import React from 'react';
import './CustomButton.css';
import { IButtonProps } from '@/utils/hooks/useSponsorStore';
import { Button } from '@chakra-ui/react';
import useClientStore from '@/utils/hooks/useClientStore';

export interface ICustomButtonProps extends IButtonProps {
	isBlue?: boolean;
	isRed?: boolean;
	index?: number;
	submit?: boolean;
	isDisabled?: boolean;
}
const CustomButton: React.FC<ICustomButtonProps> = ({
	text,
	onClick,
	isRed = false,
	isBlue = false,
	index = 0,
	submit = false,
	isDisabled = false,
}) => {
	const { buttonLoading } = useClientStore();
	return (
		<Button
			w={'100%'}
			mt={'8px'}
			isLoading={buttonLoading}
			isDisabled={isDisabled}
			type={submit ? 'submit' : 'button'}
			colorScheme={isRed ? 'red' : isBlue ? 'blue' : 'green'}
			key={index || text}
			onClick={onClick}
		>
			{text}
		</Button>
	);
};

export default CustomButton;
