import { useLayoutEffect, useMemo, useState, useEffect, FC } from 'react';
import './SponsorComponent.css';
import t from '@/utils/LabelsUtil/labelingUtils';
import { useQuery } from '@apollo/client/react';
import { GetSponsors } from '@/utils/Queries/Sponsors';
import { mapSponsorResponseToSponsorStore } from '@/utils/hooks/complexityHandlers';
import useSponsorStore from '@/utils/hooks/useSponsorStore';
import { customNotificationError } from '../CustomNotifications';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';

interface SponsorComponentProps {}

const SponsorComponent: FC<SponsorComponentProps> = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { allSponsors, currentSponsor, sponsorStoreActions } = useSponsorStore();

    const { refetch } = useQuery(GetSponsors, {
        onCompleted(data) {
            if (data) {
                sponsorStoreActions.setAllSponsors(mapSponsorResponseToSponsorStore(data));
            }
        },
        onError(data) {
            customNotificationError(data.message);
        },
    });

    const dataText: string[] = useMemo(() => allSponsors.map((x) => x.Name), [allSponsors]);
    const [timeoutIds, setTimeoutIds] = useState<number[]>([]);

    const clearAllTimeouts = () => {
        timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
        setTimeoutIds([]);
    };

    useLayoutEffect(() => {
        const typeWriter = (text: string, i: number, fnCallback: () => void): void => {
            const sponsorText = document.getElementsByClassName('name')[0] as HTMLElement;
            if (i < text.length) {
                if (sponsorText) {
                    if (sponsorText.id !== text) sponsorText.id = text;
                    sponsorText.innerHTML = text.substring(0, i + 1) + '<span id="substring" aria-hidden="true"></span>';
                }

                const newTimeoutId = setTimeout(function () {
                    typeWriter(text, i + 1, fnCallback);
                }, 200);

                // Store the timeout ID
                setTimeoutIds((prevTimeoutIds) => [...prevTimeoutIds, newTimeoutId] as number[]);
            } else if (typeof fnCallback === 'function') {
                const newTimeoutId = setTimeout(fnCallback, 2000);

                // Store the timeout ID
                setTimeoutIds((prevTimeoutIds) => [...prevTimeoutIds, newTimeoutId] as number[]);
            }
        };

        const StartTextAnimation = (i: number): void => {
            if (i === dataText?.length) {
                const newTimeoutId = setTimeout(function () {
                    StartTextAnimation(0);
                }, 2000);

                // Store the timeout ID
                setTimeoutIds((prevTimeoutIds) => [...prevTimeoutIds, newTimeoutId] as number[]);
            }
            if (i < dataText[i]?.length) {
                typeWriter(dataText[i], 0, function () {
                    StartTextAnimation(i + 1);
                });
            }
        };

        StartTextAnimation(0);

        // Cleanup function
        return () => {
            clearAllTimeouts();
        };
    }, [dataText]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await refetch(); // Assuming refetch is available here
                if (data) {
                    sponsorStoreActions.setAllSponsors(mapSponsorResponseToSponsorStore(data));
                }
            } catch (error: any) {
                customNotificationError(error.message);
            }
        };

        fetchData();

        clearAllTimeouts();
    }, [isOpen]);

    const handleBannerClick = (id: string) => {
        sponsorStoreActions.setCurrentSponsor(allSponsors.find((x) => x.Name === id)!);
        onOpen();
    };

    return (
        <>
            {dataText.length > 0 && (
                <>
                    {!isOpen && (
                        <div onClick={() => handleBannerClick(document.getElementsByClassName('name')[0]?.id)} className="sponsor-component-container">
                            {t('sponsor-component-pre-text')}
                            <div id="sponsor-text" className="name"></div>
                        </div>
                    )}
                    <Modal colorScheme="blackAlpha" isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                        <ModalContent>
                            <ModalHeader>{currentSponsor.Name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <div className="sponsor-component-modal-body-container">
                                    <img className="sponsor-component-modal-body-image" src={currentSponsor?.Image?.ImageUrl} alt="Sponsor Image" />
                                    <p className="sponsor-component-modal-body-text">{currentSponsor.Description}</p>
                                    {(currentSponsor?.PhoneNumber || currentSponsor?.Website || currentSponsor?.Address) && (
                                        <div className="sponsor-component-modal-body-contacts-container">
                                            <h2 className="sponsor-component-modal-body-contacts">Contatti</h2>
                                            {currentSponsor?.PhoneNumber && <p className="sponsor-component-modal-body-text">{currentSponsor?.PhoneNumber}</p>}
                                            {currentSponsor?.Website && (
                                                <a href={`http://${currentSponsor?.Website}`} target="_blank" className="sponsor-component-modal-body-text">
                                                    {`http://${currentSponsor?.Website}`}
                                                </a>
                                            )}
                                            {currentSponsor?.Address && <p className="sponsor-component-modal-body-text">{currentSponsor?.Address}</p>}
                                        </div>
                                    )}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="red" mr={3} onClick={onClose}>
                                    {t('sponsor-component-button')}
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            )}
        </>
    );
};

export default SponsorComponent;
