import '@styles/AdminPagePrenotazioni.css';
import bg_image from '@assets/bg_image.webp';
import CalendarComponent from '@/components/Calendar';
import { NoMetadata } from '@/utils/hooks/useMetadata';

const AdminPagePrenotazioni = () => {
    return <div className="AdminPagePrenotazioni-container">
        <NoMetadata/>
        <img src={bg_image} className="background-video" />
        <CalendarComponent />
    </div>
};

export default AdminPagePrenotazioni;
