import './EliminazioneDirettaComponent.css';
import { IEliminationRound, IEliminationMatch } from '@/utils/hooks/complexityHandlers';
import { IMatch } from '@/utils/hooks/useClientStore';
import t from '@/utils/LabelsUtil/labelingUtils';

const EliminazioneDiretta = ({ round }: { round: IEliminationRound[] }) => {

    return (
        <div className="EliminazioneDiretta-content">
            {round.map((round: IEliminationRound) => {
                return (
                    <div className="column">
                        {round.matches.map((match) => {
                            if ('hostName' in match) {
                                const typedMatch = match as IMatch;
                                return (
                                    <>
                                        {
                                            <div className={'EliminazioneDiretta-container ' + round.eliminationRound}>
                                                <div className="EliminazioneDiretta-partita-container">
                                                    <div className="EliminazioneDiretta-partita-orarioEData">
                                                        <p>
                                                            {t('eliminazione-diretta-data-e-ora')}
                                                            {typedMatch.date.toString()} {typedMatch.hour.toString()}
                                                        </p>
                                                    </div>
                                                    <div className="EliminazioneDiretta-squadreNome-container">
                                                        <p className="EliminazioneDiretta-squadraCasa-nome">{typedMatch.hostName}</p>
                                                        <p className="EliminazioneDiretta-squadraOspite-nome">{typedMatch.guestName}</p>
                                                    </div>
                                                    <div className="EliminazioneDiretta-squadreRisultato-container">
                                                        <p className="EliminazioneDiretta-squadraCasa-risultato">{typedMatch.hostGoals}</p>
                                                        <p className="EliminazioneDiretta-squadraOspite-risultato">{typedMatch.guestGoals}</p>
                                                    </div>
                                                </div>
                                                <div className={`EliminazioneDiretta-badge-round ${round.eliminationRound}-badge`}>
                                                    <span className='EliminazioneDiretta-badge-text'>
                                                        {round.eliminationRound?.toUpperCase()}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                );
                            } else if ('hostNameFirst' in match ) {
                                const typedMatch = match as IEliminationMatch;
                                return (
                                    <>
                                        {
                                            <div className={'EliminazioneDiretta-complex-container ' + round.eliminationRound}>
                                                <div className="EliminazioneDiretta-complex-partita-container" key={typedMatch.dateFirst}>
                                                    <div className="EliminazioneDiretta-complex-partita-container">
                                                        <div className="EliminazioneDiretta-complex-partita-orarioEData">
                                                            <p>
                                                                A : {typedMatch.dateFirst.toString()} {typedMatch.hourFirst.toString()}
                                                            </p>
                                                        </div>
                                                        <div className="EliminazioneDiretta-complex-partita-orarioEData-ritorno">
                                                            <p>
                                                                R : {typedMatch.dateSecond.toString()} {typedMatch.hourSecond.toString()}
                                                            </p>
                                                        </div>
                                                        <div className="EliminazioneDiretta-complex-squadreNome-container">
                                                            <p className="EliminazioneDiretta-complex-squadraCasa-nome">{typedMatch.hostNameFirst}</p>
                                                            <p className="EliminazioneDiretta-complex-squadraOspite-nome">{typedMatch.guestNameFirst}</p>
                                                        </div>
                                                        <div className="EliminazioneDiretta-complex-squadreRisultato-container">
                                                            <div className="EliminazioneDiretta-complex-squadreRisultato-container-andata">
                                                                <p className="EliminazioneDiretta-complex-squadraCasa-risultato">{typedMatch.hostGoalsFirst}</p>
                                                                <p className="EliminazioneDiretta-complex-squadraOspite-risultato">{typedMatch.guestGoalsFirst}</p>
                                                            </div>
                                                            <div className="EliminazioneDiretta-complex-squadreRisultato-container-ritorno">
                                                                <p className="EliminazioneDiretta-complex-squadraCasa-risultato">{typedMatch.hostGoalsSecond}</p>
                                                                <p className="EliminazioneDiretta-complex-squadraOspite-risultato">{typedMatch.guestGoalsSecond}</p>
                                                            </div>
                                                            <div className="EliminazioneDiretta-complex-squadreRisultato-container-totale">
                                                                <p className="EliminazioneDiretta-complex-squadraCasa-risultato">{typedMatch.hostGoalsFirst + typedMatch.hostGoalsSecond}</p>
                                                                <p className="EliminazioneDiretta-complex-squadraOspite-risultato">{typedMatch.guestGoalsFirst + typedMatch.guestGoalsSecond}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`EliminazioneDiretta-badge-round ${round.eliminationRound}-badge`}>
                                                    <span className='EliminazioneDiretta-badge-text'>
                                                        {round.eliminationRound?.toUpperCase()}
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                    </>
                                );
                            }
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default EliminazioneDiretta;
