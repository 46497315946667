import { customNotificationError, customNotificationSuccess } from "@/components/CustomNotifications";
import { API_URL } from "./useReservationStore";


export interface ReservationClientMethods {
  getAllEvents: () => Promise<ICalendar[]>;
  confirmEvent: (postConfirmUrl: string) => Promise<void>;
  deleteEvent: (postDeleteUrl: string) => Promise<void>;
  addEvent: (activeDay: number, month: number, year: number, newEvent: ICalendarEvents) => Promise<void>;
}

export interface ICalendarEvents {
  title: string;
  email: string;
  timeFrom : string;
  timeTo : string;
  type: string;
  state: 'Pending' | 'Confirmed'
}
export interface ICalendar {
  day: number;
  month: number;
  year: number;
  events: ICalendarEvents[];
}
export interface ICalendarEventsLink extends ICalendarEvents {
  day: number;
  month: number;
  year: number;

}

export default function ReservationClient(): ReservationClientMethods {
  async function getAllEvents(): Promise<ICalendar[]> {
    try {
      const response = await fetch(API_URL + 'getAll');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async function confirmEvent(postConfirmUrl: string): Promise<void> {
    try {
      const response = await fetch(API_URL + postConfirmUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorMessage = 'Evento non confermato';
        customNotificationError(errorMessage);
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log(data)
      const successMessage = 'Evento confermato';
      customNotificationSuccess(successMessage);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async function deleteEvent(postDeleteUrl: string): Promise<void> {
    try {
      const response = await fetch(API_URL + postDeleteUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorMessage = 'Evento non cancellato';
        customNotificationError(errorMessage);
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log(data)
      const successMessage = 'Evento cancellato';
      customNotificationSuccess(successMessage);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  async function addEvent(activeDay: number, month: number, year: number, newEvent: ICalendarEvents): Promise<void> {
    try {
      const response = await fetch(API_URL + 'addEvent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          day: activeDay,
          month: month + 1,
          year,
          events: [newEvent],
        }),
      });

      if (!response.ok) {
        const errorMessage = 'Evento non aggiunto';
        customNotificationError(errorMessage);
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log(data)
      const successMessage = 'Evento aggiunto';
      customNotificationSuccess(successMessage);
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  return {
    getAllEvents,
    confirmEvent,
    deleteEvent,
    addEvent,
  };
}