import { Text, Box } from '@chakra-ui/react';
import React from 'react';

interface IModalBodyDeleteCompetitionProps {
    competitionName: string;
}

const ModalBodyDeleteCompetition: React.FC<IModalBodyDeleteCompetitionProps> = ({ competitionName }) => {
    return (
        <Box w={'100%'}>
            <Text>{`Sei sicuro di voler rimuovere la competizione ${competitionName}?`}</Text>
        </Box>
    );
};

export default ModalBodyDeleteCompetition;
