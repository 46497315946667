import t from '@/utils/LabelsUtil/labelingUtils';
import { mapMatchesToMutation } from '@/utils/hooks/complexityHandlers';
import StrapiClient from '@/utils/hooks/strapiClient';
import useClientStore, {
	DEFAULT_VALUE_SELECTEDCOMPETITION,
	IMatch,
	IRounds,
} from '@/utils/hooks/useClientStore';
import { CloseIcon } from '@chakra-ui/icons';
import {
	VStack,
	Select,
	Box,
	Text,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	HStack,
	Input,
	Divider,
	Checkbox,
} from '@chakra-ui/react';
import moment from 'moment';
import {
	useReducer,
	forwardRef,
	useImperativeHandle,
	ForwardedRef,
	useEffect,
} from 'react';
import { customNotificationError } from '../CustomNotifications';

interface IModalBodyEditMatchDayProps {
	roundList: IRounds[];
	ranking: any;
}

interface IAction {
	type: string;
	payload?: any;
}

const initialState = {
	...DEFAULT_VALUE_SELECTEDCOMPETITION.rounds[0],
	mutationCounter: 0,
	matchListIndex: 0,
	mutationMaxCount: 0,
	isMutationStarted: false,
	isNewRoundCompleted: false,
};

const reducer = (state: any, action: IAction) => {
	switch (action.type) {
		case 'setField':
			return { ...state, [action.payload.field]: action.payload.value };
		case 'setMatchListIndex':
			return { ...state, matchListIndex: action.payload };
		case 'setRoundMatchProperties':
			const updatedMatches = [...state.matchList]; // Create a shallow copy
			updatedMatches[action.payload.index] = {
				...updatedMatches[action.payload.index], // Create a shallow copy
				[action.payload.field]: action.payload.value,
			};
			return { ...state, matchList: updatedMatches };
		case 'setMutationCounter':
			return { ...state, mutationCounter: state.mutationCounter + 1 };
		case 'setMutationMaxCount':
			return { ...state, mutationMaxCount: action.payload };
		case 'resetState':
			return initialState;
		default:
			return state;
	}
};

const ModalBodyEditMatchDay = forwardRef(
	(props: IModalBodyEditMatchDayProps, ref: ForwardedRef<any>) => {
		const { clientStoreActions } = useClientStore();
		const { roundList, ranking } = props;
		const client = StrapiClient();
		const [state, dispatch] = useReducer(reducer, initialState);
		const handleFormSubmit = async () => {
			dispatch({
				type: 'setField',
				payload: { field: 'isMutationStarted', value: true },
			});
			dispatch({
				type: 'setMutationMaxCount',
				payload:
					state?.newPlayers?.length +
					state?.playersToEdit?.length +
					state?.matchList?.length || 0,
			});
			//TODO MUTATION UpdateRound
			client.UpdateRound(
				mapMatchesToMutation(state?.matchList, state?.ranking)
			);
		};

		const handleCancelIconClick = () => {
			dispatch({ type: 'resetState' });
		};

		useEffect(() => {
			return () => {
				dispatch({ type: 'resetState' });
			}
		}, [])

		useImperativeHandle(ref, () => ({
			handleFormSubmit,
		}));
		const handleSave = (): void => {
			function checkProperties(objects: IMatch[]) {
				for (const obj of objects) {
					if (
						!obj.guestName ||
						!obj.hostName ||
						!obj.date ||
						!obj.date
					) {
						return false;
					}
				}
				return true;
			}
			if (!checkProperties(state?.matchList)) {
				customNotificationError(
					'Tutti i campi di tute le partite devono essere compilati'
				);
			} else {
				dispatch({
					type: 'setField',
					payload: { field: 'isNewRoundCompleted', value: true },
				});
				clientStoreActions.setAdminCompetitionModalEditMatchDayAdvanceButtonDisabled(
					false
				);
			}
		};

		// You need to return JSX from your component
		return (
			<>
				{roundList?.length && (
					<form>
						{!state?.isNewRoundCompleted && (
							<VStack>
								{!state?.matchListIndex && (
									<Select
										onChange={(e) => {
											dispatch({
												type: 'setField',
												payload: {
													field: 'matchList',
													value: roundList[
														parseInt(e.target.value)
													].matches,
												},
											});
											dispatch({
												type: 'setMatchListIndex',
												payload: e.target.value,
											});
										}}
									>
										<option value={undefined}>
											{t(
												'modal-body-match-day-scores-first-select-text'
											)}
										</option>
										{roundList?.map((round, index) => (
											<option
												key={round.id}
												value={index}
											>
												{`Giornata ${index + 1}`}
											</option>
										))}
									</Select>
								)}
								{state?.matchList &&
									state?.matchListIndex &&
									state?.matchList.length > 0 && (
										<>
											<Box
												position={'relative'}
												w={'100%'}
											>
												<Text>{`Giornata ${parseInt(
													state?.matchListIndex
												) + 1
													}`}</Text>

												<Box
													position={'absolute'}
													top={0}
													right={0}
												>
													<CloseIcon
														onClick={() =>
															handleCancelIconClick()
														}
													/>
												</Box>
											</Box>
										</>
									)}
								{state?.matchList && state?.matchListIndex && (
									<>
										<Accordion w={'100%'}>
											{state?.matchList?.map(
												(x: any, i: any) => {													
												console.log("🚀 ~ x:", x)
													return (
														<AccordionItem>
															<AccordionButton>
																<Text
																	w={'100%'}
																>
																	{(state
																		.matchList[
																		i
																	]
																		.hostName ||
																		'Squadra Casa') +
																		' - ' +
																		(state
																			.matchList[
																			i
																		]
																			.guestName ||
																			'Squadra Ospite')}{' '}
																</Text>
																<AccordionIcon />
															</AccordionButton>
															<AccordionPanel
																pb={4}
															>
																<HStack
																	pb={
																		'0.7rem'
																	}
																>
																	<Input
																		value={
																			x.date
																		}
																		type="date"
																		onChange={(
																			e
																		) => {
																			dispatch(
																				{
																					type: 'setRoundMatchProperties',
																					payload:
																					{
																						indexRound:
																							state.matchListIndex,
																						index: i,
																						field: 'date',
																						value: moment(
																							e
																								.target
																								.value
																						).format(
																							'YYYY-MM-DD'
																						),
																					},
																				}
																			);
																		}}
																	/>
																	<Input
																		value={
																			x.hour
																		}
																		type="time"
																		onChange={(
																			e
																		) =>
																			dispatch(
																				{
																					type: 'setRoundMatchProperties',
																					payload:
																					{
																						indexRound:
																							state.matchListIndex,
																						index: i,
																						field: 'hour',
																						value:
																							e
																								.target
																								.value +
																							':00.000',
																					},
																				}
																			)
																		}
																	/>
																</HStack>
																<HStack>
																	{ranking?.length && (
																		<Select
																			value={
																				x.hostName
																			}
																			onChange={(
																				e
																			) => {
																				dispatch(
																					{
																						type: 'setRoundMatchProperties',
																						payload:
																						{
																							indexRound:
																								state.matchListIndex,
																							index: i,
																							field: 'hostName',
																							value: e
																								.target
																								.value,
																						},
																					}
																				);
																			}}
																		>
																			<option>
																				Seleziona
																				team
																			</option>
																			{ranking?.map(
																				(
																					x: any
																				) => {
																					return (
																						<option
																							value={
																								x.name
																							}
																						>
																							{
																								x.name
																							}
																						</option>
																					);
																				}
																			)}
																		</Select>
																	)}
																	{ranking?.length && (
																		<Select
																			value={
																				x.guestName
																			}
																			onChange={(
																				e
																			) => {
																				dispatch(
																					{
																						type: 'setRoundMatchProperties',
																						payload:
																						{
																							indexRound:
																								state.matchListIndex,
																							index: i,
																							field: 'guestName',
																							value: e
																								.target
																								.value,
																						},
																					}
																				);
																			}}
																		>
																			<option>
																				Seleziona
																				team
																			</option>
																			{ranking?.map(
																				(
																					x: any
																				) => {
																					return (
																						<option
																							value={
																								x.name
																							}
																						>
																							{
																								x.name
																							}
																						</option>
																					);
																				}
																			)}
																		</Select>
																	)}
																</HStack>
																<HStack marginTop={'4px'} w={'100%'}>
																	<Checkbox 
																		isChecked={x.isPostponed}
																		onChange={(e) =>
																			dispatch(
																				{
																					type: 'setRoundMatchProperties',
																					payload:
																					{
																						indexRound:
																							state.matchListIndex,
																						index: i,
																						field: 'isPostponed',
																						value: e
																							.target
																							.checked,
																					},
																				}
																			)
																		}
																	>
																		Partita rinviata?
																	</Checkbox>
																</HStack>
															</AccordionPanel>
														</AccordionItem>
													);
												}
											)}
										</Accordion>
										<Button
											colorScheme="whiteAlpha"
											onClick={() => {
												handleSave();
											}}
										>
											Salva
										</Button>
									</>
								)}
							</VStack>
						)}
						{state?.isNewRoundCompleted && (
							<VStack>
								{state.matchList.map((match: any) => (
									<Box w={'100%'}>
										<Text
											as={'b'}
										>{`${match.hostName} - ${match.guestName}`}</Text>
										<Text>{`Data ${moment(
											match.date,
											'YYYY-MM-DD'
										).format('DD/MM')}`}</Text>
										<Text>
											{`Orario ${moment(
												match.hour,
												'HH:mm:ss.SSS'
											).format('HH:mm')}`}{' '}
										</Text>
										<Divider />
									</Box>
								))}
							</VStack>
						)}
					</form>
				)}
			</>
		);
	}
);

export default ModalBodyEditMatchDay;
