import '@styles/ContactUs.css';
import NavBar from '@/components/Navbar';
import t from '@utils/LabelsUtil/labelingUtils';
import { Metadata } from '@/utils/hooks/useMetadata';
import { DEFAULT_URL } from '@/app';
const ContactUs = () => {
    const HEADER_TEXT = t('contact-us-title');
    const ABOUTUS_LABEL = t('contact-us-first-label');
    const ABOUTUS_TEXT = t('contact-us-first-text');
    const PHONE_LABEL = t('contact-us-second-label');
    const PHONE_TEXT = t('contact-us-second-text');
    const EMAIL_LABEL = t('contact-us-third-label');
    const EMAIL_TEXT = t('contact-us-third-text');
    const ADDRESS_LABEL = t('contact-us-fourth-label');
    const ADDRESS_TEXT = t('contact-us-fourth-text');

    const structuredData = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Calciolandia",
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "${PHONE_TEXT}",
                "contactType": "customer service",
                "areaServed": "IT",
                "availableLanguage": "Italian"
            },
        ]
    })


    return (
        <> 
            <Metadata pageUrl={DEFAULT_URL + 'contattaci'} pageTitle={'Calciolandia | Contattaci'} structuredData={structuredData}/>
            <div>
                <NavBar />
                <video autoPlay loop muted playsInline className="background-video">
                    <source src={import.meta.env.VITE_VIDEO_BG_URL} type="video/mp4" />
                </video>
                <section className="contact-us-content">
                    <div className="ContactUs-container">
                        <div className="box">
                            <span></span>
                            <div className="content">
                                <h2>{HEADER_TEXT}</h2>
                                <h3>{ABOUTUS_LABEL}</h3>
                                <p>{ABOUTUS_TEXT}</p>
                                <h3>{PHONE_LABEL}</h3>
                                <p>{PHONE_TEXT}</p>
                                <h3>{EMAIL_LABEL}</h3>
                                <p>{EMAIL_TEXT}</p>
                                <h3>{ADDRESS_LABEL}</h3>
                                <p>{ADDRESS_TEXT}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};
export default ContactUs;
