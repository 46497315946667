import useReservationStore, { API_URL, EReservationType } from '@/utils/hooks/useReservationStore';
import { Button, ButtonGroup, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure } from '@chakra-ui/react';
import t from '@utils/LabelsUtil/labelingUtils';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { customNotificationError, customNotificationSuccess } from '../CustomNotifications';
import './JoinUsModal.css';

interface IErrors {
    joinUsName?: string;
    joinUsSecondName?: string;
    joinUsNumber?: string;
    joinUsEmail?: string;
    joinUsNotes?: string;
    joinUsType?: string;
}

const DEFAULT_BUTTON_1_LABEL = t('join-us-modal-button-padel');
const DEFAULT_BUTTON_2_LABEL = t('join-us-modal-button-calciotto');

const JoinUsModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { joinUsName, joinUsSecondName, joinUsNumber, joinUsEmail, joinUsNotes, joinUsType, joinUsConsentWhatsapp, joinUsStoreActions } = useReservationStore();
    const [errors, setErrors] = useState<IErrors>({});

    const handleCloseClick = () => {
        joinUsStoreActions.resetJoinUsFields();
        onClose();
    };

    const validateForm = () => {
        const newErrors: IErrors = {};

        if (!joinUsName || joinUsName.length <= 1) {
            newErrors.joinUsName = t('errors-join-us-modal-joinUsName');
        }

        if (!joinUsNumber || !/^\d{9,10}$/.test(joinUsNumber)) {
            newErrors.joinUsNumber = t('errors-join-us-modal-joinUsNumber');
        }

        if (!joinUsEmail || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(joinUsEmail)) {
            newErrors.joinUsEmail = t('errors-join-us-modal-joinUsEmail');
        }

        if (!joinUsNotes) {
            newErrors.joinUsNotes = t('errors-join-us-modal-joinUsNotes');
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            customNotificationError('Azione non effettuata');
        }

        return Object.keys(newErrors).length === 0;
    };

    const submitModal = (e: any) => {
        e.preventDefault();
        const joinUsObject = {
            name: joinUsName,
            secondName: joinUsSecondName,
            number: joinUsNumber,
            email: joinUsEmail,
            notes: joinUsNotes,
            type: EReservationType[joinUsType],
            consent: joinUsConsentWhatsapp,
        };
        if (validateForm()) {
            joinUsStoreActions.resetJoinUsFields();
            onClose();
            fetch(API_URL + 'addJoinUs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any other headers if needed
                },
                body: JSON.stringify(joinUsObject),
            })
                .then((response) => {
                    if (!response.ok) {
                        customNotificationError(t('errors-join-us-modal-connection-error'));
                    }
                    return response.json();
                })
                .then((data) => {
                    customNotificationSuccess(data.message);
                })
                .catch((error) => {
                    customNotificationError(t('errors-join-us-modal-reservation-error'));
                });
        }
    };

    return (
        <>
            <span onClick={onOpen} className="nav-item">
                {t('navbar.fourth')}
            </span>
            <Modal colorScheme="blackAlpha" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                <ModalContent>
                    <ModalHeader>{t('join-us-modal-title')}</ModalHeader>
                    <br />
                    <ModalBody>
                        <ButtonGroup variant="outline" spacing={isMobile ? '3' : '6'}>
                            <Button
                                isActive={joinUsType === EReservationType.Padel}
                                key={EReservationType.Padel}
                                value={EReservationType.Padel}
                                onClick={() => joinUsStoreActions.setJoinUsType(EReservationType.Padel)}
                            >
                                {DEFAULT_BUTTON_1_LABEL}
                            </Button>
                            <Button
                                isActive={joinUsType === EReservationType.Calciotto}
                                key={EReservationType.Calciotto}
                                value={EReservationType.Calciotto}
                                onClick={() => joinUsStoreActions.setJoinUsType(EReservationType.Calciotto)}
                            >
                                {DEFAULT_BUTTON_2_LABEL}
                            </Button>
                        </ButtonGroup>
                        <form onSubmit={submitModal}>
                            <div className="modal-input-container">
                                <Input
                                    key="joinUsName"
                                    value={joinUsName}
                                    onChange={(e) => joinUsStoreActions.setJoinUsName(e.target.value)}
                                    placeholder={t('join-us-modal-input-name')}
                                    isInvalid={!!errors.joinUsName}
                                />
                                {errors.joinUsName && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.joinUsName}
                                    </div>
                                )}
                                <Input
                                    key="joinUsSecondName"
                                    value={joinUsSecondName}
                                    onChange={(e) => joinUsStoreActions.setJoinUsSecondName(e.target.value)}
                                    placeholder={t('join-us-modal-input-second-name')}
                                    isInvalid={!!errors.joinUsSecondName}
                                />
                                {errors.joinUsName && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.joinUsName}
                                    </div>
                                )}
                                <Input
                                    key="joinUsNumber"
                                    value={joinUsNumber}
                                    onChange={(e) => joinUsStoreActions.setJoinUsNumber(e.target.value)}
                                    placeholder={t('join-us-modal-input-phone')}
                                    isInvalid={!!errors.joinUsNumber}
                                />
                                {errors.joinUsNumber && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.joinUsNumber}
                                    </div>
                                )}
                                <Input
                                    key="joinUsEmail"
                                    value={joinUsEmail}
                                    onChange={(e) => joinUsStoreActions.setJoinUsEmail(e.target.value)}
                                    placeholder={t('join-us-modal-input-email')}
                                    isInvalid={!!errors.joinUsEmail}
                                />
                                {errors.joinUsEmail && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.joinUsEmail}
                                    </div>
                                )}
                                <Textarea
                                    key="joinUsNotes"
                                    value={joinUsNotes}
                                    onChange={(e) => joinUsStoreActions.setJoinUsNotes(e.target.value)}
                                    placeholder={t('join-us-modal-input-notes')}
                                    isInvalid={!!errors.joinUsNotes}
                                />
                                {errors.joinUsNotes && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.joinUsNotes}
                                    </div>
                                )}
                                <Checkbox onChange={(e) => joinUsStoreActions.setJoinUsConsentWhatsapp(e.target.checked)} colorScheme="green" defaultChecked>
                                    {t('join-us-modal-input-whatsapp')}
                                </Checkbox>
                            </div>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button key="handleCloseClick" colorScheme="red" mr={3} onClick={handleCloseClick}>
                            {t('join-us-modal-button-cancel')}
                        </Button>
                        <Button onClick={submitModal} key="submitModal" type="submit" colorScheme="green">
                            {t('join-us-modal-button-confirm')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default JoinUsModal;
