import t from '@utils/LabelsUtil/labelingUtils';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Button, ButtonGroup, Input, Radio, RadioGroup, Flex, Select } from '@chakra-ui/react';
import './ReservationModal.css';
import useReservationStore, { API_URL, EReservationType, IEventDate } from '@/utils/hooks/useReservationStore';
import { mapClientReservationToDateObject } from '@/utils/hooks/complexityHandlers';
import moment from 'moment';
import { customNotificationError, customNotificationSuccess } from '../CustomNotifications';

interface IErrors {
    reservationName?: string;
    reservationPhone?: string;
    reservationEmail?: string;
    reservationDay?: string;
    selectHourFrom?: string;
    selectHourTo?: string;
}

const DEFAULT_BUTTON_1_LABEL = t('reservation-modal-button-padel');
const DEFAULT_BUTTON_2_LABEL = t('reservation-modal-button-calciotto');
const DEFAULT_BUTTON_3_LABEL = t('reservation-modal-button-calcetto');

const generateDates = () => {
    const currentDate = new Date();
    const formattedDatesArray = [];
    for (let i = 0; i < 15; i++) {
        const dateWithoutTime = new Date(currentDate);
        dateWithoutTime.setHours(0, 0, 0, 0);
        const formattedDate = dateWithoutTime.toLocaleDateString('it-IT', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
        });
        formattedDatesArray.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return formattedDatesArray;
};

const generateHoursArray = (scheduledEvents: IEventDate[], selectedDay: string) => {
    const scheduledHours: string[] = [];
    scheduledEvents.forEach((x) => {
        if (selectedDay.includes(x.day.toString())) {
            x.events.forEach((y) => {
                scheduledHours.push(y.timeFrom);
                scheduledHours.push(y.timeTo);
            });
        }
    });
    const hoursArray: string[] = [];
    const startTime = new Date();
    startTime.setHours(9, 0, 0, 0);
    const endTime = new Date();
    endTime.setHours(23, 0, 0, 0);
    const currentTime = new Date(startTime);
    while (currentTime <= endTime) {
        const formattedTime = currentTime.toLocaleTimeString('it-IT', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
        if (!scheduledHours.includes(formattedTime)) {
            hoursArray.push(formattedTime);
        }
        currentTime.setTime(currentTime.getTime() + 30 * 60 * 1000);
    }
    return hoursArray;
};

const buildGroups = (generatedHours: string[], groupSize = 3) => {
    const chunkedArray = [];
    for (let i = 0; i < generatedHours.length; i += groupSize) {
        chunkedArray.push(generatedHours.slice(i, i + groupSize));
    }
    const buttonGroups = chunkedArray.map((chunk, index) => (
        <Flex justifyContent="space-between" direction="row">
            {chunk.map((x) => (
                <Radio value={x} key={x + index}>
                    {x}
                </Radio>
            ))}
        </Flex>
    ));
    return buttonGroups;
};


const ReservationModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { reservationType, reservationName, reservationHourFrom, reservationHourTo, reservationDay, reservationEmail, reservationPhone, reservationStoreActions, reservationsSchedule } =
    useReservationStore();

    const buildSelect = (generateHours: string[]) => {
        // The client requested to block the reservationType 1 and 2
        // from 20:00 to 22:00 from mon to fri
        if(reservationType === 1 || reservationType === 2) {
            const currentSelectedDay = reservationDay?.split(' ')
            const daysArr = ['lun' , 'mar' , 'mer' , 'gio' , 'ven']
            const hoursArr = ['20:00' , '20:30' , '21:00' , '21:30']
            if(currentSelectedDay && daysArr.includes(currentSelectedDay[0])) {
                const filteredGenerateHours = generateHours?.filter((x) => !hoursArr.includes(x))
                return filteredGenerateHours.map((x) => {
                    return <option value={x}>{x}</option>;
                });
            }
        }
        return generateHours.map((x) => {
            return <option value={x}>{x}</option>;
        });
    };

    const [errors, setErrors] = useState<IErrors>({});
    let generatedHours = generateHoursArray(reservationsSchedule, reservationDay);
    const generatedDays = generateDates();

    const handleFromSelectChange = (e: any) => {
        reservationStoreActions.setReservationHourFrom(e.target.value);
    };

    const handleToSelectChange = (e: any) => {
        reservationStoreActions.setReservationHourTo(e.target.value);
    };

    const handleCloseClick = () => {
        reservationStoreActions.resetReservationFields();
        onClose();
    };

    useEffect(() => {
        fetch(API_URL + 'getAll', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    customNotificationError(t('errors-reservation-modal-impossible-to-retrieve'));
                }
                return response.json();
            })
            .then((data) => {
                const filteredData: IEventDate[] = data
                    ?.map((item: { events: any[] }) => ({
                        ...item,
                        events: item.events.filter((event: { type: string }) => event.type == EReservationType[reservationType]),
                    }))
                    ?.filter((item: { events: string | any[] }) => item.events.length > 0);
                reservationStoreActions.setReservationSchedule(filteredData);
                generatedHours = generateHoursArray(filteredData, reservationDay);
            })
            .catch((error) => {
                customNotificationError(t('errors-reservation-modal-impossible-to-retrieve'));
            });
    }, [reservationType, isOpen]);

    const validateForm = () => {
        const newErrors: IErrors = {};

        if (!reservationName || reservationName.length <= 1) {
            newErrors.reservationName = t('errors-reservation-modal-reservationName');
        }

        if (!reservationPhone || !/^\d{9,10}$/.test(reservationPhone)) {
            newErrors.reservationPhone = t('errors-reservation-modal-reservationPhone');
        }

        if (!reservationEmail || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(reservationEmail)) {
            newErrors.reservationEmail = t('errors-reservation-modal-reservationEmail');
        }

        if (!reservationDay) {
            newErrors.reservationDay = t('errors-reservation-modal-reservationDay');
        }

        if (!reservationHourFrom) {
            newErrors.selectHourFrom = t('errors-reservation-modal-selectHourFrom');
        }

        if (!reservationHourTo) {
            newErrors.selectHourTo = t('errors-reservation-modal-selectHourTo');
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            customNotificationError('Prenotazione non effettuata');
        }

        return Object.keys(newErrors).length === 0;
    };

    const submitModal = (e: any) => {
        e.preventDefault();

        const dateObject = mapClientReservationToDateObject(reservationDay);
        const reservationObject = {
            day: dateObject.day,
            month: dateObject.month,
            year: dateObject.year,
            events: [
                {
                    email: reservationEmail,
                    title: (reservationName + ' ' + reservationPhone + ' ' + EReservationType[reservationType]).toString(),
                    timeFrom: reservationHourFrom,
                    timeTo: moment(reservationHourTo.toString(), 'HH:mm').subtract(1, 'minute').format('HH:mm'),
                    state: 'Pending',
                    type: EReservationType[reservationType],
                },
            ],
        };
        if (validateForm()) {
            reservationStoreActions.resetReservationFields();
            onClose();
            fetch(API_URL + 'addEvent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any other headers if needed
                },
                body: JSON.stringify(reservationObject),
            })
                .then((response) => {
                    if (!response.ok) {
                        customNotificationError(t('errors-reservation-modal-connection-error'));
                    }
                    return response.json();
                })
                .then((data) => {
                    customNotificationSuccess(t('errors-reservation-modal-reservation-success'));
                })
                .catch((error) => {
                    customNotificationError(t('errors-reservation-modal-reservation-error'));
                });
        }
    };

    return (
        <>
            <span onClick={onOpen} className="nav-item">
                {t('navbar.second')}
            </span>
            <Modal colorScheme="blackAlpha" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
                <ModalContent>
                    <ModalHeader>{t('reservation-modal-title')}</ModalHeader>
                    <br />
                    <ModalBody>
                        <ButtonGroup variant="outline" spacing={isMobile ? '3' : '6'}>
                            <Button
                                isActive={reservationType === EReservationType.Padel}
                                key={EReservationType.Padel}
                                value={EReservationType.Padel}
                                onClick={() => reservationStoreActions.setReservationType(EReservationType.Padel)}
                            >
                                {DEFAULT_BUTTON_1_LABEL}
                            </Button>
                            <Button
                                isActive={reservationType === EReservationType.Calciotto}
                                key={EReservationType.Calciotto}
                                value={EReservationType.Calciotto}
                                onClick={() => reservationStoreActions.setReservationType(EReservationType.Calciotto)}
                            >
                                {DEFAULT_BUTTON_2_LABEL}
                            </Button>
                            <Button
                                isActive={reservationType === EReservationType.Calcetto}
                                key={EReservationType.Calcetto}
                                value={EReservationType.Calcetto}
                                onClick={() => reservationStoreActions.setReservationType(EReservationType.Calcetto)}
                            >
                                {DEFAULT_BUTTON_3_LABEL}
                            </Button>
                        </ButtonGroup>
                        <form onSubmit={submitModal}>
                            <div className="modal-input-container">
                                <Input
                                    key="reservationName"
                                    value={reservationName}
                                    onChange={(e) => reservationStoreActions.setReservationName(e.target.value)}
                                    placeholder={t('reservation-modal-input-name')}
                                    isInvalid={!!errors.reservationName}
                                />
                                {errors.reservationName && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.reservationName}
                                    </div>
                                )}
                                <Input
                                    key="reservationPhone"
                                    value={reservationPhone}
                                    onChange={(e) => reservationStoreActions.setReservationPhone(e.target.value)}
                                    placeholder={t('reservation-modal-input-phone')}
                                    isInvalid={!!errors.reservationPhone}
                                />
                                {errors.reservationPhone && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.reservationPhone}
                                    </div>
                                )}
                                <Input
                                    key="reservationEmail"
                                    value={reservationEmail}
                                    onChange={(e) => reservationStoreActions.setReservationEmail(e.target.value)}
                                    placeholder={t('reservation-modal-input-email')}
                                    isInvalid={!!errors.reservationEmail}
                                />
                                {errors.reservationEmail && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.reservationEmail}
                                    </div>
                                )}
                                <RadioGroup key="radio-group-day" onChange={reservationStoreActions.setReservationDay} value={reservationDay}>
                                    {buildGroups(generatedDays)}
                                </RadioGroup>
                                {errors.reservationDay && (
                                    <div
                                        style={{
                                            color: 'red',
                                            fontSize: '12px',
                                        }}
                                        className="error"
                                    >
                                        {errors.reservationDay}
                                    </div>
                                )}
                                <div className="ReservationModal-Select-Container">
                                    <Select isInvalid={!!errors.selectHourFrom} key="select-hour-from" onChange={handleFromSelectChange} placeholder="Dalle ore">
                                        {buildSelect(generatedHours)}
                                    </Select>
                                    {errors.reservationEmail && (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: '12px',
                                            }}
                                            className="error"
                                        >
                                            {errors.reservationEmail}
                                        </div>
                                    )}

                                    <Select isInvalid={!!errors.selectHourTo} key="select-hour-to" onChange={handleToSelectChange} placeholder="Alle ore">
                                        {buildSelect(generatedHours)}
                                    </Select>
                                    {errors.selectHourTo && (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: '12px',
                                            }}
                                            className="error"
                                        >
                                            {errors.selectHourTo}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </ModalBody>

                    <ModalFooter>
                        <Button key="handleCloseClick" colorScheme="red" mr={3} onClick={handleCloseClick}>
                            {t('reservation-modal-button-cancel')}
                        </Button>
                        <Button onClick={submitModal} key="submitModal" type="submit" colorScheme="green">
                            {t('reservation-modal-button-confirm')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ReservationModal;
