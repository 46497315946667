import { CheckIcon } from '@chakra-ui/icons';
import { Text, Box, Divider, AbsoluteCenter } from '@chakra-ui/react';
import React from 'react';

interface ICustomDividerModal {
    text?: string;
    isConfirmed?: boolean;
}
const CustomDividerModal: React.FC<ICustomDividerModal> = ({ text, isConfirmed = false }) => {
    return (
        <Box w={'100%'} position={'relative'} margin={'8px 0'}>
            <Divider />
            <AbsoluteCenter display={'inline-flex'} bg="transparent" px="1">
                <Text padding={'2px 4px'} fontSize={'8px'} color={'white'} background={'#222'} borderRadius={'8px'} position={'relative'}>
                    {text}
                    {isConfirmed && <CheckIcon color={'green'} marginLeft={'2px'} />}
                </Text>
            </AbsoluteCenter>
        </Box>
    );
};
export default CustomDividerModal;
