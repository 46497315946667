import { ICompetition, IRounds, IPlayer, ITeam, IMatch } from './useClientStore';
import moment from 'moment';
import { ISponsor } from './useSponsorStore';
import { ExistingMatchInfo } from './strapiClient';

export interface MatchAttributes {
    id: string;
    attributes: {
        Date: string;
        Hour: string;
        HostGoals: number;
        GuestGoals: number;
        GuestWarnings: number;
        HostWarnings: number;
        IsPostponed : boolean;
        HostTeam: {
            data: {
                id: string;
                attributes: {
                    Name: string;
                };
            };
        };
        GuestTeam: {
            data: {
                id: string;
                attributes: {
                    Name: string;
                };
            };
        };
    };
}

export interface PlayerAttributes {
    id: string;
    attributes: {
        Name: string;
        Surname: string;
        Goals: number;
        Warnings: number;
        Assists: number;
    };
}

export interface TeamAttributes {
    id: string;
    attributes: {
        Name: string;
        Points: number;
        PlayedMatches: number;
        Wins: number;
        Draws: number;
        Loss: number;
        GoalsDone: number;
        GoalsReceived: number;
        Tags: number;
        HostedMatches: {
            data: MatchAttributes[];
        };
        GuestMatches: {
            data: MatchAttributes[];
        };
        Players: {
            data: PlayerAttributes[];
        };
    };
}

export interface RoundAttributes {
    id: string;
    attributes: {
        Stage: string | null; // Replace with appropriate type if known
        Matches: {
            data: MatchAttributes[];
        };
    };
}
export interface CompetitionAttributes {
    Name: string;
    DirectElimination: boolean | null;
    IsArchived : boolean | null;
    Ranking: {
        data: {
            id: string;
            attributes: {
                Teams: {
                    data: TeamAttributes[];
                };
            };
        };
    };
    Rounds: {
        data: RoundAttributes[];
    };
}

export interface CompetitionData {
    id: string;
    attributes: CompetitionAttributes;
}

export interface CompetitionResponse {
    competitions: {
        data: CompetitionData[];
    };
}

const formatTime = (timeString: string) => {
    const parts = timeString?.split(':');
    if (parts?.length >= 2) {
        return parts?.slice(0, 2)?.join(':');
    }
    return timeString;
};

export const mapCompetitionRanking = (team: TeamAttributes): ITeam => {
    const hostedGoalsDone: number = team?.attributes?.HostedMatches.data.map((x) => x.attributes.HostGoals).reduce((total, goal) => total + goal, 0);
    const guestedGoalsDone: number = team?.attributes?.GuestMatches.data.map((x) => x.attributes.GuestGoals).reduce((total, goal) => total + goal, 0);
    const hostedGoalsReceived: number = team?.attributes?.HostedMatches.data.map((x) => x.attributes.GuestGoals).reduce((total, goal) => total + goal, 0);
    const guestedGoalsReceived: number = team?.attributes?.GuestMatches.data.map((x) => x.attributes.HostGoals).reduce((total, goal) => total + goal, 0);
    const goalsDone = hostedGoalsDone + guestedGoalsDone;
    const goalsReceived = hostedGoalsReceived + guestedGoalsReceived;
    const hostedMatches = team?.attributes?.HostedMatches.data.map((match) => match?.id)
    const guestMatches = team?.attributes?.GuestMatches.data
        .map((match) => match?.id)
    return {
        id: team?.id,
        name: team?.attributes?.Name,
        wins: team?.attributes?.Wins,
        draws: team?.attributes?.Draws,
        loss: team?.attributes?.Loss,
        playedMatches: team?.attributes?.PlayedMatches,
        points: team?.attributes?.Points,
        goalsDone,
        goalsReceived,
        cards: team?.attributes?.Tags ? team?.attributes?.Tags : 0,
        hostedMatches,
        guestMatches,
    };
};
export const mapCompetitionRounds = (round: RoundAttributes): IRounds => {
    const roundMatches = round?.attributes?.Matches.data.map((match) => {
        return {
            id: match?.id,
            date: match?.attributes?.Date,
            hour: formatTime(match?.attributes?.Hour),
            hostGoals: match?.attributes?.HostGoals,
            hostCards: match?.attributes?.HostWarnings,
            hostName: match?.attributes?.HostTeam?.data?.attributes?.Name,
            guestName: match?.attributes?.GuestTeam?.data?.attributes?.Name,
            guestGoals: match?.attributes?.GuestGoals,
            guestCards: match?.attributes?.GuestWarnings,
            eliminationRound: round?.attributes?.Stage,
            isPostponed: match?.attributes?.IsPostponed || false,
        };
    });
    const returnObj = {
        eliminationRound: round?.attributes?.Stage,
        matches: roundMatches,
        id: round?.id,
    };

    return returnObj;
};

export const mapCompetitionPlayersRanking = (player: TeamAttributes): IPlayer[] => {
    const teamName = player?.attributes?.Name;
    return player.attributes.Players.data.map((x: PlayerAttributes) => {
        return {
            name: x?.attributes?.Name,
            surname: x?.attributes?.Surname,
            goals: x?.attributes?.Goals,
            cards: x?.attributes?.Warnings,
            id: x?.id,
            teamName,
        };
    });
};

export const mapResponseToClientStore = (response: CompetitionResponse): ICompetition[] => {
    const competitionsList: CompetitionData[] = response?.competitions?.data;
    const mappedCompetitionList: ICompetition[] = competitionsList?.map((competition: CompetitionData) => {
        const competitionRankings = competition?.attributes?.Ranking?.data?.attributes?.Teams.data?.map((x) => mapCompetitionRanking(x));
        const competitionRounds = competition?.attributes?.Rounds.data?.map((x) => {
            return mapCompetitionRounds(x);
        });
        const competitionPlayersRanking = competition?.attributes?.Ranking?.data?.attributes.Teams.data
            ?.map((x) => {
                return mapCompetitionPlayersRanking(x);
            })
            .reduce((acc, currentArray) => {
                return acc.concat(currentArray);
            }, []);
        return {
            name: competition?.attributes?.Name,
            id: competition?.id,
            directElimination: competition?.attributes?.DirectElimination ? competition?.attributes?.DirectElimination : false,
            isArchived : competition?.attributes?.IsArchived ? competition?.attributes?.IsArchived : false,
            ranking: competitionRankings,
            rounds: competitionRounds,
            playersRanking: competitionPlayersRanking,
        };
    });
    return mappedCompetitionList;
};

export interface IEliminationMatch {
    dateFirst: string;
    hourFirst: string;
    hostGoalsFirst: number;
    hostCardsFirst: number;
    hostNameFirst: string;
    guestNameFirst: string;
    guestGoalsFirst: number;
    guestCardsFirst: number;
    dateSecond: string;
    hourSecond: string;
    hostGoalsSecond: number;
    hostCardsSecond: number;
    hostNameSecond: string;
    guestNameSecond: string;
    guestGoalsSecond: number;
    guestCardsSecond: number;
}
export interface IEliminationRound {
    eliminationRound: string | null;
    matches: IMatch[] | IEliminationMatch[];
}

export const itemCounter = (value: string[], index: string) => {
    return value.filter((x) => x == index).length;
};
export const mapDirectEliminationRounds = (rounds: IRounds[]): IEliminationRound[] => {
    const eliminationRoundNames: string[] = [];
    const homeTeamNames = rounds.map((singleRound: IRounds) => {
        eliminationRoundNames.push(singleRound?.eliminationRound as string);
        const counter = itemCounter(eliminationRoundNames, singleRound?.eliminationRound as string);
        if (counter === 1) {
            return {
                round: singleRound?.eliminationRound,
                names: singleRound?.matches.map((singleMatch: IMatch) => singleMatch?.hostName),
            };
        }
    });

    const filteredHomeTeamNames = homeTeamNames.filter((hostName) => hostName !== undefined);

    const complexRounds = [];
    for (const singleRound of rounds) {
        const roundOuterMatches = [];
        const counter = itemCounter(eliminationRoundNames, singleRound.eliminationRound as string);
        if (singleRound.eliminationRound !== 'finale' && counter > 1) {
            for (const singleMatch of singleRound?.matches) {
                for (const singleRoundOuter of rounds) {
                    const namesArrayOfCurrentRound = filteredHomeTeamNames.filter((x) => x?.round === singleRoundOuter?.eliminationRound);
                    for (const singleMatchOuter of singleRoundOuter?.matches) {
                        if (namesArrayOfCurrentRound[0]) {
                            if (
                                namesArrayOfCurrentRound[0]?.names?.includes(singleMatch?.hostName) &&
                                singleMatch?.hostName === singleMatchOuter?.guestName &&
                                singleMatch?.guestName === singleMatchOuter?.hostName
                            ) {
                                const combinedMatch = {
                                    dateFirst: singleMatch?.date,
                                    hourFirst: singleMatch?.hour,
                                    hostGoalsFirst: singleMatch?.hostGoals,
                                    hostCardsFirst: singleMatch?.hostCards,
                                    hostNameFirst: singleMatch?.hostName,
                                    guestNameFirst: singleMatch?.guestName,
                                    guestGoalsFirst: singleMatch?.guestGoals,
                                    guestCardsFirst: singleMatch?.guestCards,
                                    dateSecond: singleMatchOuter?.date,
                                    hourSecond: singleMatchOuter?.hour,
                                    hostGoalsSecond: singleMatchOuter?.guestGoals,
                                    hostCardsSecond: singleMatchOuter?.guestCards,
                                    hostNameSecond: singleMatchOuter?.guestName,
                                    guestNameSecond: singleMatchOuter?.hostName,
                                    guestGoalsSecond: singleMatchOuter?.hostGoals,
                                    guestCardsSecond: singleMatchOuter?.hostCards,
                                };
                                roundOuterMatches.push(combinedMatch);
                            }
                        }
                    }
                }
            }
            complexRounds.push({
                eliminationRound: singleRound?.eliminationRound,
                matches: roundOuterMatches,
            });
        } else
            complexRounds.push({
                eliminationRound: singleRound?.eliminationRound,
                matches: singleRound?.matches,
            });
    }
    const complexRoundsFiltered = complexRounds.filter((innerArray) => innerArray?.matches?.length > 0);
    return complexRoundsFiltered;
};

export interface IDateObject {
    day: number;
    month: number;
    year: number;
}
export const mapClientReservationToDateObject = (reservation: string): IDateObject => {
    const translateMonthName = (month: string): number => {
        const monthMappings = {
            gen: 1,
            feb: 2,
            mar: 3,
            apr: 4,
            mag: 5,
            giu: 6,
            lug: 7,
            ago: 8,
            set: 9,
            ott: 10,
            nov: 11,
            dic: 12,
        };
        //@ts-ignore
        return monthMappings[month]; // Return the Italian name or the input if not found
    };

    moment.locale('it');

    const currentYear = moment().year();
    const splittedString = reservation.split(' ');
    const day = Number(splittedString[1]);
    const month = translateMonthName(splittedString[2]);

    const dateObject: IDateObject = {
        day: day,
        month: month,
        year: currentYear,
    };

    return dateObject;
};

export interface IEventObject {
    title: string;
    time: string;
    state: string;
    type: string;
}
export interface IReservationObject extends IDateObject {
    events: IEventObject[];
}

interface ISponsorResponse {
    sponsors: {
        __typename: string;
        data: SponsorEntity[];
    };
}

interface SponsorEntity {
    __typename: string;
    id: number;
    attributes: {
        __typename: string;
        Name: string;
        Description: string;
        PhoneNumber: string;
        Website: string;
        Address: string;
        Image: {
            __typename: string;
            data: {
                id: number;
                __typename: string;
                attributes: {
                    __typename: string;
                    url: string;
                };
            };
        };
    };
}

export const mapSponsorResponseToSponsorStore = (res: ISponsorResponse): ISponsor[] => {
    // Senza / finale per dio
    const DEFAULT_BASE_URL = 'https://odin.pdsstaging.com';
    const response_data = res?.sponsors?.data;
    const mapped_response = response_data?.map((x) => {
        const data_obj = {
            id: x?.id,
            Name: x?.attributes?.Name,
            Description: x?.attributes?.Description,
            Image: {
                ImageUrl: x?.attributes?.Image?.data?.attributes?.url !== undefined ? DEFAULT_BASE_URL + x?.attributes?.Image?.data?.attributes?.url : '',
                ImageId: x?.attributes?.Image?.data?.id || 0,
            },
            PhoneNumber: x?.attributes?.PhoneNumber || '',
            Website: x?.attributes?.Website || '',
            Address: x?.attributes?.Address || '',
        };
        return data_obj;
    });
    return mapped_response;
};

export interface IPlayersActions {
    ID: number | string;
    Name: string;
    Goals: number;
    Assist: number;
    TeamID: number | string;
    MatchID: number | string;
    Surname: string;
    TeamName: string;
    Warnings: number;
}

interface IMatchDayScoresAttributes {
    CompetitionID: string | number;
    PlayersActions: IPlayersActions[];
    Matches : any;
    RoundID: number | string;
    __typename: string;
}

interface IMatchDayScoresEntity {
    id: string;
    attributes: IMatchDayScoresAttributes;
    __typename: string;
}



interface IMatchDayScoresResponse {
    data: IMatchDayScoresEntity[];
    __typename: string;
}
export interface IMatchDayScores {
    playersActions: IPlayersActions[];
    competitionID: string | number;
    roundID: number | string;
    matches : any;
    id: string | number;
}
export const mapMatchDayScoresResponseToState = (matchDayScore: IMatchDayScoresResponse): IMatchDayScores[] => {

    const mappedMatchDayScores = matchDayScore?.data?.map((x) => {
        return {
            id: x?.id,
            roundID: x?.attributes?.RoundID,
            competitionID: x?.attributes?.CompetitionID,
            playersActions: x?.attributes?.PlayersActions,
            matches : x?.attributes?.Matches
        }
    })
    return mappedMatchDayScores
}

export const mapRankingToMutation = (ranking: ITeam[]) => { 
    const mappedRanking = ranking?.map((team : ITeam) => {
        return {
            Points: team?.points || 0,
            PlayedMatches: team?.playedMatches || 0,
            Wins: team?.wins || 0,
            Draws: team?.draws || 0,
            Loss: team?.loss || 0,
            GoalsDone: team?.goalsDone || 0,
            GoalsReceived: team?.goalsReceived || 0,
            HostedMatches: team?.hostedMatches || [],
            GuestMatches: team?.guestMatches || [],
            Tags : team?.cards || 0,
            ID: team?.id?.toString() || '',
        }
    })
    return mappedRanking
}


export const mapMatchesToMutation = (matches: IMatch[] , ranking : ITeam[]) => { 
    const mappedRanking : ExistingMatchInfo[] = matches?.map((match : IMatch) => {
        console.log("🚀 ~ constmappedRanking:ExistingMatchInfo[]=matches?.map ~ match:", match)
        return {
            ID: match?.id,
            date : match?.date,
            hour : moment(match.hour, 'HH:mm').format('HH:mm:ss.SSS'),
            HostGoals: match?.hostGoals || 0,
            HostWarnings: match?.hostCards || 0,
            GuestGoals: match?.guestGoals || 0,
            GuestWarnings: match?.guestCards || 0,
            HostID: ranking?.find((team: ITeam) => team?.name === match?.hostName)?.id as string|number,
            GuestID: ranking?.find((team: ITeam) => team?.name === match?.guestName)?.id as string|number,
            IsPostponed : match?.isPostponed || false,
        }
    })
    return mappedRanking
}

export const mapMatchesToMatchdayMutation = (matches: IMatch[] , ranking : ITeam[]) => { 
    const mappedRanking : ExistingMatchInfo[] = matches?.map((match : IMatch) => {
        return {
            ID: match?.id,
            date : match?.date,
            hour : moment(match.hour, 'HH:mm').format('HH:mm:ss.SSS'),
            HostGoals: match?.hostGoals || 0,
            HostWarnings: match?.hostCards || 0,
            GuestGoals: match?.guestGoals || 0,
            GuestWarnings: match?.guestCards || 0,
            HostID: ranking?.find((team: ITeam) => team?.name === match?.hostName)?.id as string|number,
            GuestID: ranking?.find((team: ITeam) => team?.name === match?.guestName)?.id as string|number,
            IsPostponed : match?.isPostponed || false,
            hostAutogoals : match?.hostAutogoals || 0,
            guestAutogoals : match?.guestAutogoals || 0,
        }
    })
    return mappedRanking
}

export const mapNewPlayersToMutation = (newPlayers: IPlayer[] , ranking : ITeam[]) => {
    if(newPlayers?.length > 0) {
        const clientNewPlayers = newPlayers.map((player: IPlayer) => ({
            Name: player?.name,
            Surname: player?.surname,
            Goals: player?.goals || 0,
            Assist: 0,
            Warnings: player?.cards || 0,
            TeamIndex: ranking.find((team: ITeam) => team?.name === player?.teamName)?.id as number,
        }));
        return clientNewPlayers
    }
    return undefined
}