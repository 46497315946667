import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import './app.css';
import HomePage from '@pages/HomePage';
import React from 'react';
import RestrictedArea from '@pages/RestrictedArea';
import PageNotFound from '@pages/PageNotFound';
import CompetitionPage from '@pages/CompetitionPage';
import ContactUs from '@pages/ContactUs';
import AdminPage from './pages/AdminPage';
import AdminPagePrenotazioni from './pages/AdminPagePrenotazioni';
import AdminPageCompetizioni from './pages/AdminPageCompetizioni';
import { customNotificationError } from './components/CustomNotifications';
import t from './utils/LabelsUtil/labelingUtils';
import AdminPageSponsor from './pages/AdminPageSponsor';

export const DEFAULT_URL = 'https://calciolandia.pdsstaging.com/'
interface IProtectedRoute {
    children: React.ReactElement;
}
const ProtectedRoute = ({ children }: IProtectedRoute) => {
    const token = localStorage.getItem('token');
    if (!token) {
        customNotificationError(t('restricted-area-login-error'));
        return <Navigate to="/admin" replace />;
    }

    return children;
};

export function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/competizioni" element={<CompetitionPage />} />
                <Route path="/contattaci" element={<ContactUs />} />
                <Route path="/admin" element={<RestrictedArea />} />
                <Route
                    path="/admin/gestione"
                    element={
                        <ProtectedRoute>
                            <AdminPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/gestione/prenotazioni"
                    element={
                        <ProtectedRoute>
                            <AdminPagePrenotazioni />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/gestione/competizioni"
                    element={
                        <ProtectedRoute>
                            <AdminPageCompetizioni />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin/gestione/sponsor"
                    element={
                        <ProtectedRoute>
                            <AdminPageSponsor />
                        </ProtectedRoute>
                    }
                />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    );
}
