import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import { SnackbarProvider } from 'notistack';
import { CustomNotificationComponent, customNotificationError } from './components/CustomNotifications';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import t from './utils/LabelsUtil/labelingUtils';
import { HelmetProvider } from 'react-helmet-async';

const httpLink = createHttpLink({
    uri: import.meta.env.VITE_GRAPHQL_ENDPOINT_URL,
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: "network-only",
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all'
    }
}

const authLink: ApolloLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});
const errorLink = onError(({ graphQLErrors }) => {
    const token = localStorage.getItem('token');
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            if (message.includes('Forbidden')) {
                customNotificationError(t('restricted-area-login-error'));
                if (token) localStorage.removeItem('token');
                setTimeout(() => {
                    window.location.href = '/admin';
                }, 2000);
            } else customNotificationError(message);
        });
    }
});

const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
    //@ts-ignore
    defaultOptions: defaultOptions,

});

const rootElement = document.querySelector('[data-js="root"]');
if (!rootElement) {
    throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);
root.render(
    <StrictMode>
        <HelmetProvider>
            <ApolloProvider client={client}>
                <ChakraProvider>
                    <SnackbarProvider
                        Components={{
                            customNotification: CustomNotificationComponent,
                        }}
                        autoHideDuration={5000}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        maxSnack={7}
                    >
                        <App />
                    </SnackbarProvider>
                </ChakraProvider>
            </ApolloProvider>
        </HelmetProvider>
    </StrictMode>,
);


// TODO
// 1. Fix Tabellins not updating when a lot of matches in direct elimination and you make new players
// 2. Fix DropDown teams insert new matchday not displaying correctly all the teams
// 3. Direct Elimination each match card , find a way to say the round on the card idk how
// 4. If have time try to check the state update after mutations in AdminCompetition else leave the page reload