import React, { useEffect, useState } from 'react';
import './CalendarComponent.css';
import ReservationClient, { ICalendar, ICalendarEvents, ICalendarEventsLink } from '@/utils/hooks/reservationClient';
import { Button, IconButton, Input, Select } from '@chakra-ui/react';
import { Badge } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon, DeleteIcon, QuestionIcon } from '@chakra-ui/icons';
import { customNotificationError } from '../CustomNotifications';

const CalendarComponent: React.FC = () => {
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    const [today, setToday] = useState<Date>(new Date());
    const [month, setMonth] = useState<number>(today.getMonth());
    const [year, setYear] = useState<number>(today.getFullYear());
    const [allReservations, setAllReservations] = useState<ICalendar[]>()
    const [todayEvents, setTodayEvents] = useState<ICalendar>()
    const reservationClient = ReservationClient()
    let loadingNewReservation = false
    let loadingDeleteReservation = false

    const fetchAllReservations = async (): Promise<void> => {
        reservationClient.getAllEvents()
            .then(response => {
                setAllReservations(response)
                const todayReservation = allReservations?.find((x: ICalendar) => x.day === today.getDate() && x.month === today.getMonth() && x.year === today.getFullYear())
                if (todayReservation && todayReservation?.events?.length > 0) {
                    setTodayEvents(todayReservation)
                } else setTodayEvents(undefined)
            })
            .catch((error) => {
                customNotificationError(error?.message  || 'Errore nel caricamento delle prenotazioni')
            })
    }
    useEffect(() => {
        fetchAllReservations()
    }, [])

    const deleteReservation = async (params: ICalendarEventsLink) => {
        loadingDeleteReservation = true
        const deleteUrl = `deleteEvent?day=${today.getDate()}&month=${today.getMonth() + 1}&year=${today.getFullYear()}&eventTitle=${params.title}&eventTimeFrom=${params.timeFrom}&eventTimeTo=${params.timeTo}&eventType=${params.type}&eventEmail=${params.email}`
        reservationClient.deleteEvent(deleteUrl)
            .then(response => {
                fetchAllReservations()
                loadingDeleteReservation = false
            })
            .catch((error) => {
                customNotificationError(error?.message  || 'Errore cancella prenotazione')
            })
    }
    const confirmReservation = async (params: ICalendarEventsLink) => {
        const confirmUrl = `confirmEvent?day=${today.getDate()}&month=${today.getMonth() + 1}&year=${today.getFullYear()}&eventTitle=${params.title}&eventTimeFrom=${params.timeFrom}&eventTimeTo=${params.timeTo}&eventType=${params.type}&eventEmail=${params.email}&eventState=Pending`
        reservationClient.confirmEvent(confirmUrl)
            .then(response => {
                fetchAllReservations()
            })
            .catch((error) => {
                customNotificationError(error?.message  || 'Errore nella conferma della prenotazione')
            })
    }
    const addReservation =async (activeDay: number, month: number, year: number, newEvent:  ICalendarEvents) => {
        loadingNewReservation = true
        reservationClient.addEvent(activeDay , month , year , newEvent).then(response => {
            fetchAllReservations()
            loadingNewReservation = false
        })
        .catch((error) => {
            customNotificationError(error?.message  || 'Errore nella aggiunta della prenotazione')
        })
    }

    const lastDay = new Date(year, month + 1, 0);
    const prevLastDay = new Date(year, month, 0);
    const lastDate = lastDay.getDate();

    const handleAddOneMonth = () => {
        const newDate = new Date(year, month + 1, today.getDate());

        setMonth(newDate.getMonth());
        setYear(newDate.getFullYear());
        setToday(newDate);
    };

    const handleSubtractOneMonth = () => {
        const newDate = new Date(year, month - 1, today.getDate());

        setMonth(newDate.getMonth());
        setYear(newDate.getFullYear());
        setToday(newDate);
    };

    const handleClickOnEvent = (day: number, month: number, year: number) => {
        month = month + 1
        if (allReservations) {
            const todayReservation = allReservations?.find((x: ICalendar) => x.day === day && x.month === month && x.year === year)
            if (todayReservation && todayReservation?.events?.length > 0) {
                setTodayEvents(todayReservation)
            } else setTodayEvents(undefined)
        }
        setToday(new Date(year, month - 1, day))
    }

    const DaysCubes: React.FC = () => {
        const daysInMonth = Array.from({ length: lastDate }, (_, index) => index + 1);
        const nextDays = []
        const daysLenght = 35 - lastDate - 1
        for (let i = 1; i < daysLenght + 1; i++) {
            const date = new Date(year, month + 1, i)
            nextDays.push(date)
        }
        return <>
            <div onClick={() => handleClickOnEvent(prevLastDay.getDate(), prevLastDay.getMonth(), prevLastDay.getFullYear())} key={'prevDay'} className='calendarComponent-calendar-day prev-day'>
                {prevLastDay.getDate()}
            </div>
            {
                daysInMonth?.map((day, index) => {
                    const eventsLenght = allReservations?.find((x: ICalendar) => x.day === day && x.month === month + 1 && x.year === year)?.events
                    const hasPending = !!eventsLenght?.find((x: ICalendarEvents) => x.state === 'Pending')
                    const isToday = today.getDate() === day && today.getMonth() === month && today.getFullYear() === year;
                    return (
                        <div onClick={() => handleClickOnEvent(day, month, year)} key={index} className={`calendarComponent-calendar-day ${isToday ? 'today' : ''} ${(eventsLenght && eventsLenght?.length > 0) ? ' day-event ' : ''} ${hasPending ? 'day-pending-event' : ''}`}><p>{day}</p></div>)
                })
            }
            {
                nextDays?.map((day, index) => (
                    <div onClick={() => handleClickOnEvent(day.getDate(), day.getMonth(), day.getFullYear())} key={index} className='calendarComponent-calendar-day next-day'>{day.getDate()}</div>))
            }
        </>
    }

    const AddEvent: React.FC = () => {
        const [title, setTitle] = useState('')
        const [timeFrom, setTimeFrom] = useState('')
        const [timeTo, setTimeTo] = useState('')

        const state = 'Confirmed'
        const [type, setType] = useState<string>('Padel')
        const [date, setDate] = useState<string>()
        const competitions = ['Padel', 'Calcetto', 'Calciotto']
        const [isNewOpen , setIsNewOpen] = useState(false)
        const handleButtonClick = () => {
            
            if (!date?.length) {
                customNotificationError('Inserire Data');
                return;
            }
            
            if (!timeFrom?.length) {
                customNotificationError('Inserire Orario partenza');
                return;
            }
            
            if (!timeTo?.length) {
                customNotificationError('Inserire Orario fine');
                return;
            }
            if (!title?.length) {
              customNotificationError('Inserire Nome e/o contatti');
              return;
            }
            const splittedDate = date?.split('-') || [0,0,0]
            const newEvent : ICalendarEvents = {
                title,
                email: 'Inserito da Admin',
                timeFrom,
                timeTo,
                type,
                state
            }
            addReservation(parseInt(splittedDate[2]?.toString()) , (parseInt(splittedDate[1]?.toString()) - 1 || 0) , parseInt(splittedDate[0]?.toString()) , newEvent)
        }
        return <>
            <div className={`calendarComponent-add-event ${isNewOpen ? 'show-add-event' : ''}`}>
                <div className="calendarComponent-add-event-icon-add-container">
                    <IconButton onClick={() => setIsNewOpen(!isNewOpen)} colorScheme='teal' aria-label='Aggiungi prenotazione' icon={ isNewOpen ? <ArrowRightIcon/> : <ArrowLeftIcon/>} />
                </div>
                <div className={`calendarComponent-add-event-container ${isNewOpen ? 'show-add-event-form' : ''}`}>
                    <div className="calendarComponent-add-event-date-row-container">
                        <div className="calendarComponent-add-event-label-input-container">
                            <span className="calendarComponent-add-event-label-text">{'Campo'}</span>
                            <Select value={type} onChange={(e) => setType(e.target.value)} placeholder='Seleziona Campo'>
                                {competitions.map((competition: string) => <option key={competition} value={competition}>{competition}</option>)}
                            </Select>
                        </div>
                        <div className="calendarComponent-add-event-label-input-container">
                            <span className="calendarComponent-add-event-label-text">{'Data'}</span>
                            <Input
                                value={date}
                                color={'white'}
                                type="date"
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </div>
                        <div className="calendarComponent-add-event-label-input-container">
                            <span className="calendarComponent-add-event-label-text">{'Dalle ore'}</span>
                            <Input
                                value={timeFrom}
                                type="time"
                                onChange={(e) => setTimeFrom(e.target.value)}
                            />
                        </div>
                        <div className="calendarComponent-add-event-label-input-container">
                            <span className="calendarComponent-add-event-label-text">{'Alle ore'}</span>
                            <Input
                                value={timeTo}
                                type="time"
                                onChange={(e) => setTimeTo(e.target.value)}
                            />
                        </div>
                        <div className="calendarComponent-add-event-label-input-container">
                            <span className="calendarComponent-add-event-label-text">{'Nome e contatti'}</span>
                            <Input
                                value={title}
                                type="text"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <Button isLoading={loadingNewReservation} onClick={ () => handleButtonClick()} marginTop={'16px'} colorScheme='green'> Inserisci Prenotazione </Button>
                    </div>
                </div>
            </div>
        </>
    }

    return (
        <div className='calendarComponent-container'>
            <div className='calendarComponent-calendar-and-events-container'>
                <div className="calendarComponent-calendar-and-current-day">
                    <div className='calendarComponent-calendar-current-day'>
                        <button className='calendarComponent-calendar-current-day-button' onClick={() => handleSubtractOneMonth()}>{'<'}</button>
                        <span>{today.getDate()} {months[month]} {year}</span>
                        <button className='calendarComponent-calendar-current-day-button' onClick={() => handleAddOneMonth()}>{'>'}</button>
                    </div>
                    <AddEvent />
                    <div className="calendarComponent-days-grid">
                        <DaysCubes />
                    </div>
                </div>
                {<div className={`calendarComponent-events-grid ${(todayEvents && todayEvents?.events?.length > 0) ? 'calendarComponent-show-events-sidebar' : ''}`} >
                    {todayEvents && todayEvents?.events?.length > 0 && todayEvents?.events?.map((event, index) => {
                        const day = todayEvents.day
                        const month = todayEvents.month
                        const year = todayEvents.year
                        const queryObject: ICalendarEventsLink = {
                            day,
                            month,
                            year,
                            title: event?.title,
                            email: event?.email,
                            type: event?.type,
                            state: event?.state,
                            timeFrom: event?.timeFrom,
                            timeTo: event?.timeTo
                        }
                        return (
                            <div key={index} className={`calendarComponent-event-item ${event?.state}  ${(todayEvents && todayEvents?.events?.length > 0) ? 'calendarComponent-show-events-sidebar' : ''}`}>
                                <div className="calendarComponent-event-item-badge">
                                    <Badge colorScheme={event?.type === 'Padel' ? 'purple' : 'cyan'}>{event?.type}</Badge>
                                </div>
                                <span className="calendarComponent-event-item-text title">{'Contatti : '}{event?.title}</span>
                                <span className="calendarComponent-event-item-text email">{'Mail : '}{event?.email}</span>
                                <span className="calendarComponent-event-item-text time">{'Dalle ore : '} {event?.timeFrom} {'alle ore : '} {event?.timeTo}</span>
                                {event?.state === 'Confirmed' && <div className='calendarComponent-event-item-icon'><CheckCircleIcon color={'green'} /></div>}
                                {event?.state === 'Pending' && <div className='calendarComponent-event-item-icon not-confirmed-icon'><QuestionIcon color={'yellow'} /></div>}
                                {event?.state === 'Pending' && <Button marginTop={'4px'} colorScheme='green' onClick={() => confirmReservation(queryObject)}>CONFERMA</Button>}
                                <IconButton isLoading={loadingDeleteReservation} className='calendarComponent-event-item-delete-button' colorScheme='red' onClick={() => deleteReservation(queryObject)} aria-label='Elimina prenotazione' icon={<DeleteIcon />} />
                            </div>
                        )
                    })}
                </div>}
            </div>
        </div>
    );
};

export default CalendarComponent;
