import { create } from 'zustand';

export interface IButtonProps {
    text: string;
    onClick: (props?: any) => any;
    modalTitle?: string;
    modalBody?: string | Element | JSX.Element;
    isDisabled?: boolean;
}
export interface ICardsGrid {
    title: string;
    id: number;
    imageUrl?: string;
    imageId?: number;
    bodyText?: string | JSX.Element;
    buttons?: IButtonProps[];
    deleteButton?: IButtonProps;
    phoneNumber?: string;
    website?: string;
    address?: string;
}
export interface ISponsorImage {
    ImageUrl: string;
    ImageId: number;
}
export interface ISponsor {
    Name: string;
    Description: string;
    Image?: ISponsorImage;
    id: number;
    PhoneNumber?: string;
    Website?: string;
    Address?: string;
}

export interface IEditSponsorImage {
    ImageId: number;
    ImageUrl: string;
}
export interface IEditSponsor {
    editSponsorName: string;
    editSponsorDescription: string;
    editSponsorImage: IEditSponsorImage;
    editSponsorPhoneNumber: string;
    editSponsorWebsite: string;
    editSponsorAddress: string;
}

export interface ISponsorStore {
    allSponsors: ISponsor[];
    currentSponsor: ISponsor;
    editSponsorName: string;
    editSponsorDescription: string;
    editSponsorImage: ISponsorImage;
    editSponsorPhoneNumber: string;
    editSponsorWebsite: string;
    editSponsorAddress: string;
    sponsorStoreActions: {
        setAllSponsors: (allSponsors: ISponsor[]) => void;
        setCurrentSponsor: (currentSponsor: ISponsor) => void;
        setEditSponsorName: (editSponsorName: string) => void;
        setEditSponsorDescription: (editSponsorDescription: string) => void;
        setEditSponsorImage: (editSponsorImage: ISponsorImage) => void;
        setEditSponsorPhoneNumber: (editSponsorePhoneNumber: string) => void;
        setEditSponsorWebsite: (editSponsorWebsite: string) => void;
        setEditSponsorAddress: (editSponsorAddress: string) => void;
        resetEditSponsorFields: () => void;
    };
}
export const DEFAULT_SPONSOR_VALUE: ISponsor = {
    Name: '',
    Description: '',
    Image: {
        ImageUrl: '',
        ImageId: 0,
    },
    id: 0,
    PhoneNumber: '',
    Website: '',
    Address: '',
};

export const DEFAULT_EDIT_SPONSOR_VALUE: IEditSponsor = {
    editSponsorName: '',
    editSponsorDescription: '',
    editSponsorImage: {
        ImageId: 0,
        ImageUrl: '',
    },
    editSponsorPhoneNumber: '',
    editSponsorWebsite: '',
    editSponsorAddress: '',
};
const useSponsorStore = create<ISponsorStore>((set) => ({
    allSponsors: [DEFAULT_SPONSOR_VALUE],
    currentSponsor: DEFAULT_SPONSOR_VALUE,
    ...DEFAULT_EDIT_SPONSOR_VALUE,
    sponsorStoreActions: {
        setAllSponsors: (allSponsors: ISponsor[]) => set({ allSponsors }),
        setCurrentSponsor: (currentSponsor: ISponsor) => set({ currentSponsor }),
        setEditSponsorName: (editSponsorName: string) => set({ editSponsorName }),
        setEditSponsorDescription: (editSponsorDescription: string) => set({ editSponsorDescription }),
        setEditSponsorImage: (editSponsorImage: ISponsorImage) => set({ editSponsorImage }),
        setEditSponsorPhoneNumber: (editSponsorPhoneNumber: string) => set({ editSponsorPhoneNumber }),
        setEditSponsorWebsite: (editSponsorWebsite: string) => set({ editSponsorWebsite }),
        setEditSponsorAddress: (editSponsorAddress: string) => set({ editSponsorAddress }),
        resetEditSponsorFields: () =>
            set((state) => ({
                editSponsorName: '',
                editSponsorDescription: '',
                editSponsorImage: {
                    ImageId: 0,
                    ImageUrl: '',
                },
                editSponsorPhoneNumber: '',
                editSponsorWebsite: '',
                editSponsorAddress: '',
            })),
    },
}));

export default useSponsorStore;
