import t from '@/utils/LabelsUtil/labelingUtils';
import StrapiClient, { IExistingMatchDayScores } from '@/utils/hooks/strapiClient';
import useClientStore, { IMatch, IPlayer, IRounds, ITeam } from '@/utils/hooks/useClientStore';
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Divider, HStack, IconButton, Input, Select, Text, VStack } from '@chakra-ui/react';
import React, { ForwardedRef, useEffect, useReducer } from 'react';
import CustomButton from '../CustomButton';
import { customNotificationError } from '../CustomNotifications';
import { IMatchDayScores, IPlayersActions, mapMatchesToMatchdayMutation, mapMatchesToMutation, mapNewPlayersToMutation, mapRankingToMutation } from '@/utils/hooks/complexityHandlers';

interface IModalBodyMatchDayScoresProps {
    roundList: IRounds[];
    playersRanking: IPlayer[];
    ranking: ITeam[];
    competitionID: string | number;
    matchDayScores: IMatchDayScores[]
}

interface IAction {
    type: string;
    payload?: any;
}

const ModalBodyMatchDayScores = React.forwardRef((props: IModalBodyMatchDayScoresProps, ref: ForwardedRef<any>) => {
    const { roundList, playersRanking, ranking, competitionID, matchDayScores } = props;
    const { clientStoreActions } = useClientStore();
    const initialState = {
        matchList: undefined,
        ranking,
        roundList,
        competitionID,
        playersRanking,
        playersToEdit: [],
        newPlayers: [],
        counter: 0,
        mutationCounter: 0,
        matchListIndex: 0,
        mutationMaxCount: 0,
        isFormSubmitted: false,
    };
    /**
     * This function is a reducer used in a React component.
     * It handles various actions and updates the state accordingly.
     *
     * @param state - The current state of the component.
     * @param action - An object that contains the type of action and optional payload data.
     * @returns The updated state after performing the corresponding logic based on the action type.
     */
    const reducer = (state: any, action: IAction) => {
        switch (action.type) {
            case 'setField':
                return {
                    ...state,
                    [action.payload.field]: action.payload.value,
                };
            case 'setMatchListIndex':
                const updatedEditedPlayers = matchDayScores?.find(element => element?.roundID == state?.roundList[action.payload]?.id)?.playersActions?.map((x: IPlayersActions): IPlayer => {
                    return {
                        name: x.Name,
                        surname: x.Surname,
                        goals: x.Goals,
                        cards: x.Warnings,
                        teamName: x.TeamName,
                        id: x.ID?.toString(),
                    }
                }) || [];
                const filteredPlayersRanking = state.playersRanking.filter((x: any) => {
                    if (updatedEditedPlayers?.length > 0) {
                        const isMatchingID = updatedEditedPlayers.some((y: IPlayer) => x.id === y.id);
                        return !isMatchingID;
                    } else {
                        return true;
                    }
                });

                const updatedMatchList = state.matchList?.map((match: IMatch, index: number) => {
                    const updatedMatchdayMatchList = matchDayScores?.find(element => element?.roundID == state?.roundList[action.payload]?.id)?.matches
                    const updatedMatch = updatedMatchdayMatchList?.find((x: any) => x?.ID === match?.id)
                    console.log("🚀 ~ updatedMatchList ~ updatedMatchdayMatchList:", updatedMatchdayMatchList?.hostAutogoals, typeof updatedMatchdayMatchList?.hostAutogoals)
                    if (updatedMatch) {
                        return {
                            ...match,
                            hostAutogoals: parseInt(updatedMatch?.hostAutogoals) || 0,
                            guestAutogoals: parseInt(updatedMatch?.guestAutogoals) || 0,
                        }
                    } else {
                        return {
                            ...match
                        }
                    }
                })
                console.log("🚀 ~ updatedMatchList ~ updatedMatchList:", updatedMatchList)
                return { ...state, matchListIndex: action.payload, playersToEdit: updatedEditedPlayers || [], playersRanking: filteredPlayersRanking, matchList: updatedMatchList };
            case 'setPlayersToEdit':
                const updatedPayload = {
                    ...action.payload,
                    goals: 0,
                    cards: 0,
                }
                return {
                    ...state,
                    playersToEdit: [...state?.playersToEdit, updatedPayload],
                    playersRanking: state.playersRanking.filter((x: any) => x?.id !== action.payload.id),
                };
            case 'setTeamAutogoals': {
                if (action.payload.field === 'host') {
                    state.matchList[action.payload.index].hostAutogoals = action.payload.value
                } else if (action.payload.field === 'guest') {
                    state.matchList[action.payload.index].guestAutogoals = action.payload.value
                }
                return {
                    ...state
                };
            }
            case 'setNewPlayers':
                return {
                    ...state,
                    newPlayers: [...state.newPlayers, action.payload],
                };
            case 'setRemoveNewPlayer': {
                try {

                    const updatedNewPlayers = state?.newPlayers
                    updatedNewPlayers.splice(action.payload.index, 1)
                    return { ...state, counter: state.counter > 0 ? state.counter - 1 : 0, newPlayers: updatedNewPlayers };
                } catch (error) {
                    console.log(error)
                }
            }
            case 'setNewPlayerProperties': {
                const updatedNewPlayers = state.newPlayers.map((player: IPlayer, index: number) => {
                    if (index === action.payload.index) {
                        return {
                            ...player,
                            [action.payload.field]: parseInt(action.payload.value) ? parseInt(action.payload.value) : action.payload.value || undefined,
                        };
                    }
                    return player;
                });
                return { ...state, newPlayers: updatedNewPlayers, newPlayersCurrentValues: updatedNewPlayers };
            }
            case 'setEditedPlayerGoals': {
                const updatedPlayers = state.playersToEdit.map((player: IPlayer) => {
                    if (player?.id === action?.payload?.id) {
                        const goals = parseInt(action.payload.value) ? parseInt(action.payload.value) : 0;
                        return {
                            ...player,
                            goals,
                        };
                    }
                    return player;
                });
                return { ...state, playersToEdit: updatedPlayers, playersToEditCurrentValues: updatedPlayers };
            }
            case 'setEditedPlayerCards': {
                const updatedPlayers = state.playersToEdit.map((player: IPlayer) => {
                    if (player?.id === action?.payload?.id) {
                        const cards = parseInt(action.payload.value) ? parseInt(action.payload.value) : 0;
                        return {
                            ...player,
                            cards,
                        };
                    }
                    return player;
                });

                return { ...state, playersToEdit: updatedPlayers, playersToEditCurrentValues: updatedPlayers };
            }
            case 'setNewPlayersCounter':
                return { ...state, counter: state.counter + 1 };
            case 'setMutationCounter':
                return {
                    ...state,
                    mutationCounter: state.mutationCounter + 1,
                };
            case 'setMutationMaxCount':
                return { ...state, mutationMaxCount: action.payload };
            case 'setIsFormCompleted': {
                if (!state?.isFormCompleted) {
                    const allPlayers: IPlayer[] = [...state?.playersToEdit, ...state?.newPlayers]
                    const updatedMatchList = state?.matchList?.map((match: IMatch) => {
                        const updatedMatchdayMatchList = matchDayScores?.find(element => element?.roundID == state?.roundList[action.payload]?.id)?.matches
                        const updatedMatch = updatedMatchdayMatchList?.find((x: any) => x?.ID === match?.id)
                        const hostAutogoals = (match?.hostAutogoals ? (parseInt(match?.hostAutogoals.toString()) || (match?.hostAutogoals || 0)) : 0) - (updatedMatch?.hostAutogoals ? (parseInt(updatedMatch?.hostAutogoals.toString()) || (updatedMatch?.hostAutogoals || 0)) : 0)
                        const guestAutogoals = (match?.guestAutogoals ? (parseInt(match?.guestAutogoals.toString()) || (match?.guestAutogoals || 0)) : 0) - (updatedMatch?.guestAutogoals ? (parseInt(updatedMatch?.guestAutogoals.toString()) || (updatedMatch?.guestAutogoals || 0)) : 0)
                        const hostGoals = allPlayers?.filter((player: IPlayer) => player.teamName === match.hostName)?.reduce((acc: number, curr: IPlayer) => acc + (parseInt(curr.goals?.toString()) || 0), 0) || 0;
                        const guestGoals = allPlayers?.filter((player: IPlayer) => player.teamName === match.guestName)?.reduce((acc: number, curr: IPlayer) => acc + (parseInt(curr.goals?.toString()) || 0), 0) || 0;
                        const hostCards = allPlayers?.filter((player: IPlayer) => player.teamName === match.hostName)?.reduce((acc: number, curr: IPlayer) => acc + (parseInt(curr.cards?.toString()) || 0), 0) || 0;
                        const guestCards = allPlayers?.filter((player: IPlayer) => player.teamName === match.guestName)?.reduce((acc: number, curr: IPlayer) => acc + (parseInt(curr.cards?.toString()) || 0), 0) || 0;

                        return {
                            ...match,
                            hostGoals: parseInt(hostGoals?.toString()) + guestAutogoals || 0,
                            guestGoals: parseInt(guestGoals?.toString()) + hostAutogoals || 0,
                            hostCards: parseInt(hostCards?.toString()),
                            guestCards: parseInt(guestCards?.toString()),
                            hostAutogoals,
                            guestAutogoals,
                        }
                    })
                    const updatedRanking = state?.ranking?.map((team: ITeam) => {
                        const match = updatedMatchList?.find((match: IMatch) => match?.hostName === team?.name || match?.guestName === team?.name)
                        const isHost = match?.hostName === team?.name
                        const currentRoundID = state?.roundList[state?.matchListIndex]?.id
                        const isMatchDayScoresExisting = matchDayScores?.find((matchDayScore: IMatchDayScores) => matchDayScore?.roundID?.toString() === currentRoundID?.toString())
                        const isMatchPostponed = match?.isPostponed || false
                        const isFilled = [...state?.newPlayers, ...state.playersToEdit]?.find((player: IPlayer) => (player?.teamName === match?.hostName || player?.teamName === match?.guestName)) || undefined
                        if (!isMatchPostponed && isFilled) {
                            if (isMatchDayScoresExisting?.roundID.toString() === currentRoundID.toString()) {
                                const oldMatch = isMatchDayScoresExisting?.matches?.find((oldMatch: any) => match?.id == oldMatch?.ID)
                                const autogoalsDone = (isHost ? parseInt(match?.hostAutogoals?.toString()) : parseInt(match?.guestAutogoals?.toString())) || 0 - (isHost ? parseInt(oldMatch?.hostAutogoals?.toString()) : parseInt(oldMatch?.guestAutogoals?.toString())) || 0
                                const autogoalsReceived = (isHost ? parseInt(match?.guestAutogoals?.toString()) : parseInt(match?.hostAutogoals?.toString())) || 0 - (isHost ? parseInt(oldMatch?.guestAutogoals?.toString()) : parseInt(oldMatch?.hostAutogoals?.toString())) || 0
                                console.log("🚀 ~ updatedRanking ~ autogoalsReceived:", isHost, autogoalsDone, autogoalsReceived)
                                const updatedTeamData = {
                                    ...team,
                                    goalsDone: team.goalsDone + (isHost ? (parseInt(match?.hostGoals?.toString()) || 0) - (parseInt(oldMatch?.HostGoals?.toString()) || 0) : (parseInt(match?.guestGoals?.toString()) || 0) - (parseInt(oldMatch?.GuestGoals?.toString()) || 0)) + autogoalsReceived,
                                    goalsReceived: team.goalsReceived + (isHost ? (parseInt(match?.guestGoals?.toString()) || 0) - (parseInt(oldMatch?.GuestGoals?.toString()) || 0) : (parseInt(match?.hostGoals?.toString()) || 0) - (parseInt(oldMatch?.HostGoals?.toString()) || 0)) + autogoalsDone,
                                    cards: team.cards + (isHost ? (parseInt(match?.hostCards?.toString()) || 0) - (parseInt(oldMatch?.HostWarnings?.toString()) || 0) : (parseInt(match?.guestCards?.toString()) || 0) - (parseInt(oldMatch?.GuestWarnings?.toString()) || 0))
                                }
                                const isHostWin = match?.hostGoals > match?.guestGoals;
                                const isGuestWin = match?.guestGoals > match?.hostGoals;
                                const isDraw = match?.hostGoals === match?.guestGoals;

                                const isOldHostWin = oldMatch?.HostGoals > oldMatch?.GuestGoals;
                                const isOldGuestWin = oldMatch?.GuestGoals > oldMatch?.HostGoals;
                                const isOldDraw = oldMatch?.HostGoals === oldMatch?.GuestGoals;

                                if (isOldHostWin && isGuestWin) {
                                    updatedTeamData.loss += isHost ? 1 : -1;
                                    updatedTeamData.wins += isHost ? -1 : 1;
                                    updatedTeamData.points += isHost ? -3 : +3;
                                } else if (isOldGuestWin && isHostWin) {
                                    updatedTeamData.loss += isHost ? -1 : 1;
                                    updatedTeamData.wins += isHost ? 1 : -1;
                                    updatedTeamData.points += isHost ? +3 : -3;
                                } else if (isOldDraw && isHostWin) {
                                    updatedTeamData.draws -= 1;
                                    updatedTeamData.wins += isHost ? 1 : 0;
                                    updatedTeamData.loss += isHost ? 0 : 1;
                                    updatedTeamData.points += isHost ? +2 : -1;
                                } else if (isOldDraw && isGuestWin) {
                                    updatedTeamData.draws -= 1;
                                    updatedTeamData.wins += isHost ? 0 : 1;
                                    updatedTeamData.loss += isHost ? 1 : 0;
                                    updatedTeamData.points += isHost ? -1 : +2;
                                } else if (isOldHostWin && isDraw) {
                                    updatedTeamData.draws += 1;
                                    updatedTeamData.wins += isHost ? -1 : 0;
                                    updatedTeamData.loss += isHost ? 0 : -1;
                                    updatedTeamData.points += isHost ? -2 : +1;
                                } else if (isOldGuestWin && isDraw) {
                                    updatedTeamData.loss += isHost ? -1 : 0;
                                    updatedTeamData.wins += isHost ? 0 : -1;
                                    updatedTeamData.draws += 1;
                                    updatedTeamData.points += isHost ? +1 : -2;
                                }
                                return updatedTeamData

                            } else {
                                const isDraw = match?.hostGoals === match?.guestGoals
                                const isWin = isHost ? match?.hostGoals > match?.guestGoals : match?.guestGoals > match?.hostGoals;
                                return {
                                    ...team,
                                    points: team.points + (isDraw ? 1 : isWin ? 3 : 0),
                                    playedMatches: team.playedMatches + 1,
                                    wins: team.wins + (isWin ? 1 : 0),
                                    draws: team.draws + (isDraw ? 1 : 0),
                                    loss: team.loss + (!isWin && !isDraw ? 1 : 0),
                                    goalsDone: team.goalsDone + (isHost ? (parseInt(match?.hostGoals?.toString()) || 0) : (parseInt(match?.guestGoals?.toString()) || 0)),
                                    goalsReceived: team.goalsReceived + (isHost ? (parseInt(match?.guestGoals?.toString()) || 0) : (parseInt(match?.hostGoals?.toString()) || 0)),
                                    hostedMatches: team.hostedMatches?.map((x: any) => x?.id || x),
                                    guestMatches: team.guestMatches?.map((x: any) => x?.id || x),
                                    cards: team.cards + (isHost ? (parseInt(match?.hostCards?.toString()) || 0) : (parseInt(match?.guestCards?.toString()) || 0))
                                }
                            }
                        } else return team

                    })
                    state.isFormCompleted = true
                    state.matchList = updatedMatchList
                    state.ranking = updatedRanking
                    return { ...state };
                } return { ...state }
            }
            case 'setIsFormSubmitted': {
                return { ...state, isFormSubmitted: true }
            }
            case 'resetState':
                return initialState;
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    console.log("🚀 ~ ModalBodyMatchDayScores ~ state:", state)
    const client = StrapiClient()



    useEffect(() => {
        if (state?.isFormCompleted) {
            clientStoreActions.setAdminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled(false);
        } else clientStoreActions.setAdminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled(true);
    }, [state?.isFormCompleted]);

    // Add new player on the fly
    const handleAddPlayerButtonClick = (teamName: string) => {
        dispatch({ type: 'setNewPlayersCounter' });
        const DEFAULT_PLAYER_VALUES = {
            name: '',
            surname: '',
            goals: '',
            warnings: '',
            teamName: teamName
        };
        dispatch({ type: 'setNewPlayers', payload: DEFAULT_PLAYER_VALUES });
    };

    useEffect(() => {
        return dispatch({ type: 'resetState' });
    }, []);

    /**
     * Submits form data to the server.
     * @returns {Promise<void>} A promise that resolves when the form data is successfully submitted.
     */
    const handleFormSubmit = async () => {
        if (!state.isFormSubmitted) {
            try {
                const clientMatches = mapMatchesToMutation(state.matchList, state.ranking)
                const clientMatchdayMatches = mapMatchesToMatchdayMutation(state.matchList, state.ranking)
                const clientTeams = mapRankingToMutation(state.ranking)

                const clientExistingPlayers = state?.playersToEdit?.map((player: IPlayer) => {
                    const existingPlayerRanking = playersRanking.find((existingPlayer: IPlayer) => existingPlayer?.id?.toString() === player?.id?.toString());
                    const currentRoundID = state?.roundList[state?.matchListIndex]?.id;
                    const isMatchDayScoresExisting = matchDayScores?.find((matchDayScore: IMatchDayScores) => matchDayScore?.roundID.toString() === currentRoundID?.toString());
                    const matchDayPlayer = isMatchDayScoresExisting?.playersActions?.find((matchDayPlayer: any) => matchDayPlayer?.ID.toString() == player?.id.toString());
                    const playerGoals = (player?.goals || 0) - (matchDayPlayer?.Goals || 0);
                    const playerCards = (player?.cards || 0) - (matchDayPlayer?.Warnings || 0);
                    const Goals = (existingPlayerRanking?.goals || 0) + playerGoals;
                    const Warnings = (existingPlayerRanking?.cards || 0) + playerCards;
                    // TODO GOAL DIFF
                    return {
                        ID: player?.id || 0,
                        Goals: Goals,
                        Assist: 0,
                        Warnings: Warnings,
                    };
                });
                const clientNewPlayers = mapNewPlayersToMutation(state?.newPlayers, state.ranking)

                const playerActions = [...state?.playersToEdit, ...state?.newPlayers]

                const matchDayScoreID = matchDayScores?.find((matchDayScore: IMatchDayScores) => matchDayScore?.roundID === roundList[parseInt(state?.matchListIndex)]?.id)
                const clientNewMatchDayScores: IExistingMatchDayScores = {
                    id: matchDayScoreID?.id || undefined,
                    competitionID: state?.competitionID || 0,
                    roundID: roundList[parseInt(state?.matchListIndex)]?.id || 0,
                    matches: clientMatchdayMatches,
                    playerActions: playerActions?.map((player: IPlayer) => ({
                        ID: player?.id,
                        Goals: player?.goals || 0,
                        Assist: 0,
                        Name: player?.name,
                        Surname: player?.surname,
                        TeamName: player?.teamName,
                        Warnings: player?.cards || 0,
                        MatchID: state?.matchList?.find((match: IMatch) => match?.hostName === player?.teamName || match?.guestName === player?.teamName)?.id || undefined,
                        TeamID: state?.ranking?.find((team: ITeam) => team?.name === player?.teamName)?.id || undefined,
                    })),
                };

                await client.UpdateMatchDayScores(
                    clientMatches,
                    clientTeams,
                    clientExistingPlayers,
                    clientNewPlayers,
                    clientNewMatchDayScores
                );

                dispatch({ type: 'setIsFormSubmitted' })
            } catch (error) {
                customNotificationError('Error submitting form data: ' + error);
            }

        }

    };


    // Used to expose the function handleFormSubmit
    React.useImperativeHandle(ref, () => ({
        handleFormSubmit,
    }));

    const handleCancelIconClick = () => {
        dispatch({ type: 'resetState' });
    };

    return (
        <>
            {roundList?.length && (
                <form>
                    <>
                        {!state?.isFormCompleted && (<VStack>
                            {!state?.matchList && !state?.isMutationStarted && (
                                <Select
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'setField',
                                            payload: {
                                                field: 'matchList',
                                                value: roundList[parseInt(e.target.value)].matches,
                                            },
                                        });
                                        dispatch({
                                            type: 'setMatchListIndex',
                                            payload: e.target.value,
                                        });
                                    }}
                                >
                                    {' '}
                                    <option value={undefined}>{t('modal-body-match-day-scores-first-select-text')}</option>
                                    {roundList?.map((round: IRounds, index: number) => (
                                        <option key={round.id} value={index}>
                                            {`Giornata ${index + 1}`}
                                        </option>
                                    ))}
                                </Select>
                            )}
                            {state?.matchList && state?.matchListIndex && state?.matchList.length > 0 && (
                                <>
                                    <Box position={'relative'} w={'100%'}>
                                        <Text>{`Giornata ${parseInt(state?.matchListIndex) + 1}`}</Text>

                                        <Box position={'absolute'} top={0} right={0}>
                                            <CloseIcon onClick={() => handleCancelIconClick()} />
                                        </Box>
                                    </Box>
                                </>
                            )}
                            {state?.matchList && state.matchList?.length > 0 && (
                                <Accordion allowToggle w={'100%'}>
                                    {(state.matchList as IMatch[]).map((match: IMatch, index) => {
                                        return (
                                            <AccordionItem isDisabled={match?.isPostponed || false}>
                                                <AccordionButton>
                                                    <Box display={'inline-flex'} w={'100%'} justifyContent={'space-evenly'} alignItems={'center'} >
                                                        <Box as="span" flex="1" textAlign="left">
                                                            {`${match.hostName} - ${match.guestName} | ${match?.hostGoals || 0} - ${match?.guestGoals || 0}`}
                                                        </Box>
                                                        {match?.isPostponed && <Badge variant='subtle' colorScheme='red'>
                                                            {t('giornate-component-badge-next')}
                                                        </Badge>}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <VStack pb={'0.7rem'}>
                                                        {playersRanking && playersRanking?.length > 0 && (
                                                            <Select
                                                                value={state?.playersToEdit[0]?.id || undefined}
                                                                onChange={(e) => {
                                                                    if (e.target.value) {
                                                                        dispatch({
                                                                            type: 'setPlayersToEdit',
                                                                            payload: playersRanking.find((x) => x.id === e.target.value),
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <option value={undefined}>{t('modal-body-match-day-scores-second-select-text')}</option>
                                                                {(state.playersRanking as IPlayer[])?.map((playerRanking) => {
                                                                    if (playerRanking?.teamName === match.hostName || playerRanking?.teamName === match.guestName) {
                                                                        return <option value={playerRanking.id} key={playerRanking.id}>{`${playerRanking.name} ${playerRanking.surname}`}</option>;
                                                                    }
                                                                })}
                                                            </Select>
                                                        )}
                                                        {state?.playersToEdit &&
                                                            state?.playersToEdit.length > 0 &&
                                                            state?.playersToEdit?.map(
                                                                (playerToEdit: IPlayer) => {
                                                                    if (playerToEdit?.teamName === match.hostName || playerToEdit?.teamName === match.guestName) {
                                                                        return (
                                                                            <>
                                                                                <HStack key={playerToEdit.id} justifyContent={'space-between'} w={'100%'}>
                                                                                    <Text>
                                                                                        {playerToEdit.name} {playerToEdit.surname}
                                                                                    </Text>
                                                                                    <HStack w={'30%'}>
                                                                                        <i className="bx bx-football"></i>
                                                                                        <Input
                                                                                            variant={'flushed'}
                                                                                            htmlSize={2}
                                                                                            key={'setEditedPlayerGoals'}
                                                                                            width="auto"
                                                                                            size={'xs'}
                                                                                            min={0}
                                                                                            type="number"
                                                                                            value={playerToEdit?.goals}
                                                                                            onChange={(e) => {
                                                                                                dispatch({
                                                                                                    type: 'setEditedPlayerGoals',
                                                                                                    payload: {
                                                                                                        id: playerToEdit.id,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <i
                                                                                            className="bx bxs-square-rounded"
                                                                                            style={{
                                                                                                color: '#ffc900',
                                                                                            }}
                                                                                        ></i>
                                                                                        <Input
                                                                                            variant={'flushed'}
                                                                                            htmlSize={4}
                                                                                            width="auto"
                                                                                            size={'xs'}
                                                                                            key={'setEditedPlayerCards'}
                                                                                            type="number"
                                                                                            value={playerToEdit?.cards}
                                                                                            placeholder="0"
                                                                                            min={0}
                                                                                            onChange={(e) => {
                                                                                                dispatch({
                                                                                                    type: 'setEditedPlayerCards',
                                                                                                    payload: {
                                                                                                        id: playerToEdit.id,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </HStack>
                                                                                </HStack>
                                                                            </>
                                                                        );
                                                                    }
                                                                },
                                                            )}

                                                        <CustomButton text={t('modal-body-match-day-scores-add-players-button-text')} onClick={() => handleAddPlayerButtonClick(match.hostName)} />
                                                        {
                                                            <HStack fontSize={'12px'} w={'100%'} justifyContent={'space-between'}>
                                                                <Box w={'100%'} display={'inline-flex'} alignItems={'center'} justifyContent={'space-around'}>

                                                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} columnGap={'4px'}>
                                                                        {'Autogol Casa'}
                                                                        <Input
                                                                            variant={'flushed'}
                                                                            htmlSize={1}
                                                                            value={match?.hostAutogoals}
                                                                            width="20px"
                                                                            size={'xs'}
                                                                            type="number"
                                                                            placeholder="0"
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'setTeamAutogoals',
                                                                                    payload: {
                                                                                        field: 'host',
                                                                                        index: index,
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                                <Box w={'100%'} display={'inline-flex'} alignItems={'center'} justifyContent={'space-around'}>

                                                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} columnGap={'4px'}>
                                                                        {'Autogol Osp.'}
                                                                        <Input
                                                                            variant={'flushed'}
                                                                            value={match?.guestAutogoals}
                                                                            width="20px"
                                                                            htmlSize={1}
                                                                            size={'xs'}
                                                                            type="number"
                                                                            placeholder="0"
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'setTeamAutogoals',
                                                                                    payload: {
                                                                                        field: 'guest',
                                                                                        index: index,
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </HStack>
                                                        }
                                                        {state?.newPlayers &&
                                                            state?.newPlayers.length > 0 &&
                                                            state?.newPlayers?.map((newPlayer: IPlayer, i: any) => {
                                                                if (newPlayer?.teamName === match.hostName || newPlayer?.teamName === match.guestName || !newPlayer?.teamName) {
                                                                    return (
                                                                        <>
                                                                            {i !== 0 && <Divider variant={'dashed'} />}
                                                                            <HStack key={newPlayer.id} justifyContent={'center'} w={'100%'}>
                                                                                <VStack w={'100%'}>
                                                                                    <HStack w={'100%'}>
                                                                                        <Input
                                                                                            variant={'flushed'}
                                                                                            htmlSize={2}
                                                                                            width="100%"
                                                                                            size={'m'}
                                                                                            placeholder={'Cognome'}
                                                                                            value={newPlayer.surname}
                                                                                            onChange={(e) => {
                                                                                                dispatch({
                                                                                                    type: 'setNewPlayerProperties',
                                                                                                    payload: {
                                                                                                        field: 'surname',
                                                                                                        index: i,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <Input
                                                                                            variant={'flushed'}
                                                                                            htmlSize={2}
                                                                                            width="100%"
                                                                                            size={'m'}
                                                                                            placeholder={'Nome'}
                                                                                            value={newPlayer.name}
                                                                                            onChange={(e) => {
                                                                                                dispatch({
                                                                                                    type: 'setNewPlayerProperties',
                                                                                                    payload: {
                                                                                                        field: 'name',
                                                                                                        index: i,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <IconButton onClick={() => dispatch({ type: 'setRemoveNewPlayer', payload: { index: i } })} aria-label="Remove Player" size="xs" colorScheme="red" icon={<DeleteIcon />} />
                                                                                    </HStack>
                                                                                    <HStack w={'100%'}>
                                                                                        <Select
                                                                                            value={newPlayer.teamName}
                                                                                            onChange={(e) => {
                                                                                                if (e.target.value) {
                                                                                                    dispatch({
                                                                                                        type: 'setNewPlayerProperties',
                                                                                                        payload: {
                                                                                                            field: 'teamName',
                                                                                                            index: i,
                                                                                                            value: e.target.value,
                                                                                                        },
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <option value={match.hostName} key={match.hostName}>{`${match.hostName}`}</option>
                                                                                            <option value={match.guestName} key={match.guestName}>{`${match.guestName}`}</option>
                                                                                        </Select>
                                                                                        <i className="bx bx-football"></i>
                                                                                        <Input
                                                                                            variant={'flushed'}
                                                                                            htmlSize={2}
                                                                                            width="auto"
                                                                                            size={'xs'}
                                                                                            type="number"
                                                                                            placeholder="0"
                                                                                            onChange={(e) => {
                                                                                                dispatch({
                                                                                                    type: 'setNewPlayerProperties',
                                                                                                    payload: {
                                                                                                        field: 'goals',
                                                                                                        index: i,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        <i
                                                                                            className="bx bxs-square-rounded"
                                                                                            style={{
                                                                                                color: '#ffc900',
                                                                                            }}
                                                                                        ></i>
                                                                                        <Input
                                                                                            variant={'flushed'}
                                                                                            htmlSize={4}
                                                                                            width="auto"
                                                                                            size={'xs'}
                                                                                            type="number"
                                                                                            placeholder="0"
                                                                                            onChange={(e) => {
                                                                                                dispatch({
                                                                                                    type: 'setNewPlayerProperties',
                                                                                                    payload: {
                                                                                                        field: 'cards',
                                                                                                        index: i,
                                                                                                        value: e.target.value,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </HStack>
                                                                                </VStack>
                                                                            </HStack>
                                                                        </>
                                                                    );
                                                                }
                                                            })}
                                                    </VStack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        );
                                    })}
                                </Accordion>
                            )}
                            <Button colorScheme="whiteAlpha" onClick={() => dispatch({ type: 'setIsFormCompleted' })}>
                                Salva
                            </Button>
                        </VStack>)}
                        {state?.isFormCompleted && (
                            <VStack>
                                Modifiche effettuate
                                {
                                    state?.matchList.map((match: IMatch) => {
                                        console.log("state?.ranking[state?.matchListIndex]", state?.ranking[state?.matchListIndex], match)
                                        const allPlayers = [...state?.newPlayers, ...state?.playersToEdit]
                                        const homePlayers = allPlayers?.filter((x: IPlayer) => x?.teamName === match.hostName)
                                        const guestPlayers = allPlayers?.filter((x: IPlayer) => x?.teamName === match.guestName)
                                        return (
                                            <Box marginTop={'8px'} w={'100%'}>
                                                <VStack textAlign={'justify'} w={'100%'}>
                                                    <Text>{`${match.hostName} - ${match.guestName} | ${match?.hostGoals || 0} - ${match?.guestGoals || 0}`}</Text>
                                                    {
                                                        homePlayers?.map((x: IPlayer) => {
                                                            return (
                                                                <div className='modal-body-matchday-scores-recap-home-player'><div className='modal-body-matchday-scores-recap-home-player-text'>{`${x?.surname} ${x?.name?.charAt(0).toUpperCase() || x?.name}.`} </div> <div className='modal-body-matchday-scores-recap-home-player-goals-and-cards'>{`${x?.goals || 0}`} <i className="bx bx-football"></i>  {x?.cards || 0}<i
                                                                    className="bx bxs-square-rounded"
                                                                    style={{
                                                                        color: '#ffc900',
                                                                    }}
                                                                ></i>
                                                                </div>

                                                                </div>
                                                            )

                                                        })

                                                    }
                                                    {(match?.hostAutogoals && (match?.hostAutogoals > 0)) && <div className='modal-body-matchday-scores-recap-home-player'><div className='modal-body-matchday-scores-recap-home-player-text'>{`Autogol`} </div>
                                                        <div className='modal-body-matchday-scores-recap-home-player-goals-and-cards'>{`${match.hostAutogoals || 0}`}
                                                            <i style={{ color: 'red' }} className="bx bx-football"></i>
                                                        </div>

                                                    </div>}
                                                    {
                                                        guestPlayers?.map((x: IPlayer) => {
                                                            return (
                                                                <div className='modal-body-matchday-scores-recap-guest-player'><div className='modal-body-matchday-scores-recap-guest-player-text'>{`${x?.surname} ${x?.name?.charAt(0).toUpperCase() || x?.name}.`}</div><div className='modal-body-matchday-scores-recap-guest-player-goals-and-cards'>{`${x?.goals || 0}`} <i className="bx bx-football"></i>  {x?.cards || 0}<i
                                                                    className="bx bxs-square-rounded"
                                                                    style={{
                                                                        color: '#ffc900',
                                                                    }}
                                                                ></i> </div></div>
                                                            )

                                                        })
                                                    }
                                                    {(match?.guestAutogoals && (match?.guestAutogoals > 0)) && <div className='modal-body-matchday-scores-recap-guest-player'><div className='modal-body-matchday-scores-recap-guest-player-text'>{`Autogol`}</div><div className='modal-body-matchday-scores-recap-guest-player-goals-and-cards'>{`${match?.guestAutogoals || 0}`} <i className="bx bx-football"></i></div></div>}


                                                </VStack>
                                            </Box>
                                        )


                                    })
                                }
                            </VStack>
                        )}
                    </>
                </form>
            )}
        </>
    );
});

export default ModalBodyMatchDayScores;

