import { InsertCompetition } from '../Queries/Competition';
import { CreateTeam, UpdateTeam } from '../Queries/Teams';
import { InsertRanking } from '../Queries/Ranking';
import { CreatePlayer, EditPlayer } from '../Queries/Player';
import { InsertRound } from '../Queries/Round';
import { useMutation } from '@apollo/client';
import { CreateMatch, UpdateMatch } from '../Queries/Match';
import {
	CreateMatchDayScoresMutation,
	UpdateMatchDayScoresMutation,
} from '../Queries/MatchDayScores';
import useClientStore from './useClientStore';
import { customNotificationSuccess } from '@/components/CustomNotifications';
type ID = string | number;

type CompetitionInfo = {
	Name: string;
	Ranking?: ID;
	Rounds?: string[];
	DirectElimination?: boolean;
	IsArchived: boolean;
	ID?: ID;
};
type TeamInfo = {
	Points?: number;
	PlayedMatches?: number;
	Wins?: number;
	Draws?: number;
	Loss?: number;
	GoalsDone?: number;
	GoalsReceived?: number;
	Tags?: number;
	Ranking?: ID;
	Players?: PlayerInfo[];
	HostedMatches?: string[] | number[];
	GuestMatches?: string[] | number[];
	ID?: ID;
};
interface NonExistingTeamInfo extends TeamInfo {
	Name: string;
}
interface ExistingTeamInfo extends TeamInfo {
	Points: number;
	PlayedMatches: number;
	Wins: number;
	Draws: number;
	Loss: number;
	GoalsDone: number;
	GoalsReceived: number;
	HostedMatches: string[] | number[];
	GuestMatches: string[] | number[];
	ID: ID;
}
/* type RankingInfo = {
	Teams: string[] | number[];
	Competition: ID;
	ID?: ID;
}; */
type PlayerInfo = {
	Name?: string;
	Surname?: string;
	TeamIndex?: number;
	Goals?: number;
	Assist?: number;
	Warnings?: number;
	Team?: ID;
	ID?: ID;
};
interface NonExistingPlayerInfo extends PlayerInfo {
	Name: string;
	Surname: string;
	TeamIndex: number;
}
interface ExistingPlayerInfo extends PlayerInfo {
	ID: ID;
	Goals: number;
	Assist: number;
	Warnings: number;
}

type RoundInfo = {
	ID?: ID;
	eliminationRound?: string | null;
};
interface ExistingCompetitionRoundInfo extends RoundInfo {
	competition: ID;
	matches: ExistingCompetitionMatchInfo[];
}
interface NonExistingCompetitionRoundInfo extends RoundInfo {
	matches: NonExistingCompetitionMatchInfo[];
}
type MatchInfo = {
	date: string;
	hour: string;
};
interface NonExistingCompetitionMatchInfo extends MatchInfo {
	guestIndex: number;
	hostIndex: number;
}
export interface ExistingCompetitionMatchInfo extends MatchInfo {
	GuestID: ID;
	HostID: ID;
	GuestWarnings?: number;
	HostWarnings?: number;
	GuestGoals?: number;
	HostGoals?: number;
}
export interface ExistingMatchInfo extends MatchInfo {
	ID: ID;
	HostGoals: number;
	HostWarnings: number;
	GuestGoals: number;
	GuestWarnings: number;
	GuestID?: ID;
	HostID?: ID;
	IsPostponed?: boolean;
}

interface IPlayerActions {
	ID: ID;
	Goals: number;
	Assist: number;
	Name: string;
	Surname: string;
	TeamName: string;
	Warnings: number;
	MatchID: ID;
	TeamID: ID;
}
export interface IExistingMatchDayScores {
	id?: ID;
	roundID: ID;
	competitionID: ID;
	matches: ExistingMatchInfo[];
	playerActions: IPlayerActions[];
}
export interface ClientMethods {
	CreateCompetition: (
		champ: CompetitionInfo,
		teams: NonExistingTeamInfo[],
		players: NonExistingPlayerInfo[],
		round: NonExistingCompetitionRoundInfo
	) => void;
	CreateRound: (
		round: ExistingCompetitionRoundInfo,
		matches: ExistingCompetitionMatchInfo[]
	) => void;
	UpdateMatchDayScores: (
		matches: ExistingMatchInfo[],
		teams: ExistingTeamInfo[],
		players: ExistingPlayerInfo[],
		newPlayers?: NonExistingPlayerInfo[],
		matchDayScore?: IExistingMatchDayScores
	) => void;
	UpdateRound: (matches: ExistingMatchInfo[]) => void;
}

export default function StrapiClient(): ClientMethods {
	const { clientStoreActions } = useClientStore();
	const [createCompetition] = useMutation(InsertCompetition);
	const [createTeam] = useMutation(CreateTeam);
	const [updateTeam] = useMutation(UpdateTeam);
	const [createRanking] = useMutation(InsertRanking);
	const [createPlayer] = useMutation(CreatePlayer);
	const [createRound] = useMutation(InsertRound);
	const [createMatch] = useMutation(CreateMatch);
	const [updateMatch] = useMutation(UpdateMatch);
	const [updatePlayer] = useMutation(EditPlayer);
	const [updateMatchDayScoresPlayerActions] = useMutation(
		UpdateMatchDayScoresMutation
	);
	const [createMatchDayScoresPlayerActions] = useMutation(
		CreateMatchDayScoresMutation
	);

	//? New Competition query flow
	/**
	 * Creates a competition in the system.
	 * @param champ - An object containing information about the competition.
	 * @param teams - An array of objects containing information about the teams participating in the competition.
	 * @param players - An array of objects containing information about the players participating in the competition.
	 * @param round - An object containing information about the round of the competition.
	 */
	async function CreateCompetition(
		champ: CompetitionInfo,
		teams: NonExistingTeamInfo[],
		players: NonExistingPlayerInfo[],
		round: NonExistingCompetitionRoundInfo
	) {
		try {
			clientStoreActions.setButtonLoading(true);

			let champId: string | null = null;
			let teamIds: string[] = [];

			// Create Competition
			const createCompetitionResponse = await new Promise<void>((resolve, reject) => {
				createCompetition({
					variables: { data: champ },
					onCompleted(data) {
						champId = data.createCompetition.data.id;
						resolve();
					},
					onError(error) {
						reject(error);
					},
				});
			});

			// Create Teams
			await Promise.all(
				teams.map(async (team) => {
					const createTeamResponse = await new Promise<void>((resolve, reject) => {
						createTeam({
							variables: { data: team },
							onCompleted(data) {
								team.ID = data.createTeam.data.id;
								teamIds.push(data.createTeam.data.id);
								resolve();
							},
							onError(error) {
								reject(error);
							},
						});
					});
				})
			);

			// Create Ranking
			const createRankingResponse = await new Promise<void>((resolve, reject) => {
				createRanking({
					variables: {
						data: {
							Competition: champId,
							Teams: teamIds,
						},
					},
					onCompleted() {
						resolve();
					},
					onError(error) {
						reject(error);
					},
				});
			});

			// Create Players
			await Promise.all(
				players.map(async (player) => {
					const createPlayerResponse = await new Promise<void>((resolve, reject) => {
						createPlayer({
							variables: {
								data: {
									Name: player.Name,
									Surname: player.Surname,
									Team: teams[player.TeamIndex].ID,
								},
							},
							onCompleted() {
								resolve();
							},
							onError(error) {
								reject(error);
							},
						});
					});
				})
			);

			// Create Round
			let roundID: string | number | null = null;
			const createRoundResponse = await new Promise<void>((resolve, reject) => {
				createRound({
					variables: {
						data: {
							Competition: champId,
							Stage: round.eliminationRound,
						},
					},
					onCompleted(data) {
						roundID = data.createRound.data.id;
						resolve();
					},
					onError(error) {
						reject(error);
					},
				});
			});

			// Create Matches
			await Promise.all(
				round.matches.map(async (match) => {
					const createMatchResponse = await new Promise<void>((resolve, reject) => {
						createMatch({
							variables: {
								data: {
									Date: match.date,
									Hour: match.hour,
									GuestTeam: teams[match.guestIndex].ID,
									HostTeam: teams[match.hostIndex].ID,
									Round: roundID,
									IsPostponed : false,
								},
							},
							onCompleted() {
								resolve();
							},
							onError(error) {
								reject(error);
							},
						});
					});
				})
			);

			clientStoreActions.setButtonLoading(false);
			document.dispatchEvent(new Event('close-custom-modal'));
		} catch (error) {
			// Handle errors if necessary
			console.error(error);
		}
	}


	//? Create Round query flow createRound -> createMatch
	async function CreateRound(
		round: ExistingCompetitionRoundInfo,
		matches: ExistingCompetitionMatchInfo[]
	) {
		try {
			clientStoreActions.setButtonLoading(true);
			let roundID: string | number | null = null;

			// Create Round
			const createRoundResponse = await new Promise<void>((resolve, reject) => {
				createRound({
					variables: {
						data: {
							Competition: round.competition,
							Stage: round.eliminationRound || null,
						},
					},
					onCompleted(data) {
						roundID = data.createRound.data.id;
						resolve();
					},
					onError(error) {
						reject(error);
					},
				});
			});

			// Create Matches
			const updateRoundPromises = matches.map(async (match) => {
				await new Promise<void>((resolve, reject) => {
					createMatch({
						variables: {
							data: {
								Date: match.date,
								Hour: match.hour,
								GuestTeam: match.GuestID,
								HostTeam: match.HostID,
								Round: roundID,
								GuestGoals: match?.GuestGoals || 0,
								HostGoals: match?.HostGoals || 0,
								GuestWarnings: match?.GuestWarnings || 0,
								HostWarnings: match?.HostWarnings || 0,
							},
						},
						onCompleted() {
							resolve();
						},
						onError(error) {
							reject(error);
						},
					});
				});
			});

			await Promise.all(updateRoundPromises);

			clientStoreActions.setButtonLoading(false);
			clientStoreActions.setAdminCompetitionModalEditMatchDayAdvanceButtonDisabled(true);
			document.dispatchEvent(new Event('close-custom-modal'));
		} catch (error) {
			// Handle errors if necessary
			console.error(error);
		}
	}



	//? Create match day score query flow  updateMatch -> updateTeam -> updatePlayer
	async function UpdateMatchDayScores(
		matches: ExistingMatchInfo[],
		teams: ExistingTeamInfo[],
		players: ExistingPlayerInfo[],
		newPlayers?: NonExistingPlayerInfo[],
		matchDayScore?: IExistingMatchDayScores
	) {
		try {
			clientStoreActions.setButtonLoading(true);

			const matchPromises = matches.map(async (match) => {
				await new Promise<void>((resolve, reject) => {
					updateMatch({
						variables: {
							id: match.ID,
							data: {
								GuestGoals: parseInt(match.GuestGoals?.toString()) || 0,
								GuestWarnings: parseInt(match.GuestWarnings?.toString()) || 0,
								HostGoals: parseInt(match.HostGoals?.toString()) || 0,
								HostWarnings: parseInt(match.HostWarnings?.toString()) || 0,
							},
						},
						onCompleted() {
							resolve();
						},
						onError(error) {
							reject(error);
						},
					});
				});
			});

			const teamPromises = teams.map(async (team) => {
				await new Promise<void>((resolve, reject) => {
					updateTeam({
						variables: {
							id: team.ID,
							data: {
								Points: team.Points,
								PlayedMatches: team.PlayedMatches,
								Wins: team.Wins,
								Draws: team.Draws,
								Loss: team.Loss,
								GoalsDone: team.GoalsDone,
								GoalsReceived: team.GoalsReceived,
								HostedMatches: team.HostedMatches,
								GuestMatches: team.GuestMatches,
								Tags: team.Tags,
							},
						},
						onCompleted() {
							resolve();
						},
						onError(error) {
							reject(error);
						},
					});
				});
			});

			const playerPromises = players.map(async (player) => {
				await new Promise<void>((resolve, reject) => {
					updatePlayer({
						variables: {
							id: player.ID,
							data: {
								Goals: parseInt(player.Goals?.toString()) || 0,
								Assists: 0,
								Warnings: parseInt(player.Warnings?.toString()) || 0,
							},
						},
						onCompleted() {
							resolve();
						},
						onError(error) {
							reject(error);
						},
					});
				});
			});

			const createPlayerPromises :  Promise<void>[] | undefined = (newPlayers ?? [])?.map(async (newPlayer) => {
				await new Promise<void>(async (resolve) => {
					const { data } = await createPlayer({
						variables: {
							data: {
								Name: newPlayer?.Name,
								Surname: newPlayer?.Surname,
								Team: newPlayer?.TeamIndex,
								Goals: parseInt(newPlayer?.Goals?.toString() || '0') || 0,
								Assists: 0,
								Warnings: parseInt(newPlayer?.Warnings?.toString() || '0') || 0,
							},
						},
					});

					if (data?.createPlayer?.data?.id) {
						const cleanData = {
							id: data?.createPlayer?.data?.id,
							Name: data?.createPlayer?.data?.attributes?.Name,
							Surname: data?.createPlayer?.data?.attributes?.Surname,
							TeamIndex: data?.createPlayer?.data?.attributes?.Team?.data?.id,
							Goals: parseInt(data?.createPlayer?.data?.attributes?.Goals?.toString() || '0'),
							Warnings: parseInt(data?.createPlayer?.data?.attributes?.Warnings.toString() || '0'),
						};

						if (matchDayScore && matchDayScore?.playerActions?.length > 0) {
							matchDayScore?.playerActions?.forEach((x, i) => {
								if (!(x?.ID === cleanData?.id) && !x.ID && cleanData.Name === x.Name && cleanData.Surname === x.Surname) {
									matchDayScore.playerActions[i].ID = cleanData?.id;
								}
							});
						}
					}

					resolve();
				});
			});
			
			await Promise.all(matchPromises);
			await Promise.all(teamPromises);
			await Promise.all(createPlayerPromises);
			await Promise.all(playerPromises);

			if (matchDayScore?.id) {
				const updateMatchDayScorePromise = new Promise<void>((resolve) => {
					updateMatchDayScoresPlayerActions({
						variables: {
							id: matchDayScore?.id,
							data: {
								RoundID: matchDayScore?.roundID,
								CompetitionID: matchDayScore?.competitionID,
								PlayersActions: matchDayScore?.playerActions,
								Matches: matchDayScore?.matches,
							},
						},
						onCompleted() {
							resolve();
						},
					});
				});
				await Promise.resolve(updateMatchDayScorePromise);
			} else if (!matchDayScore?.id) {
				const createMatchDayScoresPromise = new Promise<void>((resolve) => {
					createMatchDayScoresPlayerActions({
						variables: {
							data: {
								CompetitionID: matchDayScore?.competitionID,
								PlayersActions: matchDayScore?.playerActions,
								RoundID: matchDayScore?.roundID,
								Matches: matchDayScore?.matches,
							},
						},
						onCompleted() {
							resolve();
						},
					});
				});
				await Promise.resolve(createMatchDayScoresPromise);
			}

			clientStoreActions.setButtonLoading(false);
			clientStoreActions.setAdminCompetitionModalEditMatchDayAdvanceButtonDisabled(true);
			document.dispatchEvent(new Event('close-custom-modal'));
		} catch (error) {
			// Handle errors if necessary
			console.error(error);
		}
	}


	function UpdateRound(matches: ExistingMatchInfo[]) {
		const updateRoundPromises: Promise<void>[] = [];
		for (const match of matches) {
			const promise = new Promise<void>((resolve) => {
				updateMatch({
					variables: {
						id: match.ID,
						data: {
							Date: match.date,
							Hour: match.hour,
							GuestTeam: match.GuestID,
							HostTeam: match.HostID,
							IsPostponed : match?.IsPostponed || false,
						},
					},
					onCompleted(data) {
						console.log(data);
						resolve();
					},
				});
			});
			updateRoundPromises.push(promise);
		}
		Promise.all(updateRoundPromises)
			.then(() => {
				clientStoreActions.setAdminCompetitionModalEditMatchDayAdvanceButtonDisabled(
					true
				);
				clientStoreActions.setButtonLoading(false);
				document.dispatchEvent(new Event('close-custom-modal'));
				customNotificationSuccess('Giornata aggiornata correttamente');
			})
			.catch((error) => {
				console.error(error);
			});
	}

	return {
		CreateCompetition,
		CreateRound,
		UpdateMatchDayScores,
		UpdateRound
	};
}