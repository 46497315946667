import useClientStore, {
	DEFAULT_VALUE_SELECTEDCOMPETITION,
	IMatch,
	IRounds,
	ITeam,
} from '@/utils/hooks/useClientStore';
import {
	VStack,
	Select,
	Box,
	Text,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	HStack,
	Input,
	Divider,
	Stack,
	Checkbox,
} from '@chakra-ui/react';
import moment from 'moment';
import {
	useReducer,
	forwardRef,
	useImperativeHandle,
	useEffect,
	ForwardedRef,
} from 'react';
import { customNotificationError } from '../CustomNotifications';
import StrapiClient from '@/utils/hooks/strapiClient';
import { itemCounter } from '@/utils/hooks/complexityHandlers';

interface IModalBodyCreateMatchDayProps {
	roundList: IRounds[];
	ranking: any;
	competitionID: string | number;
}

interface IAction {
	type: string;
	payload?: any;
}

const newMatch = () => ({
	id: 0,
	date: '',
	hour: '',
	hostName: '',
	hostGoals: 0,
	hostCards: 0,
	guestName: '',
	guestGoals: 0,
	guestCards: 0,
});

const whatRoundIsReturn = (roundList: IRounds[]) => {
	const roundOfReturn: string[] = roundList?.map((x: any) => {
		if (x.eliminationRound) {
			return x.eliminationRound
		}
	});;

	const howManyOttavi = itemCounter(roundOfReturn, 'ottavi');
	const howManyQuarti = itemCounter(roundOfReturn, 'quarti');
	const howManySemi = itemCounter(roundOfReturn, 'semi');
	const howManyFinale = itemCounter(roundOfReturn, 'finale');
	const updatedStage = () => {
		if (
			howManyOttavi === 1 &&
			!howManyQuarti &&
			!howManySemi &&
			!howManyFinale
		) {
			return 'ottavi';
		} else if (
			(howManyOttavi === 2 || !howManyOttavi) &&
			howManyQuarti === 1 &&
			!howManySemi &&
			!howManyFinale
		) {
			return 'quarti';
		} else if (
			(howManyOttavi === 2 || !howManyOttavi) &&
			(howManyQuarti === 2 || !howManyQuarti) &&
			howManySemi === 1 &&
			!howManyFinale
		) {
			return 'semi';
		} else return 'finale';

	};
	const lastRound = roundList?.find(
		(x) => x.eliminationRound === updatedStage()
	);
	const newRoundMatches = lastRound?.matches?.map((x: IMatch, i) => {
		const updatedNewMatch = newMatch();
		updatedNewMatch.hostName = x.guestName;
		updatedNewMatch.guestName = x.hostName;
		return updatedNewMatch;
	});
	return {
		eliminationRound: updatedStage(),
		matches: newRoundMatches || [],
	};
};

const ModalBodyCreateMatchDay = forwardRef(
	(
		{ competitionID, roundList, ranking }: IModalBodyCreateMatchDayProps,
		ref: ForwardedRef<any>
	) => {
		const initialState = {
			roundList: roundList,
			ranking: ranking,
			newRound: {
				eliminationRound: null,
				matches: Array((Math.floor(ranking?.length / 2))).fill(
					DEFAULT_VALUE_SELECTEDCOMPETITION.rounds[0].matches[0]
				),
			},
			mutationCounter: 0,
			matchListIndex: roundList?.length,
			mutationMaxCount: 0,
			isMutationStarted: false,
			isNewRoundCompleted: false,
			isReturnMatch: false,
		};

		const reducer = (state: any, action: IAction) => {
			switch (action.type) {
				case 'setField':
					return {
						...state,
						[action.payload.field]: action.payload.value,
					};
				case 'setMatchListIndex':
					return { ...state, matchListIndex: action.payload };
				case 'setRoundMatchProperties':
					const updatedNewTeam = { ...state.newRound }; // Create a shallow copy
					updatedNewTeam.matches[action.payload.index] = {
						...updatedNewTeam.matches[action.payload.index], // Create a shallow copy
						[action.payload.field]: action.payload.value,
					};
					return { ...state, newRound: updatedNewTeam };
				case 'setEliminationRound': {
					state.newRound.eliminationRound = action.payload.value;
					state.newRound.matches = Array(
						(action.payload.value === 'ottavi'
							? 8
							: action.payload.value === 'quarti'
								? 4
								: action.payload.value === 'semi'
									? 2
									: 1)
					).fill(
						DEFAULT_VALUE_SELECTEDCOMPETITION.rounds[0].matches[0]
					);
					return { ...state };
				}
				case 'setIsReturnMatch': {
					if (action.payload.value === true) {
						const updatedRound = whatRoundIsReturn(roundList);
						state.newRound = updatedRound;
						return { ...state, isReturnMatch: action.payload.value, newRound: updatedRound };
					}
					return { ...state, isReturnMatch: action.payload.value };
				}
				case 'resetState':
					return initialState;
				default:
					return state;
			}
		};

		const [state, dispatch] = useReducer(reducer, initialState);

		const { clientStoreActions } = useClientStore();
		const client = StrapiClient();

		useEffect(() => {
			return dispatch({ type: 'resetState' });
		}, []);

		const handleFormSubmit = async () => {
			const hostGuestID = state?.newRound?.matches?.map((x: IMatch) => {
				return {
					GuestID: state?.ranking.find(
						(y: ITeam) => y.name === x.guestName
					)?.id,
					HostID: state?.ranking.find(
						(y: ITeam) => y.name === x.hostName
					)?.id,
					date: x.date,
					hour: x.hour,
					GuestGoals: x.guestGoals || 0,
					GuestCards: x.guestCards || 0,
					HostGoals: x.hostGoals || 0,
					HostCards: x.hostCards || 0,
				};
			});
			client.CreateRound(
				{
					matches: hostGuestID,
					competition: competitionID,
					eliminationRound: state?.newRound?.eliminationRound || null,
				},
				hostGuestID
			);
			//document.dispatchEvent(new Event('close-custom-modal'));
		};

		useImperativeHandle(ref, () => ({
			handleFormSubmit,
		}));

		const handleSave = (): void => {
			function checkProperties(objects: IMatch[]) {
				for (const obj of objects) {
					if (
						!obj.date ||
						!obj.hour ||
						!obj.guestName ||
						!obj.hostName
					) {
						return false;
					}
				}
				return true;
			}
			if (!checkProperties(state?.newRound.matches)) {
				customNotificationError(
					'Tutti i campi di tute le partite devono essere compilati'
				);
			} else {
				dispatch({
					type: 'setField',
					payload: { field: 'isNewRoundCompleted', value: true },
				});
				clientStoreActions.setAdminCompetitionModalCreateMatchDayAdvanceButtonDisabled(
					false
				);
			}
		};
		const DEFAULT_ELIMINATION_ROUNDS = [
			'ottavi',
			'quarti',
			'semi',
			'finale',
		];
		function splitArrayFromMatchToEnd(match: string): string[] {
			const index = DEFAULT_ELIMINATION_ROUNDS.indexOf(match);
			if (index !== -1) {
				return DEFAULT_ELIMINATION_ROUNDS.slice(index);
			}
			return DEFAULT_ELIMINATION_ROUNDS;
		}

		return (
			<>
				{roundList?.length && (
					<form>
						{!state?.isNewRoundCompleted && (
							<VStack>
								{state?.roundList &&
									state?.roundList.length > 0 && (
										<>
											<Box
												position={'relative'}
												w={'100%'}
											>
												<Text>{`Giornata ${parseInt(
													state?.matchListIndex
												) + 1
													}`}</Text>
											</Box>
										</>
									)}
								{state?.newRound?.matches && (
									<>
										<Accordion w={'100%'}>
											{roundList[0]?.eliminationRound &&
												splitArrayFromMatchToEnd(
													roundList[0]
														?.eliminationRound
												) && (
													<Box marginBottom={'16px'}>
														<Stack
															spacing={5}
															direction="row"
														>
															<Checkbox
																onChange={(e) =>
																	dispatch({
																		type: 'setIsReturnMatch',
																		payload:
																		{
																			value: e
																				.target
																				.checked,
																		},
																	})
																}
															>
																Gara di ritorno?
															</Checkbox>
														</Stack>
														{!state?.isReturnMatch && (
															<Select
																onChange={(
																	e
																) => {
																	dispatch({
																		type: 'setEliminationRound',
																		payload:
																		{
																			value: e
																				.target
																				.value,
																		},
																	});
																}}
															>
																<option>
																	Seleziona
																	Turno
																</option>
																{splitArrayFromMatchToEnd(
																	roundList[0]
																		?.eliminationRound
																).map(
																	(
																		x: any
																	) => {
																		return (
																			<option
																				value={
																					x
																				}
																			>
																				{
																					x
																				}
																			</option>
																		);
																	}
																)}
															</Select>
														)}
													</Box>
												)}
											{(!roundList[0]?.eliminationRound ||
												(roundList[0]
													?.eliminationRound &&
													state?.newRound
														?.eliminationRound) ||
												state?.isReturnMatch) &&
												state?.newRound?.matches?.map(
													(x: any, i: any) => {
														return (
															<AccordionItem>
																<AccordionButton>
																	<Text
																		w={
																			'100%'
																		}
																	>
																		{(state
																			.newRound
																			.matches[
																			i
																		]
																			.hostName ||
																			'Squadra Casa') +
																			' - ' +
																			(state
																				.newRound
																				.matches[
																				i
																			]
																				.guestName ||
																				'Squadra Ospite')}
																	</Text>
																	<AccordionIcon />
																</AccordionButton>
																<AccordionPanel
																	pb={4}
																>
																	<HStack
																		pb={
																			'0.7rem'
																		}
																	>
																		<Input
																			value={
																				x.date
																			}
																			color={
																				'white'
																			}
																			type="date"
																			onChange={(
																				e
																			) => {
																				dispatch(
																					{
																						type: 'setRoundMatchProperties',
																						payload:
																						{
																							index: i,
																							field: 'date',
																							value: moment(
																								e
																									.target
																									.value
																							).format(
																								'YYYY-MM-DD'
																							),
																						},
																					}
																				);
																			}}
																		/>
																		<Input
																			value={
																				x.hour
																			}
																			type="time"
																			onChange={(
																				e
																			) =>
																				dispatch(
																					{
																						type: 'setRoundMatchProperties',
																						payload:
																						{
																							index: i,
																							field: 'hour',
																							value:
																								e
																									.target
																									.value +
																								':00.000',
																						},
																					}
																				)
																			}
																		/>
																	</HStack>
																	<HStack>
																		{state
																			?.ranking
																			?.length && (
																				<Select
																					value={
																						x.hostName
																					}
																					onChange={(
																						e
																					) => {
																						dispatch(
																							{
																								type: 'setRoundMatchProperties',
																								payload:
																								{
																									index: i,
																									field: 'hostName',
																									value: e
																										.target
																										.value,
																								},
																							}
																						);
																					}}
																				>
																					<option>
																						Seleziona
																						team
																					</option>
																					{state?.ranking?.sort((a : ITeam, b: ITeam) => a.name.localeCompare(b.name))
																					?.map(
																						(
																							x: any
																						) => {
																							return (
																								<option
																									value={
																										x.name
																									}
																								>
																									{
																										x.name
																									}
																								</option>
																							);
																						}
																					)}
																				</Select>
																			)}
																		{ranking?.length && (
																			<Select
																				value={
																					x.guestName
																				}
																				onChange={(
																					e
																				) => {
																					dispatch(
																						{
																							type: 'setRoundMatchProperties',
																							payload:
																							{
																								index: i,
																								field: 'guestName',
																								value: e
																									.target
																									.value,
																							},
																						}
																					);
																				}}
																			>
																				<option>
																					Seleziona
																					team
																				</option>
																				{state?.ranking?.sort((a : ITeam, b: ITeam) => a.name.localeCompare(b.name))
																				?.map(
																					(
																						x: any
																					) => {
																						return (
																							<option
																								value={
																									x.name
																								}
																							>
																								{
																									x.name
																								}
																							</option>
																						);
																					}
																				)}
																			</Select>
																		)}
																	</HStack>
																</AccordionPanel>
															</AccordionItem>
														);
													}
												)}
										</Accordion>
										<Button
											colorScheme="whiteAlpha"
											onClick={() => handleSave()}
										>
											Salva
										</Button>
									</>
								)}
							</VStack>
						)}
						{state?.isNewRoundCompleted && (
							<VStack>
								{state?.newRound?.matches?.map((x: any) => {
									return (
										<Box w={'100%'}>
											<Text
												as={'b'}
											>{`${x.hostName} - ${x.guestName}`}</Text>
											<Text>
												{`Data ${moment(
													x.date,
													'YYYY-MM-DD'
												).format('DD/MM')}`}{' '}
											</Text>
											<Text>
												{`Orario ${moment(
													x.hour,
													'HH:mm:ss.SSS'
												).format('HH:mm')}`}{' '}
											</Text>
											<Divider />
										</Box>
									);
								})}
							</VStack>
						)}
					</form>
				)}
			</>
		);
	}
);

export default ModalBodyCreateMatchDay;
