import './GiornateComponent.css';
import useClientStore, { IRounds } from '@/utils/hooks/useClientStore';
import t from '@/utils/LabelsUtil/labelingUtils';
import { Badge } from '@chakra-ui/react';

const GiornateComponent = ({ giornate }: { giornate: IRounds }) => {
    const { selectedRoundIndex, clientStoreActions } = useClientStore();

    return (
        <>
            {
                <div className="GiornateComponent-container">
                    <div className="GiornateComponent-buttonAndTitle-container">
                        <button onClick={clientStoreActions.setDecrementSelectedRoundIndex} className={'GiornateComponent-buttonAndTitle-button'}>
                            {t('giornate-component-button-previous')}
                        </button>
                        <p>
                            {t('giornate-component-header-text')} {selectedRoundIndex + 1}
                        </p>
                        <button onClick={clientStoreActions.setIncrementSelectedRoundIndex} className={'GiornateComponent-buttonAndTitle-button'}>
                            {t('giornate-component-button-next')}
                        </button>
                    </div>
                    <div className="GiornateComponent-scontri-container">
                        {giornate?.matches?.map((x, i) => {
                            return (
                                <div key={i + x.date} className="GiornateComponent-scontroDiretto-container">
                                    {!x.isPostponed && <div className="GiornateComponent-orarioEData">
                                        <p>{x.date}</p>
                                        <p>{x.hour}</p>
                                    </div>}
                                    {x.isPostponed && <div className="GiornateComponent-orarioEData">
                                        <Badge variant='subtle' colorScheme='green'>
                                            {t('giornate-component-badge-next')}
                                        </Badge>
                                    </div>}
                                    <div className="GiornateComponent-squadreNome-container">
                                        <p className="GiornateComponent-squadraCasa-nome">{x.hostName}</p>
                                        <p className="GiornateComponent-squadraOspite-nome">{x.guestName}</p>
                                    </div>
                                    <div className="GiornateComponent-squadreRisultato-container">
                                        <p className="GiornateComponent-squadraCasa-risultato">{x.isPostponed ? "" : x.hostGoals}</p>
                                        <p className="GiornateComponent-squadraOspite-risultato">{x.isPostponed ? "" : x.guestGoals}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    );
};
export default GiornateComponent;

// Each game template
// <div className="GiornateComponent-orarioEData">
// <p>24/02/2023</p>
// <p>21:00</p>
// </div>
// <div className="GiornateComponent-squadreNome-container">
// <p className="GiornateComponent-squadraCasa-nome">TestCasa</p>
// <p className="GiornateComponent-squadraOspite-nome">TestOspite</p>
// </div>
// <div className="GiornateComponent-squadreRisultato-container">
// <p className="GiornateComponent-squadraCasa-risultato">0</p>
// <p className="GiornateComponent-squadraOspite-risultato">1</p>
// </div>
