import { Text, Box } from '@chakra-ui/react';
import React from 'react';

interface IModalBodyArchiveCompetitionProps {
    competitionName: string;
}

const ModalBodyArchiveCompetition: React.FC<IModalBodyArchiveCompetitionProps> = ({ competitionName }) => {
    return (
        <Box w={'100%'}>
            <Text>{`Sei sicuro di voler archiviare la competizione ${competitionName}?`}</Text>
        </Box>
    );
};

export default ModalBodyArchiveCompetition;
