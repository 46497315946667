import labels, { LabelType } from './labels';

const t = (key: LabelType, ...parts: string[]): string => {
    const label = labels[key];
    if (!label) {
        return key;
    }
    // @ts-ignore
    return label.replace(/%s/g, () => parts.shift());
};

export default t;
