import { create } from 'zustand';

export const API_URL = import.meta.env.VITE_RESERVATION_ENDPOINT_URL;

export enum EReservationType {
    'Padel',
    'Calciotto',
    'Calcetto',
}

export interface IEvent {
    title: string;
    timeFrom: string;
    timeTo: string;
    state: string;
    type: string;
}

export interface IEventDate {
    day: number;
    month: number;
    year: number;
    events: IEvent[];
}

export interface IReservationStore {
    reservationType: EReservationType;
    reservationName: string;
    reservationHourFrom: string;
    reservationHourTo: string;
    reservationDay: string;
    reservationEmail: string;
    reservationPhone: string;
    reservationsSchedule: IEventDate[];
    reservationGeneratedHours: string[];
    reservationStoreActions: {
        setReservationType: (reservationType: EReservationType) => void;
        setReservationName: (reservationName: string) => void;
        setReservationHourFrom: (reservationHourFrom: string) => void;
        setReservationHourTo: (reservationHourFrom: string) => void;
        setReservationDay: (reservationDay: string) => void;
        setReservationEmail: (reservationEmail: string) => void;
        setReservationPhone: (reservationPhone: string) => void;
        setReservationSchedule: (reservationSchedule: IEventDate[]) => void;
        setReservationGeneratedHours: (reservationGeneratedHours: string[]) => void;
        resetReservationFields: () => void;
    };
    joinUsName: string;
    joinUsSecondName: string;
    joinUsNumber: string;
    joinUsEmail: string;
    joinUsNotes: string;
    joinUsType: EReservationType;
    joinUsConsentWhatsapp: boolean;
    joinUsStoreActions: {
        setJoinUsName: (joinUsName: string) => void;
        setJoinUsSecondName: (joinUsSecondName: string) => void;
        setJoinUsNumber: (joinUsNumber: string) => void;
        setJoinUsEmail: (joinUsEmail: string) => void;
        setJoinUsNotes: (joinUsNotes: string) => void;
        setJoinUsType: (joinUsType: EReservationType) => void;
        setJoinUsConsentWhatsapp: (joinUsConsentWhatsapp: boolean) => void;
        resetJoinUsFields: () => void;
    };
}

const initialValuesReservation = {
    reservationType: EReservationType.Padel,
    reservationName: '',
    reservationHourFrom: '',
    reservationHourTo: '',
    reservationDay: '',
    reservationEmail: '',
    reservationPhone: '',
};
const initialValuesJoinUs = {
    joinUsType: EReservationType.Padel,
    joinUsName: '',
    joinUsSecondName: '',
    joinUsNumber: '',
    joinUsEmail: '',
    joinUsNotes: '',
    joinUsConsentWhatsapp: true,
};

const DEFAULT_RESERVATION_SCHEDULE_VALUE: IEventDate[] = [
    {
        day: 0,
        month: 0,
        year: 0,
        events: [
            {
                title: '',
                timeFrom: '',
                timeTo: '',
                state: '',
                type: '',
            },
        ],
    },
];

const useReservationStore = create<IReservationStore>((set) => ({
    ...initialValuesReservation,
    ...initialValuesJoinUs,
    reservationsSchedule: DEFAULT_RESERVATION_SCHEDULE_VALUE,
    reservationGeneratedHours: [],
    reservationStoreActions: {
        setReservationType: (reservationType: EReservationType) => set({ reservationType }),
        setReservationName: (reservationName: string) => set({ reservationName }),
        setReservationHourFrom: (reservationHourFrom: string) => set({ reservationHourFrom }),
        setReservationHourTo: (reservationHourTo: string) => set({ reservationHourTo }),
        setReservationDay: (reservationDay: string) => set({ reservationDay }),
        setReservationEmail: (reservationEmail: string) => set({ reservationEmail }),
        setReservationPhone: (reservationPhone: string) => set({ reservationPhone }),
        setReservationSchedule: (reservationsSchedule: IEventDate[]) => set({ reservationsSchedule }),
        setReservationGeneratedHours: (reservationGeneratedHours: string[]) => set({reservationGeneratedHours}),
        resetReservationFields: () =>
            set((state) => ({
                reservationDay: initialValuesReservation.reservationDay,
                reservationName: initialValuesReservation.reservationName,
                reservationHourFrom: initialValuesReservation.reservationHourFrom,
                reservationHourTo: initialValuesReservation.reservationHourTo,
                reservationEmail: initialValuesReservation.reservationEmail,
                reservationPhone: initialValuesReservation.reservationPhone,
                reservationType: initialValuesReservation.reservationType,
                reservationGeneratedHours : []
            })),
    },
    joinUsStoreActions: {
        setJoinUsName: (joinUsName: string) => set({ joinUsName }),
        setJoinUsSecondName: (joinUsSecondName: string) => set({ joinUsSecondName }),
        setJoinUsNumber: (joinUsNumber: string) => set({ joinUsNumber }),
        setJoinUsEmail: (joinUsEmail: string) => set({ joinUsEmail }),
        setJoinUsNotes: (joinUsNotes: string) => set({ joinUsNotes }),
        setJoinUsType: (joinUsType: EReservationType) => set({ joinUsType }),
        setJoinUsConsentWhatsapp: (joinUsConsentWhatsapp: boolean) => set({ joinUsConsentWhatsapp }),
        resetJoinUsFields: () =>
            set((state) => ({
                joinUsName: initialValuesJoinUs.joinUsName,
                joinUsSecondName: initialValuesJoinUs.joinUsSecondName,
                joinUsNumber: initialValuesJoinUs.joinUsNumber,
                joinUsEmail: initialValuesJoinUs.joinUsEmail,
                joinUsNotes: initialValuesJoinUs.joinUsNotes,
                joinUsType: initialValuesJoinUs.joinUsType,
                joinUsConsentWhatsapp: initialValuesJoinUs.joinUsConsentWhatsapp,
            })),
    },
}));

export default useReservationStore;
