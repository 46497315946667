const labels = {
    'home-page.title': 'Calciolandia',

    'navbar.first': 'Pagina iniziale',
    'navbar.second': 'Prenota',
    'navbar.third': 'Info',
    'navbar.fourth': 'Gioca con noi!',

    'restricted-access.title': 'Area Admin',
    'restricted-access.description': "Hai bisogno di un'autorizzazione per vedere questo contenuto.Se non ne hai una, torna alla homepage.",
    'restricted-access.password-text': 'Inserisci la tua password per continuare.',
    'restricted-access.button-text': 'Accedi',

    'page-not-found.title-text': 'Pagina non trovata',

    'reservation-modal-title': 'Effettua prenotazione',
    'reservation-modal-button-padel': 'Padel',
    'reservation-modal-button-calciotto': 'Calciotto',
    'reservation-modal-button-calcetto': 'Calcetto',
    'reservation-modal-input-name': 'Nome e cognome',
    'reservation-modal-input-phone': 'Numero di telefono',
    'reservation-modal-input-email': 'Indirizzo email',
    'reservation-modal-button-cancel': 'Annulla',
    'reservation-modal-button-confirm': 'Conferma',

    'errors-reservation-modal-reservationName': 'Il campo deve contenere nome e cognome',
    'errors-reservation-modal-reservationPhone': 'Il campo deve essere di 9-10 numeri',
    'errors-reservation-modal-reservationEmail': 'Il campo deve contenere una mail valida',
    'errors-reservation-modal-reservationDay': 'Il campo data non è selezionato',
    'errors-reservation-modal-selectHourFrom': 'Il campo orario dalle ore non è selezionato',
    'errors-reservation-modal-selectHourTo': 'Il campo orario alle ore non è selezionato',
    'errors-reservation-modal-reservation-error': 'Prenotazione non effettuata',
    'errors-reservation-modal-reservation-success': 'Prenotazione effettuata con successo',
    'errors-reservation-modal-connection-error': 'Errore di connessione',
    'errors-reservation-modal-impossible-to-retrieve': 'Impossibile recuperare le prenotazioni',

    'join-us-modal-title': 'Gioca con noi!',
    'join-us-modal-button-padel': 'Padel',
    'join-us-modal-button-calciotto': 'Calciotto',
    'join-us-modal-button-calcetto': 'Calcetto',
    'join-us-modal-input-name': 'Nome',
    'join-us-modal-input-second-name': 'Cognome',
    'join-us-modal-input-phone': 'Numero di telefono',
    'join-us-modal-input-email': 'Indirizzo email',
    'join-us-modal-input-whatsapp': 'Possiamo contattarti su whatsapp?',
    'join-us-modal-input-notes': 'Informazioni utili',
    'join-us-modal-button-cancel': 'Annulla',
    'join-us-modal-button-confirm': 'Conferma',

    'errors-join-us-modal-joinUsName': 'Il campo deve contenere il nome',
    'errors-join-us-modal-joinUsSecondName': 'Il campo deve contenere il cognome',
    'errors-join-us-modal-joinUsNumber': 'Il campo deve essere di 9-10 numeri',
    'errors-join-us-modal-joinUsEmail': 'Il campo deve contenere una mail valida',
    'errors-join-us-modal-joinUsNotes': 'Il campo è vuoto',
    'errors-join-us-modal-reservation-error': 'Azione non effettuata',
    'errors-join-us-modal-reservation-success': 'Azione effettuata con successo',
    'errors-join-us-modal-connection-error': 'Errore di connessione',

    'contact-us-title': 'Informazioni utili',
    'contact-us-first-label': 'Chi siamo',
    'contact-us-first-text':
        "Da Calciolandia è possibile prenotare i campi per il calcetto ed il calciotto tutto l'anno. Sono disponibili spogliatoi separati per giocatori e per arbitri, tutti dotati di docce e riscaldamento ed è  possibile organizzare tornei, anche serali. Calciolandia è a Gavignano in provincia di Roma, a soli 4 km da Colleferro",
    'contact-us-second-label': 'Telefono',
    'contact-us-second-text': '3357788600',
    'contact-us-third-label': 'Email',
    'contact-us-third-text': 'info@calciolandia.eu',
    'contact-us-fourth-label': 'Indirizzo',
    'contact-us-fourth-text': 'Via Consolare Latina Km. 4,600 Gavignano (RM)',

    'eliminazione-diretta-data-e-ora': 'Data e ora : ',
    'eliminazione-diretta-buttons-ottavi': 'Ottavi',
    'eliminazione-diretta-buttons-quarti': 'Quarti',
    'eliminazione-diretta-buttons-semi': 'Semi',
    'eliminazione-diretta-buttons-finale': 'Finale',

    'competition-tab-names-players-ranking': 'Classifica giocatori',
    'competition-tab-names-players-fairplay': 'Classifica Fairplay',
    'competition-tab-names-teams-ranking': 'Classifica squadre',

    'classifica-component-table-headings-team': 'Squadra',
    'classifica-component-table-headings-points': 'PT',
    'classifica-component-table-headings-played': 'G',
    'classifica-component-table-headings-won-lost-drawn': 'V-N-P',
    'classifica-component-table-headings-goals-diff': 'GF:GS',
    'classifica-component-table-headings-cards': 'CD',

    'giocatori-component-table-headings-player': 'Giocatore',
    'giocatori-component-table-headings-team': 'Squadra',
    'giocatori-component-table-headings-goal': 'Goal',
    'giocatori-component-table-headings-cards': 'Amm.',

    'loading-spinner-component-text': 'Caricamento ...',

    'giornate-component-button-previous': 'Precedente',
    'giornate-component-button-next': 'Successivo',
    'giornate-component-header-text': 'Giornata ',
    'giornate-component-badge-next' : 'Rinv.',

    'restricted-area-login-success': 'Login effettuata con successo',
    'restricted-area-login-error': 'Login non effettuata',

    'sponsor-component-pre-text': 'Sponsorizzato da',
    'sponsor-component-button': 'Chiudi',

    'admin-page-sponsor-edit-button': 'Modifica',
    'admin-page-sponsor-delete-button': 'Elimina',
    'admin-page-sponsor-modal-edit-title': 'Nome Sponsor',
    'admin-page-sponsor-modal-edit-image': 'Immagine',
    'admin-page-sponsor-modal-edit-bodyText': 'Descrizione',
    'admin-page-sponsor-modal-edit-phoneNumber': 'Numero di telefono',
    'admin-page-sponsor-modal-edit-website': 'Sito web',
    'admin-page-sponsor-modal-edit-address': 'Indirizzo',

    'cards-grid-card-body-description': 'Descrizione',
    'cards-grid-card-body-phoneNumber': 'Numero di telefono',
    'cards-grid-card-body-website': 'Sito Web',
    'cards-grid-card-body-address': 'Indirizzo',
    'cards-grid-card-body-modal-cancel-button': 'Annulla',
    'cards-grid-card-body-modal-accept-button': 'Applica',
    'cards-grid-card-body-modal-delete-button': 'Elimina',
    'cards-grid-card-modal-add-close-button': 'Annulla',
    'cards-grid-card-modal-add-add-button': 'Aggiungi',

    'sponsor-image-uploader-text': "Trascina e rilascia un'immagine qui oppure clicca per selezionarne una.",
    'sponsor-image-uploader-button-text': 'Carica immagine',
    'sponsor-image-uploader-error-no-image-selected': "Non hai selezionato un'immagine",
    'sponsor-image-uploader-error-failed': "Caricamento dell'immagine fallito",
    'sponsor-image-uploader-success-upload': 'Immagine caricata con successo',
    'sponsor-image-uploader-success-delete': 'Immagine cancellata con successo',
    'sponsor-image-uploader-error-delete': 'Cancella fallito',
    'sponsor-image-uploader-cancel-image-text' : 'Annulla caricamento immagine',
    'sponsor-image-uploader-delete-image-text' : 'Cancella immagine',

    'admin-page-competizioni-insert-match-day-scores-button-text': 'Inserisci tabellino giornata',
    'admin-page-competizioni-add-icon-text': 'Aggiungi Competizione',
    'admin-page-competizioni-edit-matchday-button-text': 'Modifica Giornata',
    'admin-page-competizioni-insert-matchday-button-text': 'Inserisci Giornata',
    'admin-page-competizioni-delete-competition-button-text': 'Elimina Competizione',
    'admin-page-competizioni-archive-competition-button-text': 'Archivia Competizione',
    'admin-page-competizioni-unarchive-competition-button-text': 'Dis-archivia Competizione',
    'admin-page-competizioni-direct-elimination': 'Eliminazione diretta',
    'admin-page-competizioni-archived-toggle-text': 'Visualizza competizioni archiviate',

    'modal-body-match-day-scores-first-select-text': 'Seleziona Giornata',
    'modal-body-match-day-scores-second-select-text': 'Seleziona Giocatore',
    'modal-body-match-day-scores-add-players-button-text': 'Aggiungi Nuovo Giocatore',


    'modal-body-create-competition-first-custom-divider-text': 'Nome Competizione',
    'modal-body-create-competition-competition-name-input-placeholder': 'Nome Competizione',
    'modal-body-create-competition-direct-elimination-checkbox': 'Eliminazione Diretta?',
    'modal-body-create-competition-second-custom-divider-text': 'Aggiungi Nuova squadra',
    'modal-body-create-competition-team-name-input-placeholder': 'Nome squadra',
};

export default labels;

export type LabelType = keyof typeof labels;
