import { gql } from '@apollo/client';

export const CreateMatch = gql`
    mutation CreateMatch($data: MatchInput!) {
        createMatch(data: $data) {
            data {
                id
            }
        }
    }
`;
export const UpdateMatch = gql`
    mutation UpdateMatch($id: ID!, $data: MatchInput!) {
        updateMatch(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;
