import t from '@/utils/LabelsUtil/labelingUtils';
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { customNotificationError, customNotificationSuccess } from '../CustomNotifications';
import styles from './SponsorImageUploader.module.css';
import { Button } from '@chakra-ui/button';
interface SponsorImageUploaderProps {
    callBackForImageData: (editSponsor: string) => void;
}

const SponsorImageUploader: React.FC<SponsorImageUploaderProps> = ({callBackForImageData}) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadStatus, setUploadStatus] = useState("");
    const [imageID , setImageID] = useState()
    const onDrop = useCallback((acceptedFiles: any[], rejectedFiles: any) => {
        acceptedFiles.forEach((file: any) => {
            //@ts-ignore
            setSelectedImages((prevState) => [...prevState, file]);
        });
    }, []);

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({ onDrop });

    const onUpload = async () => {
        try {
            if (!selectedImages) {
                customNotificationError(t('sponsor-image-uploader-error-no-image-selected'));
                return;
            }

            const formData = new FormData();
            formData.append('files', selectedImages[0]);
            formData.append('ref', 'post'); // Optional: Associate with a specific entry
            formData.append('field', 'image'); // Optional: Associate with a specific field

            fetch(`${import.meta.env.VITE_POST_API_ENDPOINT}/upload`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setImageID(data[0].id)
                    callBackForImageData(data[0].id)
                });


            setUploadStatus("success");
            customNotificationSuccess(t('sponsor-image-uploader-success-upload'));
        } catch (error) {
            customNotificationError(t('sponsor-image-uploader-error-failed'));
            setUploadStatus("failed");
        }
    };

    const onDelete = async () => {
        try{
            fetch(`${import.meta.env.VITE_POST_API_ENDPOINT}/upload/files/${imageID}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setSelectedImages([])
                    setUploadStatus("failed");
                    customNotificationSuccess(t('sponsor-image-uploader-success-delete'));
                });
                    
        } catch (error) {
            customNotificationError(t('sponsor-image-uploader-error-delete'));
            setUploadStatus("failed");
        }
    }

    const style = useMemo(
        () => ({
            ...(isDragAccept ? { borderColor: "#00e676" } : {}),
            ...(isDragReject ? { borderColor: "#ff1744" } : {})
        }),
        [isDragAccept, isDragReject]
    );

    return (
        <div className={styles.container}>
            {selectedImages.length === 0 && <div className={styles.dropzone} {...getRootProps({ style })}>
                <input {...getInputProps()} />
                 <p>{t('sponsor-image-uploader-text')}</p>
            </div>}
            <div className={styles.images}>
                {selectedImages.length > 0 &&
                    selectedImages.map((image, index) => (
                        <img src={`${URL.createObjectURL(image)}`} key={index} alt="" />
                    ))}
            </div>
            {selectedImages.length > 0 && (
                <>
                <div className={styles.btn}>
                <Button colorScheme='red'  type='button' onClick={() => (uploadStatus !== 'success') ? setSelectedImages([]) : onDelete()}>{(uploadStatus === 'success') ? t('sponsor-image-uploader-cancel-image-text') : t('sponsor-image-uploader-delete-image-text')}</Button>
                    {(uploadStatus !== 'success') && <Button colorScheme='green'  type='button'  onClick={onUpload}>{t('sponsor-image-uploader-button-text')}</Button>}
                </div> 
                </>
            )}
        </div>
    );
};

export default SponsorImageUploader;