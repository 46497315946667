import useClientStore, { DEFAULT_VALUE_SELECTEDCOMPETITION, ICompetition, IMatch, IPlayer, ITeam } from '@/utils/hooks/useClientStore';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, Divider, HStack, Input, Select, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import CustomButton from '../CustomButton';
import CustomDividerModal from '../CustomModalDivider';
import { customNotificationError } from '../CustomNotifications';
import StrapiClient from '@/utils/hooks/strapiClient';
import t from '@/utils/LabelsUtil/labelingUtils';

interface IState extends ICompetition {
    newPlayers: IPlayer[];
    isNameCompleted: boolean;
    isNewTeamsCompleted: boolean;
    isNewPlayersCompleted: boolean;
    isNewRoundCompleted: boolean;
    isMutationStarted: boolean;
    mutationCounter: number;
    mutationMaxCount: number;
}

const initialState: IState = {
    ...DEFAULT_VALUE_SELECTEDCOMPETITION,
    newPlayers: [],
    isNameCompleted: false,
    isNewTeamsCompleted: false,
    isNewPlayersCompleted: false,
    isNewRoundCompleted: false,
    isMutationStarted: false,
    mutationCounter: 0,
    mutationMaxCount: 0,
};

interface ITObject {
    [key: string]: unknown; // You can refine this type based on the actual structure of your objects
}

function findDuplicates<T extends ITObject>(arr: T[], property: keyof T): T[keyof T][] {
    const seen = new Set<T[keyof T]>();
    const duplicates: T[keyof T][] = [];

    arr.forEach((obj) => {
        const value = obj[property];
        if (seen.has(value)) {
            duplicates.push(value);
        } else {
            seen.add(value);
        }
    });

    return duplicates;
}

const newTeamPlayer = (teamName: string) => {
    return {
        name: '',
        surname: '',
        goals: 0,
        cards: 0,
        teamName: teamName,
        id: '0',
    };
};
const DEFAULT_TEAM = () => ({
    name: '',
    wins: 0,
    draws: 0,
    loss: 0,
    playedMatches: 0,
    points: 0,
    goalsDone: 0,
    goalsReceived: 0,
    cards: 0,
    hostedMatches: 0,
    guestMatches: 0,
});

const DEFAULT_MATCH = () => ({
    id: 0,
    date: '',
    hour: '',
    hostName: '',
    hostGoals: 0,
    hostCards: 0,
    guestName: '',
    guestGoals: 0,
    guestCards: 0,
})


const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'setField': {
            if (action.payload.field === 'isNameCompleted') {
                if (state?.rounds[0].eliminationRound) {
                    const eliminationRounds = state.rounds[0]?.eliminationRound === 'ottavi' ? 16 : state.rounds[0]?.eliminationRound === 'quarti' ? 8 : state.rounds[0]?.eliminationRound === 'semi' ? 4 : null;
                    const teamsToPush = [];
                    if (eliminationRounds) {
                        for (let i = 0; i < eliminationRounds; i++) {
                            teamsToPush.push(DEFAULT_TEAM());
                        }
                        state.ranking = teamsToPush
                    }

                }
            }
            if (action.payload.field === 'directElimination') {
                state.rounds[0].eliminationRound = 'ottavi'
            }
            return { ...state, [action.payload.field]: action.payload.value };
        }
        case 'isNewPlayersCompleted': {
            state.isNewPlayersCompleted = true
            const currentRound = state.rounds[0]
            const eliminationRounds = state.rounds[0]?.eliminationRound === 'ottavi' ? 16 : state.rounds[0]?.eliminationRound === 'quarti' ? 8 : state.rounds[0]?.eliminationRound === 'semi' ? 4 : null;
            const teamsLenght = (eliminationRounds ? eliminationRounds : state?.ranking?.length) / 2;
            const matchesToPush = [];
            for (let i = 0; i < teamsLenght; i++) {
                matchesToPush.push(DEFAULT_MATCH());
            }
            currentRound.matches = matchesToPush
            return { ...state, round: currentRound };
        }
        case 'setRoundDirectElimination': {
            state.rounds[0].eliminationRound = action.payload?.value || null;
            return { ...state };
        }
        case 'addNewTeam':
            return { ...state, ranking: [...state.ranking, action.payload] };
        case 'currentTeamName':
            return { ...state, currentTeamName: action.payload };
        case 'addNewTeamChangePlayerTeam': {
            const newPlayersArr = state?.ranking?.map((x: any) => newTeamPlayer(x.name));
            return { ...state, newPlayers: newPlayersArr };
        }
        case 'setDirectElRound': {
            const updatedRound = state.rounds[0];
            updatedRound.eliminationRound = action.payload.value;
            return { ...state, rounds: [updatedRound] };
        }
        case 'setNewTeamProperties': {
            const updatedNewTeam = state.ranking;
            updatedNewTeam[action.payload.index][action.payload.field] = action.payload.value;
            state.ranking = updatedNewTeam;
            return { ...state };
        }
        case 'setRoundProperties': {
            const updatedRound = state.rounds[0];
            updatedRound.matches[action.payload.index][action.payload.field] = action.payload.value;

            return { ...state, rounds: [updatedRound] };
        }
        case 'addNewPlayer':
            return {
                ...state,
                newPlayers: [...state.newPlayers, action.payload],
            };
        case 'setNewPlayerProperties': {
            const updatedNewPlayers = state.newPlayers;
            updatedNewPlayers[action.payload.index][action.payload.field] = action.payload.value;
            return { ...state, newPlayers: updatedNewPlayers };
        }
        case 'resetState':
            return initialState;
        default:
            return state;
    }
};


const DEFAULT_DIRECT_ELIMINATION_STARTING_ROUNDS = ['ottavi', 'quarti', 'semi'];

const ModalBodyCreateCompetition = React.forwardRef((props, ref) => {
    const { clientStoreActions } = useClientStore();
    const [state, dispatch] = useReducer(reducer, initialState);
    const handleAddTeamButtonClick = (teamName: string) => {
        dispatch({ type: 'addNewTeam', payload: DEFAULT_TEAM() });
        dispatch({ type: 'addNewPlayer', payload: newTeamPlayer(teamName) });
    };
    useEffect(() => {
        if (state.isNameCompleted && state.isNewTeamsCompleted && state.isNewPlayersCompleted && state.isNewRoundCompleted) {
            clientStoreActions.setAdminCompetitionModalCreateCompetitionAdvanceButtonDisabled(false);
        }
    }, [state.isNameCompleted, state.isNewTeamsCompleted, state.isNewPlayersCompleted, state.isNewRoundCompleted]);

    useEffect(() => {
        return () => {
            dispatch({ type: 'resetState' });
        }
    }, [])


    const handleAddPlayerButtonClick = (playerTeamName: string) => {
        dispatch({
            type: 'addNewPlayer',
            payload: newTeamPlayer(playerTeamName),
        });
    };

    const handleFormSubmit = () => {
        clientStoreActions.setAdminCompetitionModalCreateCompetitionAdvanceButtonDisabled(true);
        dispatch({
            type: 'setField',
            payload: { field: 'isMutationStarted', value: true },
        });
        const clientNewPlayersData = state.newPlayers.map((x: IPlayer) => {
            const TeamIndex = state.ranking.findIndex((obj: ITeam) => obj.name === x.teamName);
            return {
                Name: x.name,
                Surname: x.surname,
                TeamIndex,
            }
        })
        const clientNewTeamsData = state.ranking.map((x: any) => {
            return {
                Name: x.name
            }
        })
        const clientNewRound = {
            eliminationRound: state.rounds[0]?.eliminationRound === 'ottavi' ? 'ottavi' : state.rounds[0]?.eliminationRound === 'quarti' ? 'quarti' : state.rounds[0]?.eliminationRound === 'semi' ? 'semi' : null,
            matches: state.rounds[0]?.matches?.map((match: IMatch) => {
                return {
                    ...match,
                    hostIndex: state.ranking.findIndex((obj: ITeam) => obj.name === match.hostName),
                    guestIndex: state.ranking.findIndex((obj: ITeam) => obj.name === match.guestName),
                }
            })
        }
        dispatch({
            type: 'setField',
            payload: {
                field: 'mutationMaxCount',
                value: parseInt(1 + clientNewTeamsData.lenght + 1 + clientNewPlayersData.lenght + 1 + state?.ranking?.lenght / 2),
            },
        });
        client.CreateCompetition(
            { Name: state.name, DirectElimination: state?.directElimination || null, IsArchived: false },
            clientNewTeamsData,
            clientNewPlayersData,
            clientNewRound,
        )
    };

    React.useImperativeHandle(ref, () => ({
        handleFormSubmit,
    }));

    const handleNextAndValidation = () => {
        const { isNewRoundCompleted, isNewPlayersCompleted, isNewTeamsCompleted, isNameCompleted, rounds, ranking, name, newPlayers } = state;

        const isTeamNameInvalid = () => ranking?.some((obj: ITeam) => obj.name === '');
        const hasDuplicateTeamNames = () => findDuplicates(ranking, 'name').length > 0;
        const isPlayerNameValid = () => newPlayers?.some((obj: IPlayer) => obj.name === '');
        const isPlayerSurnameValid = () => newPlayers?.some((obj: IPlayer) => obj.surname === '');
        const isRoundDateInvalid = () => rounds[0]?.matches?.some((obj: IMatch) => obj.date === '');
        const isRoundHourInvalid = () => rounds[0]?.matches?.some((obj: IMatch) => obj.hour === '');
        const isRoundHostNameInvalid = () => rounds[0]?.matches?.some((obj: IMatch) => obj.hostName === undefined);
        const isRoundGuestNameInvalid = () => rounds[0]?.matches?.some((obj: IMatch) => obj.guestName === undefined);
        //TODO FIX A BUG WHERE PLAYER NAME AND SURNAME ARE DETECTED INCORRECTLY IN SOME SETTINGS
        const showErrorNotification = (message: string) => {
            customNotificationError(message);
        };
        if (isNameCompleted) {
            if (isNewTeamsCompleted) {
                if (isNewPlayersCompleted) {
                    if (!isNewRoundCompleted) {
                        if (isRoundDateInvalid()) {
                            showErrorNotification('Il campo data non può essere vuoto in nessuna partita');
                        } else if (isRoundHourInvalid()) {
                            showErrorNotification('Il campo ora non può essere vuoto in nessuna partita');
                        } else if (isRoundHostNameInvalid()) {
                            showErrorNotification('Il campo squadra casa non può essere vuoto in nessuna partita');
                        } else if (isRoundGuestNameInvalid()) {
                            showErrorNotification('Il campo squadra ospite non può essere vuoto in nessuna partita');
                        } else
                            dispatch({
                                type: 'setField',
                                payload: {
                                    field: 'isNewRoundCompleted',
                                    value: true,
                                },
                            });
                    }
                } else if (isPlayerNameValid() && isPlayerSurnameValid()) {
                    showErrorNotification('Sono necessari i nomi e cognomi di tutti i gioatori');
                } else dispatch({ type: 'isNewPlayersCompleted', payload: true });
            } else if (isTeamNameInvalid()) {
                showErrorNotification('Sono necessari i nomi di tutte le squadre');
            } else if (!isTeamNameInvalid() && hasDuplicateTeamNames()) {
                showErrorNotification('Le squadre non possono avere lo stesso nome');
            } else {
                dispatch({
                    type: 'setField',
                    payload: { field: 'isNewTeamsCompleted', value: true },
                });
                dispatch({ type: 'addNewTeamChangePlayerTeam' });
            }
        } else if (!name) {
            showErrorNotification('Il campo nome competizione è richiesto');
        } else {
            dispatch({
                type: 'setField',
                payload: { field: 'isNameCompleted', value: true },
            });
        }
    };
    const client = StrapiClient();
    return (
        <form>
            <VStack w={'100%'}>
                {!state?.isMutationStarted && (
                    <>
                        <CustomDividerModal text={t('modal-body-create-competition-first-custom-divider-text')} isConfirmed={state.isNameCompleted} />
                        {!state.isNameCompleted && (
                            <>
                                <Input
                                    variant={'flushed'}
                                    htmlSize={2}
                                    width="100%"
                                    size={'xl'}
                                    placeholder={t('modal-body-create-competition-competition-name-input-placeholder')}
                                    onChange={(e) => {
                                        dispatch({
                                            type: 'setField',
                                            payload: {
                                                field: 'name',
                                                value: e.target.value,
                                            },
                                        });
                                    }}
                                />
                                <HStack w={'100%'}>
                                    <Checkbox
                                        w={'40%'}
                                        onChange={(e) => {
                                            dispatch({
                                                type: 'setField',
                                                payload: {
                                                    field: 'directElimination',
                                                    value: e.target.checked,
                                                },
                                            });
                                        }}
                                        colorScheme="green"
                                    >
                                        {t('modal-body-create-competition-direct-elimination-checkbox')}
                                    </Checkbox>
                                    {state?.directElimination && (
                                        <Select onChange={(e) => { dispatch({ type: 'setDirectElRound', payload: { value: e.target.value } }) }}>
                                            {DEFAULT_DIRECT_ELIMINATION_STARTING_ROUNDS.map((x) => {
                                                return (
                                                    <option value={x} key={x}>
                                                        {x}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    )}
                                </HStack>
                                <Button colorScheme="whiteAlpha" onClick={() => handleNextAndValidation()}>
                                    Continua
                                </Button>
                            </>
                        )}
                        {state?.isNameCompleted && <CustomDividerModal text="Aggiungi squadre" isConfirmed={state?.isNewTeamsCompleted} />}
                        {state?.name?.length > 0 && (
                            <>
                                {state?.isNameCompleted && !state?.isNewTeamsCompleted && (
                                    <>
                                        <CustomButton text={t('modal-body-create-competition-second-custom-divider-text')} onClick={() => handleAddTeamButtonClick(state.currentTeamName)} />
                                        {state?.ranking?.map((x: any, i: any) => {
                                            return (
                                                <Input
                                                    variant={'flushed'}
                                                    htmlSize={2}
                                                    width="100%"
                                                    key={i}
                                                    size={'xl'}
                                                    value={state?.ranking[i].name}
                                                    placeholder={`${t('modal-body-create-competition-team-name-input-placeholder')} ${i + 1}`}
                                                    onChange={(e) => {
                                                        dispatch({
                                                            type: 'setNewTeamProperties',
                                                            payload: {
                                                                field: 'name',
                                                                index: i,
                                                                value: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                />
                                            );
                                        })}

                                        <Button colorScheme="whiteAlpha" onClick={() => handleNextAndValidation()}>
                                            Continua
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                        {state?.isNewTeamsCompleted && <CustomDividerModal text="Aggiungi Giocatori" isConfirmed={state?.isNewPlayersCompleted} />}
                        {state?.isNewTeamsCompleted && !state?.isNewPlayersCompleted && state?.ranking?.length > 1 && (
                            <>
                                <Accordion allowToggle w={'100%'}>
                                    {state.ranking.map((x: any, i: any) => {
                                        return (
                                            <AccordionItem>
                                                <AccordionButton
                                                    onClick={() =>
                                                        dispatch({
                                                            type: 'currentTeamName',
                                                            payload: x.name,
                                                        })
                                                    }
                                                >
                                                    <Box as="span" flex="1" textAlign="left">
                                                        {`${x.name}`}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    {state?.newPlayers?.map((newPlayer: any, newPlayerIndex: any) => {
                                                        if (newPlayer.teamName === x.name) {
                                                            return (
                                                                <>
                                                                    <HStack>
                                                                        <Input
                                                                            variant={'flushed'}
                                                                            htmlSize={2}
                                                                            width="100%"
                                                                            key={i}
                                                                            size={'xl'}
                                                                            value={newPlayer.surnname}
                                                                            placeholder={'Cognome'}
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'setNewPlayerProperties',
                                                                                    payload: {
                                                                                        field: 'surname',
                                                                                        index: newPlayerIndex,
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />
                                                                        <Input
                                                                            variant={'flushed'}
                                                                            htmlSize={2}
                                                                            width="100%"
                                                                            key={'i'}
                                                                            size={'xl'}
                                                                            value={newPlayer.name}
                                                                            placeholder={'Nome'}
                                                                            onChange={(e) => {
                                                                                dispatch({
                                                                                    type: 'setNewPlayerProperties',
                                                                                    payload: {
                                                                                        field: 'name',
                                                                                        index: newPlayerIndex,
                                                                                        value: e.target.value,
                                                                                    },
                                                                                });
                                                                                dispatch({
                                                                                    type: 'setNewPlayerProperties',
                                                                                    payload: {
                                                                                        field: 'teamIndex',
                                                                                        index: newPlayerIndex,
                                                                                        value: i,
                                                                                    },
                                                                                });
                                                                            }}
                                                                        />

                                                                    </HStack>
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                    <CustomButton text={'Aggiungi nuovo giocatore'} onClick={() => handleAddPlayerButtonClick(state.currentTeamName)} />
                                                </AccordionPanel>
                                            </AccordionItem>
                                        );
                                    })}
                                </Accordion>
                                <Button colorScheme="whiteAlpha" onClick={() => handleNextAndValidation()}>
                                    Continua
                                </Button>
                            </>
                        )}
                        {state?.isNewPlayersCompleted && <CustomDividerModal text="Genera prima giornata" isConfirmed={state?.isNewRoundCompleted} />}
                        {state?.isNewPlayersCompleted && !state?.isNewRoundCompleted && state?.rounds[0] && (
                            <>
                                <Accordion w={'100%'}>
                                    {state?.rounds[0].matches?.map((x: any, i: any) => {
                                        return (
                                            <AccordionItem>
                                                <AccordionButton>
                                                    <Text w={'100%'}>
                                                        {(state?.rounds[0]?.matches[i]?.hostName || 'Squadra Casa') + ' - ' + (state?.rounds[0]?.matches[i]?.guestName || 'Squadra Ospite')}
                                                    </Text>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <HStack pb={'0.7rem'}>
                                                        <Input
                                                            value={x.date}
                                                            type="date"
                                                            onChange={(e) => {
                                                                dispatch({
                                                                    type: 'setRoundProperties',
                                                                    payload: {
                                                                        index: i,
                                                                        field: 'date',
                                                                        value: moment(e.target.value).format('YYYY-MM-DD'),
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        <Input
                                                            value={x.hour}
                                                            type="time"
                                                            onChange={(e) =>
                                                                dispatch({
                                                                    type: 'setRoundProperties',
                                                                    payload: {
                                                                        index: i,
                                                                        field: 'hour',
                                                                        value: e.target.value + ':00.000',
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </HStack>
                                                    <HStack>
                                                        <Select
                                                            value={x.hostName}
                                                            onChange={(e) => {
                                                                dispatch({
                                                                    type: 'setRoundProperties',
                                                                    payload: {
                                                                        index: i,
                                                                        field: 'hostName',
                                                                        value: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <option>Seleziona team</option>
                                                            {state?.ranking?.map((x: any, index: number) => {
                                                                return <option value={x.name}>{x.name}</option>;
                                                            })}
                                                        </Select>
                                                        <Select
                                                            value={x.guestName}
                                                            onChange={(e) => {
                                                                dispatch({
                                                                    type: 'setRoundProperties',
                                                                    payload: {
                                                                        index: i,
                                                                        field: 'guestName',
                                                                        value: e.target.value,
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            <option>Seleziona team</option>
                                                            {state?.ranking.map((x: any, index: number) => {
                                                                return <option value={x.name}>{x.name}</option>;
                                                            })}
                                                        </Select>
                                                    </HStack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        );
                                    })}
                                </Accordion>
                                <Button colorScheme="whiteAlpha" onClick={() => handleNextAndValidation()}>
                                    Salva
                                </Button>
                            </>
                        )}
                        {state?.isNameCompleted && state?.isNewTeamsCompleted && state?.isNewPlayersCompleted && state?.isNewRoundCompleted && (
                            <VStack>
                                <Box marginTop={'8px'} w={'100%'}>
                                    {
                                        state?.rounds[0]?.matches?.map((match: IMatch) => {
                                            const allPlayers = [...state?.newPlayers]
                                            const homePlayers = allPlayers?.filter((x: IPlayer) => x?.teamName === match.hostName)
                                            const guestPlayers = allPlayers?.filter((x: IPlayer) => x?.teamName === match.guestName)
                                            return (
                                                    <VStack marginTop={'8px'} textAlign={'center'} w={'100%'}>
                                                        <HStack w={'100%'}>
                                                        <Text>{`${match.hostName} - ${match.guestName}`}</Text>
                                                        </HStack>
                                                        <HStack w={'100%'}>
                                                        <Text>{`${match.date} | ${moment(match.hour, 'HH:mm:ss.SSS').format('HH:mm')}`}</Text>
                                                        </HStack>

                                                        // 
                                                        <HStack w={'100%'}>
                                                            <Box w={'100%'}>
                                                                {
                                                                    homePlayers?.map((x: IPlayer, i: number) => {
                                                                        return (
                                                                            <Box w={'100%'} textAlign={'left'}>
                                                                                {`| ${x?.surname} ${x?.name?.charAt(0).toUpperCase() || x?.name}.`}
                                                                            </Box>
                                                                        )

                                                                    })
                                                                }
                                                            </Box>
                                                            <Box w={'100%'}>
                                                                {
                                                                    guestPlayers?.map((x: IPlayer, i: number) => {
                                                                        return (
                                                                            <Box w={'100%'} textAlign={'right'}>
                                                                                {`${x?.surname} ${x?.name?.charAt(0).toUpperCase() || x?.name}. |`}
                                                                            </Box>
                                                                        )

                                                                    })
                                                                }
                                                            </Box>
                                                        </HStack>
                                                    
                                                    <Divider />
                                                    </VStack>
                                            )

                                        })
                                    }
                                </Box>
                            </VStack>
                        )}
                    </>
                )}
            </VStack>
        </form>
    );
});

export default ModalBodyCreateCompetition;
