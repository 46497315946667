import { gql } from '@apollo/client';

export const InsertRanking = gql`
    mutation CreateRanking($data: RankingInput!) {
        createRanking(data: $data) {
            data {
                id
            }
        }
    }
`;
