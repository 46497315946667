import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import CustomButton, { ICustomButtonProps } from '../CustomButton';
import './CustomizableModal.css';

interface ICustomizableModalProps {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	title: string;
	buttonClose: ICustomButtonProps;
	buttonAdvance: ICustomButtonProps;
	body?: JSX.Element | string;
	loading?: boolean;
}

const CustomizableModal = ({
	isOpen,
	onClose,
	title,
	body,
	buttonClose,
	buttonAdvance,
	loading,
}: ICustomizableModalProps) => {
	document?.addEventListener('close-custom-modal', () => onClose());
	return (
		<>
			<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={buttonClose.onClick}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{title}</ModalHeader>
					<ModalCloseButton />
					{body && <ModalBody>{body}</ModalBody>}

					<ModalFooter>
						<div className="CustomizableModal-modal-footer-container">
							<CustomButton
								isDisabled={buttonAdvance.isDisabled}
								text={buttonAdvance.text}
								onClick={() => {
									buttonAdvance.onClick();
								}}
							/>
							<CustomButton
								isRed
								text={buttonClose.text}
								onClick={() => {
									buttonClose.onClick();
									onClose();
								}}
							/>
						</div>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
export default CustomizableModal;
