import './Navbar.css';
import CALCIOLANDIA_LOGO_PLAIN from '@assets/CALCIOLANDIA_LOGO_PLAIN.webp';
import t from '@utils/LabelsUtil/labelingUtils';
import ReservationModal from '../ReservationModal';
import JoinUsModal from '../JoinUsModal';
const NavBar = () => {
    return (
        <header className="navBar-header">
            <a href="/">
                <img src={CALCIOLANDIA_LOGO_PLAIN} alt="logo" className="logo" />
            </a>

            <input type="checkbox" id="check" />
            <label htmlFor="check" className="icons">
                <i className="bx bx-menu" id="menu-icon"></i>
                <i className="bx bx-x" id="close-icon"></i>
            </label>

            <nav className="navbar">
                <a href="/" className="nav-item">
                    {t('navbar.first')}
                </a>
                <ReservationModal />
                <a href="/contattaci" className="nav-item">
                    {t('navbar.third')}
                </a>
                <JoinUsModal />
            </nav>
        </header>
    );
};
export default NavBar;
