import { gql } from '@apollo/client';

export const CreateMatchDayScoresMutation = gql`
    mutation CreateMatchDayScoresMutation($data: MatchDayScoresInput!) {
        createMatchDayScores(data: $data) {
            data {
                id
            }
        }
    }
`;
export const UpdateMatchDayScoresMutation = gql`
    mutation UpdateMatchDayScores($id: ID!, $data: MatchDayScoresInput!) {
        updateMatchDayScores(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;

export const GetAllMatchDayScores = gql`
query GetAllMatchDayScores {
    matchDaysScores(
        pagination: {limit:100000}
    ) {
        data {
            id
           attributes {
                RoundID
                CompetitionID
                PlayersActions
                Matches
           }
        }
    }
}
`;
