import './GiocatoriComponent.css';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { IPlayer } from '@/utils/hooks/useClientStore';
import t from '@/utils/LabelsUtil/labelingUtils';

const GiocatoriComponent = ({ giocatori, isFPOnly = false }: { giocatori: IPlayer[]; isFPOnly?: boolean }) => {
    const sortedPlayers = isFPOnly ? giocatori?.sort((a, b) => a.cards - b.cards) : giocatori?.sort((a, b) => b.goals - a.goals);
    return (
        <>
            <div className="GiocatoriComponent-container">
                <TableContainer>
                    <Table colorScheme="whiteAlpha" variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Pos.</Th>
                                <Th>{t('giocatori-component-table-headings-player')}</Th>
                                <Th>{t('giocatori-component-table-headings-team')}</Th>
                                {!isFPOnly && <Th>{t('giocatori-component-table-headings-goal')}</Th>}
                                {isFPOnly && <Th>{t('giocatori-component-table-headings-cards')}</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {giocatori &&
                                sortedPlayers?.map((x: IPlayer , i : number) => {
                                    return (
                                        <Tr key={x.surname + x.name + i}>
                                            <Td>{i + 1}</Td>
                                            <Td>{x.surname + ' ' + x.name.charAt(0) + '.'}</Td>
                                            <Td>{x.teamName}</Td>
                                            {!isFPOnly && <Td>{x.goals ? x.goals : 0}</Td>}
                                            {isFPOnly && <Td>{x.cards ? x.cards : 0}</Td>}
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default GiocatoriComponent;
