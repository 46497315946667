import './LoadingSpinnerComponent.css';
import t from '@/utils/LabelsUtil/labelingUtils';

const LoadingSpinner = () => {
    return (
        <div className="LoadingSpinner-container">
            <div className="spinner-box">
                <div className="blue-orbit leo"></div>

                <div className="green-orbit leo"></div>

                <div className="red-orbit leo"></div>

                <div className="white-orbit w1 leo"></div>
                <div className="white-orbit w2 leo"></div>
                <div className="white-orbit w3 leo"></div>
            </div>
            <span className="LoadingSpinner-text"> {t('loading-spinner-component-text')}</span>
        </div>
    );
};
export default LoadingSpinner;
