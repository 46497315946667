import { gql } from '@apollo/client';

export const InsertRound = gql`
	mutation CreateRound($data: RoundInput!) {
		createRound(data: $data) {
			data {
				id
			}
		}
	}
`;

export const GetMatchByRound = gql`
	query getMatchesByRound($id: ID) {
		matches(filters: { Round: { id: { eq: $id } } }) {
			data {
				id
				attributes {
					Hour
					Date
					GuestGoals
					GuestWarnings
					HostGoals
					HostWarnings
					HostTeam {
						data {
							id
							attributes {
								Name
							}
						}
					}
					GuestTeam {
						data {
							id
							attributes {
								Name
							}
						}
					}
				}
			}
		}
	}
`;
