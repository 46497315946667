import { EditIcon, CalendarIcon, ViewIcon } from '@chakra-ui/icons';
import '@styles/AdminPage.css';
import { useNavigate } from 'react-router-dom';
import bg_image from '@assets/bg_image.webp';
import { useEffect, useState } from 'react';
import { API_URL } from '@/utils/hooks/useReservationStore';
import { NoMetadata } from '@/utils/hooks/useMetadata';

const AdminPage = () => {
    const [notificationNumber, setNotificationNumber] = useState(0);

    const navigate = useNavigate();
    const handlePrenotazioniClick = () => {
        navigate('/admin/gestione/prenotazioni');
    };
    const handleCompetizioniClick = () => {
        navigate('/admin/gestione/competizioni');
    };
    const handleSponsorClick = () => {
        navigate('/admin/gestione/sponsor');
    };
    useEffect(() => {
        fetch(API_URL + 'getAll', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (data) {
                    const notificationNumber = data?.map((x: any) => x.events
                        ?.reduce((acc: any, curr: any) => curr?.state === 'Pending' ? acc + 1 : acc, 0) || 0)
                        ?.reduce((acc: any, curr: any) => acc + curr, 0)
                    setNotificationNumber(notificationNumber)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [])

    return (
        <>
            <img src={bg_image} className="background-video" />
            <NoMetadata/>
            <div className="AdminPage-container">
                <button onClick={handlePrenotazioniClick} className="AdminPage-prenotazioni-button">
                    <CalendarIcon /> Prenotazioni
                    {(notificationNumber > 0) &&<div className="AdminPage-notification-badge">{notificationNumber}</div>}
                </button>
                <button onClick={handleCompetizioniClick} className="AdminPage-competizioni-button">
                    <EditIcon /> Competizioni
                </button>
                <button onClick={handleSponsorClick} className="AdminPage-sponsor-button">
                    <ViewIcon /> Sponsor
                </button>
            </div>
        </>
    );
};
export default AdminPage;
