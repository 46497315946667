import t from '@utils/LabelsUtil/labelingUtils';
import { useEffect, useState } from 'react';
import '@styles/RestrictedArea.css';
import { useMutation } from '@apollo/client';
import { Auth } from '@/utils/Queries/Auth';
import { customNotificationError, customNotificationSuccess } from '@/components/CustomNotifications';
import { useNavigate } from 'react-router-dom';
import { NoMetadata } from '@/utils/hooks/useMetadata';

const RestrictedArea = () => {
    const isAlreadyLogged = localStorage.getItem('token');
    useEffect(() => {
        if (isAlreadyLogged) navigate('/admin/gestione');
        const $cont_bgc_modal: HTMLElement = document.querySelector('.cont_bgc_modal')!;
        const $cont_modal: HTMLElement = document.querySelector('.cont_modal')!;
        $cont_bgc_modal.style.display = 'block';
        $cont_modal.style.display = 'block';
        setTimeout(function () {
            $cont_bgc_modal.className = 'cont_bgc_modal  .cont_bgc_modal_active';
            $cont_modal.className = 'cont_modal cont_modal_active';
        }, 100);
    }, []);

    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [loginAuth] = useMutation(Auth, {
        onCompleted(data) {
            if (data?.login?.jwt) {
                localStorage.setItem('token', data.login.jwt);
                customNotificationSuccess(t('restricted-area-login-success'));
                navigate('/admin/gestione');
            } else customNotificationError(t('restricted-area-login-success'));
        },
    });
    return (
        <div className="cont_main">
            <NoMetadata/>
            <div className="cont_bgc_modal"></div>
            <div className="cont_modal">
                <div className="cont_circle_denied">
                    <div className="cont_form_modal">
                        <div className="po_relative">
                            <h1>{t('restricted-access.title')}</h1>
                            <p>{t('restricted-access.description')}</p>
                        </div>
                        <div className="cont_input_password">
                            <p>{t('restricted-access.password-text')}</p>
                            <input onChange={(e) => setUserEmail(e.target.value)} type="email" className="input_password_" placeholder="mail@domain.com" />
                        </div>
                        <div className="cont_input_password">
                            <p>{t('restricted-access.password-text')}</p>
                            <input onChange={(e) => setUserPassword(e.target.value)} type="password" className="input_password_" placeholder="********" />
                        </div>
                        <div className="cont_btn">
                            <button
                                onClick={() =>
                                    loginAuth({
                                        variables: {
                                            identifier: userEmail,
                                            password: userPassword,
                                        },
                                    })
                                }
                                className="btn_proceed"
                            >
                                {t('restricted-access.button-text')}
                                <span>&#10141;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RestrictedArea;
