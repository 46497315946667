import { gql } from '@apollo/client';

export const GetTeamsShort = gql`
    # Write your query or mutation here
    query GetTeamsShort {
        teams {
            data {
                id
                attributes {
                    Name
                }
            }
        }
    }
`;
export const CreateTeam = gql`
    mutation CreateNewTeam($data: TeamInput!) {
        createTeam(data: $data) {
            data {
                id
            }
        }
    }
`;
export const GetTeamRoundByCompetition = gql`
    query getTeamAndRoundFromComp($id: ID) {
        teams(filters: { Ranking: { Competition: { id: { eq: $id } } } }) {
            data {
                id
                attributes {
                    Name
                    Wins
                    Loss
                    Draws
                    GoalsDone
                    GoalsReceived
                    Tags
                    Points
                    PlayedMatches
                }
            }
        }
        rounds(filters: { Competition: { id: { eq: $id } } }) {
            data {
                id
                attributes {
                    Name
                    Matches {
                        data {
                            id
                            attributes {
                                Hour
                                Date
                                GuestGoals
                                GuestWarnings
                                HostGoals
                                HostWarnings
                                HostTeam {
                                    data {
                                        id
                                        attributes {
                                            Name
                                        }
                                    }
                                }
                                GuestTeam {
                                    data {
                                        id
                                        attributes {
                                            Name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GetTeamByCompetition = gql`
    query GetCompetitionTeam($id: ID) {
        teams(filters: { Ranking: { Competition: { id: { eq: $id } } } }) {
            data {
                id
                attributes {
                    Name
                    Players {
                        data {
                            id
                            attributes {
                                Name
                                Surname
                                Goals
                                Warnings
                                Assists
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const UpdateTeam = gql`
    mutation UpdateTeam($id: ID!, $data: TeamInput!) {
        updateTeam(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;
