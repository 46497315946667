import { gql } from '@apollo/client';

export const GetAllPlayers = gql`
    query GetAllPlayers {
        players(pagination: { limit: 1000 }) {
            data {
                id
                attributes {
                    Name
                    Surname
                    Goals
                    Assists
                    Warnings
                    Team {
                        data {
                            id
                            attributes {
                                Name
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const FindPlayerByID = gql`
    query GetPlayersByTeam($id: ID) {
        players(pagination: { limit: 9000 }, filters: { Team: { Ranking: { Competition: { id: { eq: $id } } } } }) {
            data {
                id
                attributes {
                    Name
                    Surname
                    Goals
                    Warnings
                    Team {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }

    # query GetPlayersByTeam($id: ID, $id2: ID) {
    #   players(
    #     pagination: { limit: 9999 }
    #     filters: {
    #       or: [{ Team: { id: { eq: $id } } }, { Team: { id: { eq: $id2 } } }]
    #     }
    #   ) {
    #     data {
    #       id
    #       attributes {
    #         Name
    #         Surname
    #         Goals
    #         Warnings
    #         Team {
    #           data {
    #             id
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
`;
export const CreatePlayer = gql`
    mutation CreatePlayer($data: PlayerInput!) {
        createPlayer(data: $data) {
            data {
                id
                attributes {
                    Name
                    Surname
                    Goals
                    Warnings
                    Team {
                        data {
                            id
                        }
                    }
                    
                }
            }
        }
    }
`;
export const DeletePlayer = gql`
    mutation DeletePlayer($id: ID!) {
        deletePlayer(id: $id) {
            data {
                id
            }
        }
    }
`;
export const EditPlayer = gql`
    mutation EditPlayer($id: ID!, $data: PlayerInput!) {
        updatePlayer(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;
