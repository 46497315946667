import { gql } from '@apollo/client';

export const GetSponsors = gql`
    query GetAllSponsors {
        sponsors {
            data {
                id
                attributes {
                    Name
                    Description
                    Website
                    Address
                    PhoneNumber
                    Image {
                        data {
                            id
                            attributes {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const DeleteSponsor = gql`
    mutation DeleteSponsor($id: ID!) {
        deleteSponsor(id: $id) {
            data {
                id
            }
        }
    }
`;
export const UpdateSponsor = gql`
    mutation UpdateSponsor($id: ID!, $data: SponsorInput!) {
        updateSponsor(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;
export const UPLOAD_IMAGE = gql`
mutation Upload($data: Upload!) {
	upload(file : $data) {
    data {
      id
    }
  }
	}

`;
export const DeleteImageUpload = gql`
    mutation UpdateSponsor($id: ID!) {
        deleteUploadFile(id: $id) {
            data {
                id
            }
        }
    }
`;
export const CREATE_SPONSOR = gql`
    mutation CreateSponsor($data: SponsorInput!) {
        createSponsor(data: $data) {
            data {
                id
                attributes {
                    Name
                    Description
                    PhoneNumber
                    Website
                    Address
                }
            }
        }
    }
`;
