import '../styles/PageNotFound.css';
import t from '@utils/LabelsUtil/labelingUtils';
import NavBar from '@/components/Navbar';
import { NoMetadata } from '@/utils/hooks/useMetadata';

const title = t('page-not-found.title-text');
const PageNotFound = () => (
    <>
        <NavBar />
        <NoMetadata/>
        <div className="page-not-found-container">
            <div className="page-not-found-title" title={title}>
                {title}
            </div>
        </div>
    </>
);
export default PageNotFound;
