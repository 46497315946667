/* eslint-disable no-empty-pattern */
import { DEFAULT_URL } from '@/app';
import EliminazioneDiretta from '@/components/EliminazioneDirettaComponent';
import LoadingSpinner from '@/components/LoadingSpinnerComponent';
import NavBar from '@/components/Navbar';
import t from '@/utils/LabelsUtil/labelingUtils';
import { GetAllCompetition } from '@/utils/Queries/Competition';
import { mapDirectEliminationRounds, mapResponseToClientStore } from '@/utils/hooks/complexityHandlers';
import useClientStore, { ICompetition } from '@/utils/hooks/useClientStore';
import { Metadata } from '@/utils/hooks/useMetadata';
import { useQuery } from '@apollo/client/react';
import { Select, Spinner, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import ClassificaComponent from '@components/ClassificaComponent';
import GiocatoriComponent from '@components/GiocatoriComponent';
import GiornateComponent from '@components/GiornateComponent';
import '@styles/CompetitionPage.css';
import { ChangeEvent, useState } from 'react';

const CompetitionPage = () => {
    const {
        competitionDataLoading,
        competitionList,
        selectedCompetition,
        selectedCompetitionRounds,
        selectedCompetitionPlayersRaking,
        selectedCompetitionRanking,
        selectedRoundIndex,
        clientStoreActions,
    } = useClientStore();
    

    const { loading , refetch } = useQuery(GetAllCompetition, {
        onCompleted(data) {
            if (data) {
                const response: ICompetition[] = mapResponseToClientStore(data);
                const updatedResponse = response?.filter((x) => x?.isArchived === showArchived)
                clientStoreActions.setCompetitionList(updatedResponse);
                clientStoreActions.setSelectedCompetition(updatedResponse[0]);
                clientStoreActions.setSelectedCompetitionRounds(updatedResponse[0]?.rounds);
                clientStoreActions.setSelectedCompetitionPlayersRanking(updatedResponse[0]?.playersRanking);
                clientStoreActions.setSelectedCompetitionRanking(updatedResponse[0].ranking);
                clientStoreActions.setCompetitionDataLoading(false);
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-first',
    });
    const [showArchived, setShowArchived] = useState(false)

    const handleSwitchChange = (value : boolean) => {
        setShowArchived(value)
        refetch()
    }

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        const currentCompetition = competitionList.find((x) => x.name === value);

        if (currentCompetition) {
            clientStoreActions.setSelectedCompetition(currentCompetition);
            clientStoreActions.setSelectedCompetitionRounds(currentCompetition.rounds);
            clientStoreActions.setSelectedCompetitionPlayersRanking(currentCompetition.playersRanking);
            clientStoreActions.setSelectedCompetitionRanking(currentCompetition.ranking);
        }
    };
    const areArchived = !!competitionList?.find((x) => x?.isArchived === true);
    return (
        <>
            <div className="competitionPage">
                <NavBar />
                <Metadata pageUrl={DEFAULT_URL + 'competizioni'} pageTitle={'Calciolandia | Competizioni'} />
                <video autoPlay loop muted playsInline className="background-video">
                    <source src={import.meta.env.VITE_VIDEO_BG_URL} type="video/mp4" />
                </video>
                {competitionDataLoading && (
                    <div className="competition-loading-spinner-container">
                        <LoadingSpinner />
                    </div>
                )}
                {!competitionDataLoading && (
                    <section className="competition-content">
                        <div className="competition-select-container">
                            <Select onChange={handleSelectChange}>
                                {competitionList &&
                                    competitionList.map((x) => {
                                        return (
                                            <option className="chakra-select__option" key={x.name} value={x.name}>
                                                {x.name}
                                            </option>
                                        );
                                    })}
                            </Select>
                            {areArchived && <div className="competition-switch-and-text-container">
                                {!loading && <span className='competition-switch-text'>{`Competizioni ${showArchived ? 'archiviate' : 'non archiviate'}`}</span>}
                                {loading && <span className='competition-switch-text'><Spinner size='sm' /></span>}
                                <Switch onChange={(e) => handleSwitchChange(e.target.checked)} />
                            </div>}
                        </div>
                        {!competitionDataLoading && !selectedCompetition.directElimination && (
                            <div className="competition-giornate-and-tables-container">
                                {selectedCompetition && <GiornateComponent giornate={selectedCompetitionRounds[selectedRoundIndex]} />}
                                <Tabs isFitted>
                                    <TabList mb="1em">
                                        <Tab>{t('competition-tab-names-teams-ranking')}</Tab>
                                        <Tab>{t('competition-tab-names-players-ranking')}</Tab>
                                        <Tab>{t('competition-tab-names-players-fairplay')}</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>{selectedCompetition && <ClassificaComponent classifica={selectedCompetitionRanking} />}</TabPanel>
                                        <TabPanel>{selectedCompetition && <GiocatoriComponent giocatori={selectedCompetitionPlayersRaking} />}</TabPanel>
                                        <TabPanel>{selectedCompetition && <GiocatoriComponent giocatori={selectedCompetitionPlayersRaking} isFPOnly={true} />}</TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </div>
                        )}
                        {!competitionDataLoading && selectedCompetition.directElimination && (
                            <div className="competition-direct-elimination-container">
                                <Tabs isFitted>
                                    <TabList mb="1.5em">
                                        <Tab>Tabellone</Tab>
                                        <Tab>Classifica giocatori</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            {selectedCompetition && selectedCompetition.directElimination && <EliminazioneDiretta round={mapDirectEliminationRounds(selectedCompetitionRounds)} />}
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="competition-direct-elimination-player-ranking-container">
                                                {selectedCompetition && <GiocatoriComponent giocatori={selectedCompetitionPlayersRaking} />}
                                            </div>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </div>
                        )}
                    </section>
                )}
            </div>
        </>
    );
};
export default CompetitionPage;
