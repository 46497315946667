import CardsGrid from '@/components/CardsGrid';
import { ICustomButtonProps } from '@/components/CustomButton';
import {
	customNotificationError,
	customNotificationSuccess,
} from '@/components/CustomNotifications';
import ModalBodyArchiveCompetition from '@/components/Gestione/ModalBodyArchiveCompetition';
import ModalBodyCreateCompetition from '@/components/Gestione/ModalBodyCreateCompetition';
import ModalBodyCreateMatchDay from '@/components/Gestione/ModalBodyCreateMatchDay';
import ModalBodyDeleteCompetition from '@/components/Gestione/ModalBodyDeleteCompetition';
import ModalBodyEditMatchDay from '@/components/Gestione/ModalBodyEditMatchDay';
import ModalBodyMatchDayScores from '@/components/Gestione/ModalBodyMatchDayScores';
import LoadingSpinner from '@/components/LoadingSpinnerComponent';
import t from '@/utils/LabelsUtil/labelingUtils';
import {
	DeleteCompetition,
	GetAllCompetition,
	UpdateCompetition,
} from '@/utils/Queries/Competition';
import { GetAllMatchDayScores } from '@/utils/Queries/MatchDayScores';
import {
	IMatchDayScores,
	mapMatchDayScoresResponseToState,
	mapResponseToClientStore,
} from '@/utils/hooks/complexityHandlers';
import useClientStore, { ICompetition } from '@/utils/hooks/useClientStore';
import { NoMetadata } from '@/utils/hooks/useMetadata';
import { ICardsGrid } from '@/utils/hooks/useSponsorStore';
import { useLazyQuery, useMutation } from '@apollo/client';
import bg_image from '@assets/bg_image.webp';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Switch, Text } from '@chakra-ui/react';
import '@styles/AdminPageCompetizioni.css';
import { forwardRef, useEffect, useRef, useState } from 'react';

const AdminPageCompetizioni = forwardRef<HTMLElement | null>(() => {
	const [competitionList, setCompetitionList] = useState<ICompetition[]>()
	const [competitionMatchDayScores, setCompetitionMatchDayScores] = useState<IMatchDayScores[]>()
	// TODO Update the fetches with promise handling
	const [getAllCompetitions] = useLazyQuery(GetAllCompetition, {
		fetchPolicy: 'network-only',
		onCompleted(data) {
			const response: ICompetition[] = mapResponseToClientStore(data);
			setCompetitionList(response);
			getAllMatchdayScores()
		},
		onError(data) {
			customNotificationError(data.message);
		},
	});
	const [getAllMatchdayScores] = useLazyQuery(GetAllMatchDayScores, {
		fetchPolicy: 'network-only',
		onCompleted(data) {
			clientStoreActions.setCompetitionDataLoading(false);
			const response = mapMatchDayScoresResponseToState(
				data?.matchDaysScores
			);
			setCompetitionMatchDayScores(response);
		},
		onError(data) {
			customNotificationError(data.message);
		},
	});
	
	const [showArchived, setShowArchived] = useState(false);

	const handleModalClose = () => {
		clientStoreActions.setCompetitionDataLoading(true);
		getAllCompetitions()
	};

	useEffect(() => {
		getAllCompetitions();
		document?.addEventListener('close-custom-modal', handleModalClose);
	}, []);


	const {
		clientStoreActions,
		competitionDataLoading,
		adminCompetitionModalCreateCompetitionAdvanceButtonDisabled,
		adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled,
		adminCompetitionModalEditMatchDayAdvanceButtonDisabled,
		adminCompetitionModalCreateMatchDayAdvanceButtonDisabled,
	} = useClientStore();

	// queries and mutations
	const [deleteCompetition] = useMutation(DeleteCompetition);
	const [archiveCompetition] = useMutation(UpdateCompetition);

	const asyncDeleteCompetition = async (id: number | string) => {
		try {
			const result = await deleteCompetition({ variables: { id } });
			if (result.data)
				customNotificationSuccess('operazione completata con successo');
			getAllCompetitions()
		} catch (error: unknown) {
			if (error && (error as { message?: string })?.message) {
				customNotificationError((error as { message: string }).message);
			}
		}
		clientStoreActions.setButtonLoading(false)
		document.dispatchEvent(new Event('close-custom-modal'))
	};
	const asyncArchiveCompetition = async (
		id: number | string,
		value: boolean
	) => {
		try {
			const result = await archiveCompetition({
				variables: { id, data: { IsArchived: value } },
			});
			if (result.data)
				customNotificationSuccess('operazione completata con successo');
			getAllCompetitions()
		} catch (error: unknown) {
			if (error && (error as { message?: string })?.message) {
				customNotificationError((error as { message: string }).message);
			}
		}
		clientStoreActions.setButtonLoading(false)
		document.dispatchEvent(new Event('close-custom-modal'))
		setShowArchived(false)
	};

	// Sets competitionList and everything else in the competition store
	// it's fired everytime a query or refetch is made on GetAllCompetition


	// This is needed to forward the function to trigger the form submit
	// just change the name in next references to have sense
	const insertMatchDayResultsForm = useRef<HTMLFormElement | null>();
	const submitMatchDayResultsFormForm = () => {
		if (insertMatchDayResultsForm.current) {
			insertMatchDayResultsForm.current.handleFormSubmit();
			getAllCompetitions()
		}
	};

	const createNewCompetitionForm = useRef<HTMLFormElement | null>();
	const submitCreateNewCompetitionForm = () => {
		if (createNewCompetitionForm.current) {
			createNewCompetitionForm.current.handleFormSubmit();
			getAllCompetitions()
		}
	};

	const submitUpdateMatchDayForm = useRef<HTMLFormElement | null>();
	const submitUpdateMatchDay = () => {
		if (submitUpdateMatchDayForm.current) {
			submitUpdateMatchDayForm.current.handleFormSubmit();
			getAllCompetitions()
		}
	};

	const submitCreateMatchDayForm = useRef<HTMLFormElement | null>();
	const submitCreateMatchDay = () => {
		if (submitCreateMatchDayForm.current) {
			submitCreateMatchDayForm.current.handleFormSubmit();
			getAllCompetitions()
		}
	};

	// Here pass each button , green buttons an array of buttons
	// delete button a single button

	const createCardsArray = (array: ICompetition[]): ICardsGrid[] => {
		return array?.filter(
			(x) => x?.isArchived === showArchived
		).map((x) => {
			const insertMatchDayScoresButton: ICustomButtonProps = {
				text: t(
					'admin-page-competizioni-insert-match-day-scores-button-text'
				),
				onClick: () => submitMatchDayResultsFormForm(),
				isDisabled:
					adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled,
				modalBody: (
					<ModalBodyMatchDayScores
						matchDayScores={competitionMatchDayScores as IMatchDayScores[]}
						competitionID={x.id}
						ranking={x?.ranking}
						playersRanking={x?.playersRanking}
						roundList={x?.rounds}
						ref={insertMatchDayResultsForm}
					/>
				),
			};
			const updateMatchDay: ICustomButtonProps = {
				text: t('admin-page-competizioni-edit-matchday-button-text'),
				onClick: () => submitUpdateMatchDay(),
				isDisabled:
					adminCompetitionModalEditMatchDayAdvanceButtonDisabled,
				modalBody: (
					<ModalBodyEditMatchDay
						roundList={x?.rounds}
						ranking={x?.ranking}
						ref={submitUpdateMatchDayForm}
					/>
				),
			};
			const insertMatchDay: ICustomButtonProps = {
				text: t('admin-page-competizioni-insert-matchday-button-text'),
				onClick: () => submitCreateMatchDay(),
				isDisabled:
					adminCompetitionModalCreateMatchDayAdvanceButtonDisabled,
				modalBody: (
					<ModalBodyCreateMatchDay
						competitionID={x.id}
						roundList={x?.rounds}
						ranking={x?.ranking}
						ref={submitCreateMatchDayForm}
					/>
				),
			};
			const deleteCompetitionButton: ICustomButtonProps = {
				text: t('admin-page-competizioni-delete-competition-button-text'),
				onClick: () => {
					clientStoreActions.setButtonLoading(true)
					asyncDeleteCompetition(x.id);
				},
				modalBody: (
					<ModalBodyDeleteCompetition competitionName={x.name} />
				),
				isRed: true,
			};
			const archiveCompetitionButton: ICustomButtonProps = {
				text: showArchived ? t('admin-page-competizioni-unarchive-competition-button-text') : t('admin-page-competizioni-archive-competition-button-text'),
				onClick: () => {
					asyncArchiveCompetition(x.id, !showArchived);
					document.dispatchEvent(new Event('close-custom-modal'));
				},
				modalBody: (
					<ModalBodyArchiveCompetition competitionName={x.name} />
				),
				isBlue: true,
			};

			const CardBodyElement = (): JSX.Element => (
				<>
					<Text as={'b'}>{t('admin-page-competizioni-direct-elimination')} </Text>
					{x.directElimination ? (
						<CheckIcon color="green" />
					) : (
						<CloseIcon color="red" />
					)}
				</>
			);

			return {
				title: x.name,
				id: parseInt(x.id),
				buttons: [
					insertMatchDayScoresButton,
					updateMatchDay,
					insertMatchDay,
					archiveCompetitionButton,
				],
				deleteButton: deleteCompetitionButton,
				bodyText: <CardBodyElement />,
			};
		});
	};
	const areArchived = !!competitionList?.find((x) => x?.isArchived === true);
	// addIcon a single button for the whole page
	const addIcon: ICustomButtonProps = {
		text: t('admin-page-competizioni-add-icon-text'),
		onClick: () => submitCreateNewCompetitionForm(),
		isDisabled: adminCompetitionModalCreateCompetitionAdvanceButtonDisabled,
		modalBody: (
			<ModalBodyCreateCompetition ref={createNewCompetitionForm} />
		),
	};


	return (
		<div className="AdminPageCompetizioni-container">
			<NoMetadata/>
			<img src={bg_image} className="background-video" />
			{competitionDataLoading && (
				<div className="competition-loading-spinner-container">
					<LoadingSpinner />
				</div>
			)}
			{!(competitionDataLoading) && <>
				{areArchived && <div className="AdminPageCompetizioni-switch-and-text-container">
					<span className="AdminPageCompetizioni-switch-text">
						{t('admin-page-competizioni-archived-toggle-text')}
					</span>
					<Switch onChange={(e) => setShowArchived(e.target.checked)} />
				</div>}

				<CardsGrid cards={createCardsArray(competitionList as ICompetition[])} addIcon={addIcon} />
			</>}
		</div>
	);
});
export default AdminPageCompetizioni;
AdminPageCompetizioni.displayName = 'AdminPageCompetizioni';
