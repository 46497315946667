import { gql } from '@apollo/client';

export const GetAllCompetition = gql`
query GetAllCompetition {
    competitions(pagination:{limit:100000}) {
        data {
            id
            attributes {
                Name
                DirectElimination
                IsArchived
                Ranking {
                    data {
                        id
                        attributes {
                            Teams(pagination:{limit:100000}) {
                                data {
                                    id
                                    attributes {
                                        Name
                                        Points
                                        PlayedMatches
                                        Wins
                                        Draws
                                        Loss
                                        GoalsDone
                                        GoalsReceived
                                        Tags
                                        HostedMatches(pagination:{limit:100000}) {
                                            data {
                                                id
                                                attributes {
                                                    HostTeam {
                                                        data {
                                                            id
                                                            attributes {
                                                                Name
                                                            }
                                                        }
                                                    }
                                                    GuestTeam {
                                                        data {
                                                            id
                                                            attributes {
                                                                Name
                                                            }
                                                        }
                                                    }
                                                    Date
                                                    Hour
                                                    IsPostponed
                                                    HostGoals
                                                    GuestGoals
                                                    GuestWarnings
                                                    HostWarnings
                                                }
                                            }
                                        }
                                        GuestMatches(pagination:{limit:1000000}) {
                                            data {
                                                id
                                                attributes {
                                                    HostTeam {
                                                        data {
                                                            id
                                                            attributes {
                                                                Name
                                                            }
                                                        }
                                                    }

                                                    GuestTeam {
                                                        data {
                                                            id
                                                            attributes {
                                                                Name
                                                            }
                                                        }
                                                    }
                                                    Date
                                                    Hour
                                                    IsPostponed
                                                    HostGoals
                                                    GuestGoals
                                                    GuestWarnings
                                                    HostWarnings
                                                }
                                            }
                                        }
                                        Players(pagination:{limit:10000000}) {
                                            data {
                                                id
                                                attributes {
                                                    Name
                                                    Surname
                                                    Goals
                                                    Warnings
                                                    Assists
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                Rounds(sort: ["id:asc"],pagination:{limit:100000}) {
                    data {
                        id
                        attributes {
                            Stage
                            Matches(pagination:{limit:1000000}) {
                                data {
                                    id
                                    attributes {
                                        Date
                                        Hour
                                        IsPostponed
                                        HostGoals
                                        GuestGoals
                                        GuestWarnings
                                        HostWarnings
                                        HostTeam {
                                            data {
                                                id
                                                attributes {
                                                    Name
                                                }
                                            }
                                        }
                                        GuestTeam {
                                            data {
                                                id
                                                attributes {
                                                    Name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

export const InsertCompetition = gql`
    mutation NewCompetition($data: CompetitionInput!) {
        createCompetition(data: $data) {
            data {
                id
            }
        }
    }
`;
export const DeleteCompetition = gql`
    mutation DeleteCompetition($id: ID!) {
        deleteCompetition(id: $id) {
            data {
                id
            }
        }
    }
`;
export const UpdateCompetition = gql`
mutation UpdateIsArchived ($id :ID! ,$data : CompetitionInput!) {
    updateCompetition(id : $id , data : $data) {
      data{
        id
      }
    }
  }
`

export const GetCompetitionRounds = gql`
    query GetCompetitionRounds($id: ID!) {
        rounds(filters: { Competition: { id: { eq: $id } } }) {
            data {
                id
                attributes {
                    Name
                    Stage
                    Matches {
                        data {
                            id
                            attributes {
                                Date
                                Hour
                                GuestGoals
                                HostGoals
                                IsPostponed
                                HostTeam {
                                    data {
                                        id
                                        attributes {
                                            Name
                                        }
                                    }
                                }
                                GuestTeam {
                                    data {
                                        id
                                        attributes {
                                            Name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
