import '../styles/HomePage.css';
import NavBar from '@/components/Navbar';
import t from '@utils/LabelsUtil/labelingUtils';
import SponsorComponent from '@/components/SponsorComponent';
import { Metadata } from '@/utils/hooks/useMetadata';
import { DEFAULT_URL } from '@/app';

const HomePage = () => {
    const homePageTitle = t('home-page.title');
    
    return (
        <div className="homePage">
            <Metadata pageUrl={DEFAULT_URL} pageTitle={'Calciolandia | HomePage'} />
            <NavBar />
            <video autoPlay loop muted playsInline preload="metadata" className="background-video">
                <source src={import.meta.env.VITE_VIDEO_BG_URL} type="video/mp4" />
            </video>
            <section className="homePage-content">
                <h1>{homePageTitle}</h1>
                <a href="/competizioni" className="btn light"></a>
            </section>
            <SponsorComponent />
        </div>
    );
};
export default HomePage;
