import t from '@/utils/LabelsUtil/labelingUtils';
import './ClassificaComponent.css';
import { TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { ITeam } from '@/utils/hooks/useClientStore';

export interface ClassificaComponentProps {
    classifica: ITeam[];
}

const ClassificaComponent: React.FC<ClassificaComponentProps> = ({ classifica }) => {
    const sortedTeams = classifica?.sort((a, b) => {
        // Sort by points in descending order
        if (b.points !== a.points) {
            return b.points - a.points;
        }
        // If points are even, sort by cards in descending order
        if (b.cards !== a.cards) {
            return b.cards - a.cards;
        }

        // If points and cards are even, sort by (goalsDone - goalsReceived) in ascending order
        const goalDifferenceA = a.goalsDone - a.goalsReceived;
        const goalDifferenceB = b.goalsDone - b.goalsReceived;

        return goalDifferenceA - goalDifferenceB;
    });
    return (
        <>
            <div className="ClassificaComponent-container">
                <TableContainer>
                    <Table colorScheme="whiteAlpha" variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Pos.</Th>   
                                <Th>{t('classifica-component-table-headings-team')}</Th>
                                <Th>{t('classifica-component-table-headings-points')}</Th>
                                <Th>{t('classifica-component-table-headings-played')}</Th>
                                <Th>{t('classifica-component-table-headings-won-lost-drawn')}</Th>
                                <Th>{t('classifica-component-table-headings-goals-diff')}</Th>
                                <Th>{t('classifica-component-table-headings-cards')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {classifica &&
                                sortedTeams?.map((x: ITeam , i : number) => {
                                    return (
                                        <Tr key={x.name}>
                                            <Td>{i + 1}</Td>
                                            <Td>{x.name}</Td>
                                            <Td>{x.points}</Td>
                                            <Td>{x.playedMatches}</Td>
                                            <Td>
                                                <span className="ClassificaComponent-vittorie">{x.wins}</span>-<span className="ClassificaComponent-pareggi">{x.draws}</span>-
                                                <span className="ClassificaComponent-sconfitte">{x.loss}</span>
                                            </Td>
                                            <Td>
                                                <span className="ClassificaComponent-golFatti">{x.goalsDone}</span>:<span className="ClassificaComponent-golSubiti">{x.goalsReceived}</span>
                                            </Td>
                                            <Td>{x.cards ? x.cards : 0}</Td>
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
};

export default ClassificaComponent;
