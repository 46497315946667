import { create } from 'zustand';
import { IMatchDayScores } from './complexityHandlers';

export interface ITeam {
	id: string | number;
	name: string;
	wins: number;
	draws: number;
	loss: number;
	playedMatches: number;
	points: number;
	goalsDone: number;
	goalsReceived: number;
	cards: number;
	hostedMatches: string[] | number[];
	guestMatches: string[] | number[];
}

export interface IMatch {
	id: string | number;
	date: string;
	hour: string;
	hostName: string;
	guestName: string;
	hostGoals: number;
	hostCards: number;
	guestGoals: number;
	guestCards: number;
	hostIndex?: number;
	guestIndex?: number;
	isPostponed?: boolean;
	guestAutogoals? : number;
	hostAutogoals? : number;
}
export interface IRounds {
	eliminationRound: string | null;
	matches: IMatch[];
	id: string;
}
export interface IPlayer {
	name: string;
	surname: string;
	goals: number;
	cards: number;
	teamName: string;
	id: string;
}
export interface ICompetition {
	id: string;
	name: string;
	directElimination: boolean;
	isArchived: boolean;
	ranking: ITeam[];
	rounds: IRounds[];
	playersRanking: IPlayer[];
}

const DEFAULT_VALUE_COMPETITIONNAMELIST: string[] = [''];
const DEFAULT_VALUE_COMPETITIONRANKING: ITeam[] = [
	{
		id: 0,
		name: '',
		wins: 0,
		draws: 0,
		loss: 0,
		playedMatches: 0,
		points: 0,
		goalsDone: 0,
		goalsReceived: 0,
		cards: 0,
		hostedMatches: [0],
		guestMatches: [0],
	},
	{
		id: 0,
		name: '',
		wins: 0,
		draws: 0,
		loss: 0,
		playedMatches: 0,
		points: 0,
		goalsDone: 0,
		goalsReceived: 0,
		cards: 0,
		hostedMatches: [0],
		guestMatches: [0],
	},
];
const DEFAULT_VALUE_COMPETITIONROUNDS: IRounds[] = [
	{
		eliminationRound: null,
		matches: [
			{
				id: 0,
				date: '',
				hour: '',
				hostName: '',
				hostGoals: 0,
				hostCards: 0,
				guestName: '',
				guestGoals: 0,
				guestCards: 0,
			},
		],
		id: '0',
	},
];
const DEFAULT_VALUE_PLAYERSRANKING: IPlayer[] = [
	{
		name: '',
		surname: '',
		goals: 0,
		cards: 0,
		teamName: '',
		id: '0',
	},
	{
		name: '',
		surname: '',
		goals: 0,
		cards: 0,
		teamName: '',
		id: '0',
	},
];
const DEFAULT_VALUE_COMPETITIONLIST: ICompetition[] = [
	{
		name: '',
		id: '0',
		directElimination: false,
		isArchived: false,
		ranking: DEFAULT_VALUE_COMPETITIONRANKING,
		rounds: DEFAULT_VALUE_COMPETITIONROUNDS,
		playersRanking: DEFAULT_VALUE_PLAYERSRANKING,
	},
];
export const DEFAULT_VALUE_SELECTEDCOMPETITION: ICompetition =
	DEFAULT_VALUE_COMPETITIONLIST[0];
const DEFAULT_VALUE_SECTEDROUNDINDEX: number = 0;
const DEFAULT_VALUE_MATCHDAYSCORES: IMatchDayScores[] = [
	{
		id: '7',
		roundID: 0,
		competitionID: 185,
		matches: [],
		playersActions: [
			{
				Assist: 0,
				Goals: 0,
				ID: '',
				Name: '',
				TeamID: '',
				MatchID: '',
				Surname: '',
				TeamName: '',
				Warnings: 0,
			},
		],
	},
];

export interface IClientStore {
	buttonLoading: boolean;
	competitionDataLoading: boolean;
	isArchivedCometitionsSelected: boolean;
	competitionList: ICompetition[];
	competitionNameList: string[];
	selectedCompetition: ICompetition;
	selectedCompetitionRanking: ITeam[];
	selectedCompetitionRounds: IRounds[];
	selectedCompetitionPlayersRaking: IPlayer[];
	selectedRoundIndex: number;
	roundNextButtonDisabled: boolean;
	roundPrevButtonDisabled: boolean;
	adminCompetitionModalCreateCompetitionAdvanceButtonDisabled: boolean;
	adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled: boolean;
	adminCompetitionModalCreateMatchDayAdvanceButtonDisabled: boolean;
	adminCompetitionModalEditMatchDayAdvanceButtonDisabled: boolean;
	competitionMatchDayScores: IMatchDayScores[];
	clientStoreActions: {
		setButtonLoading: (buttonLoading: boolean) => void;
		setCompetitionDataLoading: (competitionDataLoading: boolean) => void;
		setIsArchivedCometitionsSelected: (
			isArchivedCometitionsSelected: boolean
		) => void;
		setCompetitionList: (competitionList: ICompetition[]) => void;
		setCompetitionNameList: (competitionNameList: string[]) => void;
		setSelectedCompetition: (selectedCompetition: ICompetition) => void;
		setSelectedCompetitionRanking: (
			selectedCompetitionRanking: ITeam[]
		) => void;
		setSelectedCompetitionRounds: (
			selectedCompetitionRounds: IRounds[]
		) => void;
		setSelectedCompetitionPlayersRanking: (
			selectedCompetitionPlayersRaking: IPlayer[]
		) => void;
		setCompetitionMatchDayScores: (
			competitionMatchDayScores: IMatchDayScores[]
		) => void;
		setIncrementSelectedRoundIndex: () => void;
		setDecrementSelectedRoundIndex: () => void;
		setAdminCompetitionModalCreateCompetitionAdvanceButtonDisabled: (
			adminCompetitionModalCreateCompetitionAdvanceButtonDisabled: boolean
		) => void;
		setAdminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled: (
			adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled: boolean
		) => void;
		setAdminCompetitionModalCreateMatchDayAdvanceButtonDisabled: (
			adminCompetitionModalCreateMatchDayAdvanceButtonDisabled: boolean
		) => void;
		setAdminCompetitionModalEditMatchDayAdvanceButtonDisabled: (
			adminCompetitionModalEditMatchDayAdvanceButtonDisabled: boolean
		) => void;
	};
}

const useClientStore = create<IClientStore>((set) => ({
	buttonLoading: false,
	competitionDataLoading: true,
	isArchivedCometitionsSelected: false,
	competitionList: DEFAULT_VALUE_COMPETITIONLIST,
	competitionNameList: DEFAULT_VALUE_COMPETITIONNAMELIST,
	selectedCompetition: DEFAULT_VALUE_SELECTEDCOMPETITION,
	selectedCompetitionRanking: DEFAULT_VALUE_COMPETITIONRANKING,
	selectedCompetitionRounds: DEFAULT_VALUE_COMPETITIONROUNDS,
	selectedCompetitionPlayersRaking: DEFAULT_VALUE_PLAYERSRANKING,
	selectedRoundIndex: DEFAULT_VALUE_SECTEDROUNDINDEX,
	roundNextButtonDisabled: false,
	roundPrevButtonDisabled: true,
	adminCompetitionModalCreateCompetitionAdvanceButtonDisabled: true,
	adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled: true,
	adminCompetitionModalCreateMatchDayAdvanceButtonDisabled: true,
	adminCompetitionModalEditMatchDayAdvanceButtonDisabled: true,
	competitionMatchDayScores: DEFAULT_VALUE_MATCHDAYSCORES,
	clientStoreActions: {
		setButtonLoading: (buttonLoading: boolean) => {set({ buttonLoading })},
		setCompetitionDataLoading: (competitionDataLoading: boolean) =>
			set({ competitionDataLoading }),
		setIsArchivedCometitionsSelected: (
			isArchivedCometitionsSelected: boolean
		) => set({ isArchivedCometitionsSelected }),
		setCompetitionList: (competitionList: ICompetition[]) =>
			set({ competitionList }),
		setCompetitionNameList: (competitionNameList: string[]) =>
			set({ competitionNameList }),
		setSelectedCompetition: (selectedCompetition: ICompetition) =>
			set({ selectedCompetition }),
		setSelectedCompetitionRanking: (selectedCompetitionRanking: ITeam[]) =>
			set({ selectedCompetitionRanking }),
		setSelectedCompetitionRounds: (selectedCompetitionRounds: IRounds[]) =>
			set({ selectedCompetitionRounds }),
		setSelectedCompetitionPlayersRanking: (
			selectedCompetitionPlayersRaking: IPlayer[]
		) => set({ selectedCompetitionPlayersRaking }),
		setCompetitionMatchDayScores: (
			competitionMatchDayScores: IMatchDayScores[]
		) => set({ competitionMatchDayScores }),
		setIncrementSelectedRoundIndex: () =>
			set((state) => ({
				selectedRoundIndex:
					state.selectedRoundIndex <
					state.selectedCompetitionRounds.length - 1
						? state.selectedRoundIndex + 1
						: state.selectedRoundIndex + 0,
				roundNextButtonDisabled:
					state.selectedRoundIndex <
					state.selectedCompetitionRounds.length - 1
						? false
						: true,
			})),
		setDecrementSelectedRoundIndex: () =>
			set((state) => ({
				selectedRoundIndex:
					state.selectedRoundIndex > 0
						? state.selectedRoundIndex - 1
						: DEFAULT_VALUE_SECTEDROUNDINDEX,
				roundPrevButtonDisabled:
					state.selectedRoundIndex > 0 ? false : true,
			})),
		setAdminCompetitionModalCreateCompetitionAdvanceButtonDisabled: (
			adminCompetitionModalCreateCompetitionAdvanceButtonDisabled: boolean
		) =>
			set({
				adminCompetitionModalCreateCompetitionAdvanceButtonDisabled,
			}),
		setAdminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled: (
			adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled: boolean
		) =>
			set({
				adminCompetitionModalCreateMatchDayScoresAdvanceButtonDisabled,
			}),
		setAdminCompetitionModalCreateMatchDayAdvanceButtonDisabled: (
			adminCompetitionModalCreateMatchDayAdvanceButtonDisabled: boolean
		) => set({ adminCompetitionModalCreateMatchDayAdvanceButtonDisabled }),
		setAdminCompetitionModalEditMatchDayAdvanceButtonDisabled: (
			adminCompetitionModalEditMatchDayAdvanceButtonDisabled: boolean
		) => set({ adminCompetitionModalEditMatchDayAdvanceButtonDisabled }),
	},
}));

export default useClientStore;
