// Import necessary dependencies
import { Helmet } from 'react-helmet-async';


// React component to dynamically set meta tags
interface MetadataProps {
    pageUrl: string;
    pageTitle: string;
    structuredData?: string;
}

export const Metadata: React.FC<MetadataProps> = ({ pageUrl, pageTitle, structuredData }) => {

    const metaTags = {
        title: pageTitle, // Default title
        description: 'Calciolandia è il nuovissimo centro sportivo campo polivalente da calcio e da calcetto situato a cavallo tra le provincie di Roma e Frosinone.', // Default description
        image: 'https://odin.pdsstaging.com/uploads/chrome_v_Uh_T_Jrjb_By_db5ba4fc0a.webp', // Default image URL
    };

    // Add more conditions for other pages as needed


    return (
        <Helmet>
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content="calcetto, calciotto, colleferro, anagni, calciolandia, padel, gavignano" />
            <meta name="description" content={metaTags.description} />

            {/* Open Graph (OG) Tags */}
            <meta property="og:title" content={metaTags.title} />
            <meta property="og:description" content={metaTags.description} />
            <meta property="og:image" content={metaTags.image} />
            <meta property="og:url" content={pageUrl} />
            <meta property="og:type" content="website" />

            {/* Twitter Card */}
            <meta name="twitter:card" content={metaTags.image} />
            <meta name="twitter:title" content={metaTags.title} />
            <meta name="twitter:description" content={metaTags.description} />
            <meta name="twitter:image" content={metaTags.image} />
            {structuredData &&<script
                dangerouslySetInnerHTML={{ __html: structuredData }}
                type="application/ld+json"
            />}

            {/* Favicon */}
            <link rel="icon" type="image/svg+xml" href="/src/assets/CALCIOLANDIA_LOGO_PLAIN_FAVICON.webp" />

            {/* Canonical URL */}
            <link rel="canonical" href={pageUrl} />
            <title>{metaTags.title}</title>
        </Helmet>
    );
};

// Function to generate metadata for preventing indexing
export const NoMetadata = () => {
    return (
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
    );

}

