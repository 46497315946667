import '@styles/AdminPageSponsor.css';
import { customNotificationError, customNotificationSuccess } from '@/components/CustomNotifications';
import { CREATE_SPONSOR, DeleteImageUpload, DeleteSponsor, GetSponsors, UpdateSponsor } from '@/utils/Queries/Sponsors';
import { mapSponsorResponseToSponsorStore } from '@/utils/hooks/complexityHandlers';
import useSponsorStore, { DEFAULT_SPONSOR_VALUE, ICardsGrid, ISponsor } from '@/utils/hooks/useSponsorStore';
import { useMutation, useQuery } from '@apollo/client';
import CardsGrid from '@/components/CardsGrid';
import { ICustomButtonProps } from '@/components/CustomButton';
import { useEffect, useRef, useState } from 'react';
import { Input, Textarea } from '@chakra-ui/react';
import t from '@/utils/LabelsUtil/labelingUtils';
import SponsorImageUploader from '@/components/SponsorImageUploader';
import { DeleteIcon } from '@chakra-ui/icons';
import bg_image from '@assets/bg_image.webp';
import { NoMetadata } from '@/utils/hooks/useMetadata';

const AdminPageSponsor = () => {
    const { allSponsors, sponsorStoreActions, editSponsorImage } = useSponsorStore();

    const formRef = useRef<HTMLFormElement | null>(null);

    const { data: getSponsorData, refetch: refetchGetSponsor } = useQuery(GetSponsors);

    useEffect(() => {
        refetchGetSponsor();
    }, [editSponsorImage]);

    useEffect(() => {
        sponsorStoreActions.setAllSponsors(mapSponsorResponseToSponsorStore(getSponsorData));
    }, [getSponsorData]);

    const [deleteSponsor] = useMutation(DeleteSponsor);
    const [updateSponsor] = useMutation(UpdateSponsor);
    const [deleteImageUpload] = useMutation(DeleteImageUpload);
    const [createSponsor] = useMutation(CREATE_SPONSOR, {
        refetchQueries: [{ query: GetSponsors }],
    });
    const deleteSponsorCallback = async (id: number) => {
        try {
            await deleteSponsor({ variables: { id } });
            refetchGetSponsor();
        } catch (error: unknown) {
            if (error && (error as { message?: string })?.message) {
                customNotificationError((error as { message: string }).message);
            }
        }
    };
    const deleteUploadedImage = async (id: number) => {
        try {
            await deleteImageUpload({ variables: { id } });
            sponsorStoreActions.setEditSponsorImage({
                ImageId: 0,
                ImageUrl: '',
            });
            refetchGetSponsor();
        } catch (error: unknown) {
            if (error && (error as { message?: string })?.message) {
                customNotificationError((error as { message: string }).message);
            }
        }
    };

    const SponsorEditModalBody: React.FC<{
        sponsor: ISponsor;
        isNew: boolean;
    }> = ({ sponsor, isNew = false }) => {
        const [sponsorName, setSponsorName] = useState<string>(sponsor.Name);
        const [sponsorDescription, setSponsorDescription] = useState<string>(sponsor.Description);
        const [sponsorPhoneNumber, setSponsorPhoneNumber] = useState<string>(sponsor.PhoneNumber as string);
        const [sponsorWebsite, setSponsorWebsite] = useState<string>(sponsor.Website as string);
        const [sponsorAddress, setSponsorAddress] = useState<string>(sponsor.Address as string);
        const [sponsorImage, setSponsorImage] = useState<string>(sponsor.Image?.ImageId?.toString() as string);
        const handleCallback = (imageID : string) => {
            setSponsorImage(imageID)
        } 

        const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const data = {
                ...(sponsorName && sponsorName?.length > 0 ? { Name: sponsorName } : {}),
                ...(sponsorDescription && sponsorDescription?.length > 0 ? { Description: sponsorDescription } : {}),
                ...(sponsorImage && sponsorImage !== '0' ? { Image: sponsorImage } : {}),
                ...(sponsorPhoneNumber && sponsorPhoneNumber?.length > 0 ? { PhoneNumber: sponsorPhoneNumber } : {}),
                ...(sponsorWebsite && sponsorWebsite?.length > 0 ? { Website: sponsorWebsite } : {}),
                ...(sponsorAddress && sponsorAddress?.length > 0 ? { Address: sponsorAddress } : {}),
            };
            if (isNew) {
                try {
                    const result = await createSponsor({ variables: { data } });
                    if (result?.data?.createSponsor?.data?.id) {
                        customNotificationSuccess('Operazione completata con successo');
                        document.dispatchEvent(new Event('close-custom-modal'));
                    }
                    refetchGetSponsor();
                } catch (error: unknown) {
                    if (error && (error as { message?: string })?.message) {
                        customNotificationError((error as { message: string }).message);
                    }
                }
            } else {
                try {
                    const result = await updateSponsor({
                        variables: { id: sponsor.id, data },
                    });
                    if (result?.data?.updateSponsor?.data?.id) {
                        customNotificationSuccess('Operazione completata con successo');
                        document.dispatchEvent(new Event('close-custom-modal'));
                    }
                    refetchGetSponsor();
                } catch (error: unknown) {
                    if (error && (error as { message?: string })?.message) {
                        customNotificationError((error as { message: string }).message);
                    }
                }
            }
        };
        
        return (
            <form onSubmit={handleSubmit} ref={formRef} className="AdminPageSponsor-sponsor-edit-modal-body-container" id="sponsor-form">
                <NoMetadata/>
                <p>{t('admin-page-sponsor-modal-edit-title')}</p>
                <Input
                    key={t('admin-page-sponsor-modal-edit-title')}
                    value={sponsorName}
                    onChange={(e) => {
                        setSponsorName(e.target.value);
                    }}
                />
                <>
                    <p>{t('admin-page-sponsor-modal-edit-bodyText')}</p>
                    <Textarea value={sponsorDescription} onChange={(e) => setSponsorDescription(e.target.value)} />
                </>
                <>
                    <p>{t('admin-page-sponsor-modal-edit-phoneNumber')}</p>
                    <Input value={sponsorPhoneNumber} onChange={(e) => setSponsorPhoneNumber(e.target.value)} />
                </>
                <>
                    <p>{t('admin-page-sponsor-modal-edit-website')}</p>
                    <Input value={sponsorWebsite} onChange={(e) => setSponsorWebsite(e.target.value)} />
                </>
                <>
                    <p>{t('admin-page-sponsor-modal-edit-address')}</p>
                    <Input value={sponsorAddress} onChange={(e) => setSponsorAddress(e.target.value)} />
                </>
                {sponsor.Image?.ImageId && sponsor.Image?.ImageUrl?.length > 0 && (
                    <div className="AdminPageSponsor-sponsor-edit-modal-body-image">
                        <p>{t('admin-page-sponsor-modal-edit-image')}</p>
                        <img src={sponsor.Image.ImageUrl} />
                        <button onClick={() => deleteUploadedImage(parseInt(sponsorImage))} className="AdminPageSponsor-sponsor-edit-modal-body-image-button">
                            <DeleteIcon />
                        </button>
                    </div>
                )}
                {(isNew || !sponsor.Image?.ImageUrl?.length) && (
                    <div className="AdminPageSponsor-sponsor-edit-modal-body-drop-zone">
                        <p>{t('admin-page-sponsor-modal-edit-image')}</p>
                        <SponsorImageUploader
                            callBackForImageData={handleCallback}
                        />
                    </div>
                )}
            </form>
        );
    };

    const submitForm = () => {
        if (formRef.current) {
            formRef.current.requestSubmit();
        }
    };

    const addIcon: ICustomButtonProps = {
        text: 'Aggiungi',
        onClick: () => submitForm(),
        modalBody: (<SponsorEditModalBody sponsor={DEFAULT_SPONSOR_VALUE} isNew={true} />) as JSX.Element,
    };

    const cardsToGenerate: ICardsGrid[] = allSponsors?.map((x) => {
        const editButton: ICustomButtonProps = {
            text: 'Modifica',
            onClick: () => submitForm(),
            modalTitle: 'Modifica Sponsor',
            modalBody: (<SponsorEditModalBody sponsor={allSponsors.find((item) => item.id == x.id) as ISponsor} isNew={false} />) as JSX.Element,
        };
        const deleteButton: ICustomButtonProps = {
            text: 'Elimina',
            isRed: true,
            onClick: () => deleteSponsorCallback(x.id),
            modalBody: 'Sei sicuro di voler eliminare questo sponsor? Questa azione è irreversibile',
            modalTitle: 'Elimina Sponsor',
        };
        return {
            title: x.Name,
            id: x.id,
            buttons: [editButton],
            deleteButton,
            ...(x?.Description ? { bodyText: x.Description } : {}),
            ...(x?.Image?.ImageUrl ? { imageUrl: x.Image.ImageUrl, imageId: x.Image.ImageId } : {}),
            ...(x?.PhoneNumber ? { phoneNumber: x.PhoneNumber } : {}),
            ...(x?.Website ? { website: x.Website } : {}),
            ...(x?.Address ? { address: x.Address } : {}),
        };
    });
    return (
        <>
            <div className="AdminPageSponsor-page-container">
                <img src={bg_image} className="background-video" />
                <div className="AdminPageSponsor-card-container">
                    <CardsGrid cards={cardsToGenerate} addIcon={addIcon} />
                </div>
            </div>
        </>
    );
};
export default AdminPageSponsor;
