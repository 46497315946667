import { Card, CardHeader, Heading, CardBody, CardFooter, IconButton } from '@chakra-ui/react';
import React from 'react';
import './CardsGrid.css';
import CustomButton from '../CustomButton';
import CustomizableModal from '../CustomizableModal';
import { IButtonProps, ICardsGrid } from '@/utils/hooks/useSponsorStore';
import t from '@/utils/LabelsUtil/labelingUtils';
import { AddIcon } from '@chakra-ui/icons';

const RenderMultipleButtons: React.FC<{
    buttons: IButtonProps[];
    cardId: number;
}> = ({ buttons, cardId }) => {
    return (
        <>
            {buttons?.map((button, index) => {
                const [isOpen, setIsOpen] = React.useState(false);

                const handleOpenModal = () => {
                    setIsOpen(true);
                };

                const handleCloseModal = () => {
                    setIsOpen(false);
                };
                return (
                    <React.Fragment key={index}>
                        <CustomButton text={button.text} onClick={handleOpenModal} index={index} />
                        <CustomizableModal
                            isOpen={isOpen}
                            title={button?.modalTitle || button.text}
                            body={button?.modalBody as JSX.Element | string}
                            onOpen={handleOpenModal}
                            onClose={handleCloseModal}
                            buttonClose={{
                                text: t('cards-grid-card-body-modal-cancel-button'),
                                onClick: handleCloseModal,
                            }}
                            buttonAdvance={{
                                text: t('cards-grid-card-body-modal-accept-button'),
                                isDisabled: button.isDisabled,
                                onClick: () => button.onClick(cardId),
                            }}
                        />
                    </React.Fragment>
                );
            })}
        </>
    );
};

const RenderButtons: React.FC<{
    buttons?: IButtonProps[];
    deleteButton?: IButtonProps;
    cardId?: number;
}> = ({ buttons, deleteButton, cardId }) => {
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    const handleDeleteOpenModal = () => {
        setIsDeleteOpen(true);
    };

    const handleDeleteCloseModal = () => {
        setIsDeleteOpen(false);
    };

    return (
        <CardFooter>
            <div className="CardsGrid-card-footer-buttons-container">
                {buttons && buttons?.length > 0 && <RenderMultipleButtons buttons={buttons} cardId={cardId as number} />}
                {deleteButton && deleteButton.text && (
                    <React.Fragment>
                        <CustomButton text={deleteButton.text} onClick={() => handleDeleteOpenModal()} isRed index={cardId ? undefined : cardId} />
                        <CustomizableModal
                            isOpen={isDeleteOpen}
                            key={cardId}
                            title={deleteButton?.modalTitle || deleteButton.text}
                            body={deleteButton?.modalBody as JSX.Element | string}
                            onOpen={handleDeleteOpenModal}
                            onClose={handleDeleteCloseModal}
                            buttonClose={{
                                text: t('cards-grid-card-body-modal-cancel-button'),
                                onClick: handleDeleteCloseModal,
                            }}
                            buttonAdvance={{
                                text: t('cards-grid-card-body-modal-delete-button'),
                                onClick: () => deleteButton.onClick(cardId),
                            }}
                        />
                    </React.Fragment>
                )}
            </div>
        </CardFooter>
    );
};

const CardsGrid: React.FC<{ cards: ICardsGrid[]; addIcon?: IButtonProps }> = ({ cards, addIcon }) => {
    const [isAddOpen, setIsAddOpen] = React.useState(false);

    const handleAddOpenModal = () => {
        setIsAddOpen(true);
    };

    const handleAddCloseModal = () => {
        setIsAddOpen(false);
    };

    const renderCard = (card: ICardsGrid, index: number) => (
        <Card id={card.id.toString()} key={index}>
            <CardHeader>
                <Heading size="md">{card.title}</Heading>
            </CardHeader>
            <CardBody>
                <div className="CardsGrid-card-body-container">
                    {card.imageUrl && <img className="CardsGrid-card-body-image" src={card.imageUrl} alt="Card Image" />}
                    {card.bodyText && (
                        <div className="CardsGrid-card-body-description-container">
                            {/* <span className="CardsGrid-card-body-description">{t('cards-grid-card-body-description')}</span> */}
                            <span className="CardsGrid-card-body-text">{card.bodyText}</span>
                        </div>
                    )}
                    {card.phoneNumber && (
                        <div className="CardsGrid-card-body-phoneNumber-container">
                            <span className="CardsGrid-card-body-phoneNumber">{t('cards-grid-card-body-phoneNumber')}</span>
                            <span className="CardsGrid-card-body-text">{card.phoneNumber}</span>
                        </div>
                    )}
                    {card.website && (
                        <div className="CardsGrid-card-body-website-container">
                            <span className="CardsGrid-card-body-website">{t('cards-grid-card-body-website')}</span>
                            <span className="CardsGrid-card-body-text">{card.website}</span>
                        </div>
                    )}
                    {card.address && (
                        <div className="CardsGrid-card-body-address-container">
                            <span className="CardsGrid-card-body-address">{t('cards-grid-card-body-address')}</span>
                            <span className="CardsGrid-card-body-text">{card.address}</span>
                        </div>
                    )}
                </div>
            </CardBody>
            {card.buttons && <RenderButtons buttons={card.buttons} cardId={card.id} deleteButton={card.deleteButton} />}
        </Card>
    );



    return (
        <>
            {addIcon && (
                <div className="CardsGrid-add-button">
                    <div onClick={() => handleAddOpenModal()} className="CardsGrid-add-button-container">
                        <span className='CardsGrid-add-button-text'>Aggiungi Nuovo</span>
                        <IconButton onClick={() => handleAddOpenModal()} aria-label="Add Sponsor" size="sm" colorScheme="green" icon={<AddIcon />} />
                    </div>
                    <CustomizableModal
                        isOpen={isAddOpen}
                        title={addIcon?.modalTitle || addIcon.text}
                        body={addIcon?.modalBody as JSX.Element | string}
                        onOpen={handleAddOpenModal}
                        onClose={handleAddCloseModal}
                        buttonClose={{
                            text: t('cards-grid-card-modal-add-close-button'),
                            onClick: handleAddCloseModal,
                        }}
                        buttonAdvance={{
                            text: t('cards-grid-card-modal-add-add-button'),
                            isDisabled: addIcon.isDisabled,
                            onClick: addIcon.onClick,
                        }}
                    />
                </div>
            )}
            <div className="CardsGrid-cards-container">{cards?.map(renderCard)}</div>
        </>
    );
};

export default CardsGrid;
