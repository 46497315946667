// We're using the library notistack to manage correctly the
// multiple notifications that are not supported by mUI and
// the Material Design UI guidelines. You can find the SnackBarProvider
// declared in LiveblogAppContainer.tsx which catches and manages
// all the notifications that are sent into the enqueueSnackbar function.
// Here you can find the library documentation https://notistack.com/
import React from 'react';
import { CustomContentProps, OptionsObject, SnackbarMessage, closeSnackbar, enqueueSnackbar } from 'notistack';
import './CustomNotifications.css';
import { CloseIcon } from '@chakra-ui/icons';

export enum Severity {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}
// A declaration needed to add our custom prop to the OptionsObject.
interface ICustomOptions extends OptionsObject {
    severity: Severity;
}

declare module 'notistack' {
    interface VariantOverrides {
        customNotification: true;
    }
}
// An interface declaration made to be compliant with the current
// props that we are passing to our custom component throught enqueueSnackbar
interface ICustomNotificationProps extends CustomContentProps {
    id: string | number;
    message: SnackbarMessage;
    style: object;
    severity: Severity;
}
const enqueueSnack = (message: SnackbarMessage, options?: ICustomOptions | undefined) => {
    return enqueueSnackbar(message, options);
};
// A function that feeds the single error to feed the enqueueSnackbar function.
const errorSingleNotification = (error: string) => {
    enqueueSnack(error, {
        variant: 'customNotification',
        severity: Severity.ERROR,
        persist : false,
        autoHideDuration : 3000,
        preventDuplicate : true
    });
};

// A function that iterates throught an array of Errors to feed the enqueueSnackbar function.
const errorListNotification = (error: string[]) => {
    error.forEach((e) => errorSingleNotification(e));
};
// A function that feeds the single error to feed the enqueueSnackbar function.
const successSingleNotification = (message: string) => {
    enqueueSnack(message, {
        variant: 'customNotification',
        severity: Severity.SUCCESS,
        persist : false,
        autoHideDuration : 3000,
        preventDuplicate : true
    });
};

// A function that iterates throught an array of Errors to feed the enqueueSnackbar function.
const successListNotification = (error: string[]) => {
    error.forEach((e) => successSingleNotification(e));
};

// A function to display custom notifications using the notistack library that iterates if it's an array
// and returns the multiple notifications or a single notification if it's an object
export const customNotificationError = (error: string | string[]) => {
    if (!Array.isArray(error) && Object.keys(error).length > 0) {
        errorSingleNotification(error);
    } else if (Array.isArray(error) && error.length > 0) {
        errorListNotification(error);
    }
};
export const customNotificationSuccess = (error: string | string[]) => {
    if (!Array.isArray(error) && Object.keys(error).length > 0) {
        successSingleNotification(error);
    } else if (Array.isArray(error) && error.length > 0) {
        successListNotification(error);
    }
};

export const CustomNotificationComponent = React.forwardRef<HTMLDivElement, ICustomNotificationProps>(function CustomNotificationComponent({ id, message, style, severity }, ref) {
    const notificationStyle = {
        zIndex: 1500,
        backgroundColor: severity === 'error' ? '#D80000' : severity === 'warning' ? '#EC620A' : severity === 'success' ? 'green' : severity === 'info' ? 'blue' : '#D80000',
    };

    return (
        <div style={style} ref={ref}>
            <div style={notificationStyle} className="custom-notifications-notification_container">
                <div className="custom-notifications-notification_elements_container">
                    <div className="custom-notifications-notification_text">{message}</div>
                    <div className="custom-notifications-notification_exit" onClick={() => closeSnackbar(id)}>
                        <CloseIcon/>
                    </div>
                </div>
            </div>
        </div>
    );
});

CustomNotificationComponent.displayName = 'CustomNotificationComponent';
